/* eslint-disable quotes */
import {
  COMPANY_LIST,
  NEW_MONITORING_LIST,
  TOLERANCE_INPUT,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import intl from '../components'

const messages = {
  [intl.advancedFilters('above')]: 'Over',
  [intl.advancedFilters('below')]: 'Under',
  [intl.advancedFilters('company-type')]: 'Virksomhedstype',
  [intl.advancedFilters('count')]:
    '{count, plural, =0 {Ingen virksomheder fundet} one {{count} virksomhed fundet} other {{count} virksomheder fundet}}',
  [intl.advancedFilters('employee-count')]: 'Antal medarbejdere',
  [intl.advancedFilters('equity')]: 'Egenkapital',
  [intl.advancedFilters('financial-data')]: 'Finansielle data',
  [intl.advancedFilters('hide-ad-protected')]: 'Fjern reklamebeskyttede',
  [intl.advancedFilters('industry-group')]: 'Branchegruppe',
  [intl.advancedFilters('industry-specific')]: 'Specifik branchegruppe',
  [intl.advancedFilters('municipality')]: 'Kommune',
  [intl.advancedFilters('region')]: 'Region',
  [intl.advancedFilters('reset-search')]: 'Nulstil søgning',
  [intl.advancedFilters('risika-score')]: 'Risika score',
  [intl.advancedFilters('search-company-type')]: 'Søg efter virksomhedstype',
  [intl.advancedFilters('search-industry-group')]: 'Søg efter branchegruppe',
  [intl.advancedFilters('search-industry-specific')]: 'Søg efter specifik branchegruppe',
  [intl.advancedFilters('search-municipality')]: 'Søg efter kommune',
  [intl.advancedFilters('search-region')]: 'Søg efter region',
  [intl.advancedFilters('select-columns')]: 'Vælg kolonner, der skal eksporteres',
  [intl.advancedFilters('show-inactive')]: 'Vis inaktive',
  [intl.advancedFilters('title')]: 'Filtrering',
  [intl.advancedFilters('illegal-character')]:
    '{count, plural, one {Ugyldigt tegn} other {Ugyldige tegn}} "{char}"! Tilladte tegn er "0-9", "-" og ","',
  [intl.advancedFilters('advanced-zipcodes-helper-text')]:
    'Tilladt format: 1000, 2000-2500',
  [intl.advancedFilters('values-or-ranges')]: 'Skriv enkeltværdier eller intervaller',
  [intl.advancedFilters('show-all')]:
    '{count, plural, one {Vis {count} branche} other {Vis {count} brancher}}',
  [intl.advancedFilters('hide-all')]:
    '{count, plural, one {Skjul {count} branche} other {Skjul {count} brancher}}',
  [intl.advancedFilters('search-for-industry')]: 'Søg efter en branche',
  [intl.advancedFilters('no-industries-selected')]: 'Ingen brancher valgt',

  [intl.advancedSearch('add-filters')]: 'Vælg filtre',
  [intl.advancedSearch('all-filters')]: 'Alle filtre',
  [intl.advancedSearch('apply-filters')]: 'Anvend filtre',
  [intl.advancedSearch('assets')]: 'Aktiver',
  [intl.advancedSearch('auditor')]: 'Revisor',
  [intl.advancedSearch('between')]: 'Mellem',
  [intl.advancedSearch('cash')]: 'Likvider',
  [intl.advancedSearch('company-info')]: 'Virksomhedsinformation',
  [intl.advancedSearch('company_status')]: 'Virksomhedsstatus',
  [intl.advancedSearch('company_type')]: 'Virksomhedstype',
  [intl.advancedSearch('credit')]: 'Kredit',
  [intl.advancedSearch('tree-industry')]: 'Branchekoder',
  [intl.advancedSearch('current_financial_assets')]: 'Værdipapirer',
  [intl.advancedSearch('date_of_incorporation')]: 'Stiftelsesdato',
  [intl.advancedSearch('employees')]: 'Antal ansatte (interval)',
  [intl.advancedSearch('employees_number')]: 'Antal ansatte',
  [intl.advancedSearch('enter-percentage')]: 'Skriv værdien i procent (%)',
  [intl.advancedSearch('enter-value')]: 'Skriv værdien',
  [intl.advancedSearch('equity')]: 'Egenkapital',
  [intl.advancedSearch('excludes')]: 'Ekskluder',
  [intl.advancedSearch('financial-data-section')]: 'Regnskabsinformation',
  [intl.advancedSearch('financial-data-select-all')]: 'Vælg alt regnskabsinformation',
  [intl.advancedSearch('financial-numbers')]: 'Regnskabstal',
  [intl.advancedSearch('financial-ratios')]: 'Nøgletal',
  [intl.advancedSearch('fixed_assets')]: 'Anlægsaktiver',
  [intl.advancedSearch('general-data-section')]: 'Generel virksomhedsinformation',
  [intl.advancedSearch('general-data-select-all')]:
    'Vælg alt general virksomhedsinformation',
  [intl.advancedSearch('gross_result')]: 'Bruttoresultat',
  [intl.advancedSearch('has_auditor')]: 'Har revisor',
  [intl.advancedSearch('has_email')]: 'Har e-mail',
  [intl.advancedSearch('has_phone_number')]: 'Har telefonnummer',
  [intl.advancedSearch('has_webpage')]: 'Har hjemmeside',
  [intl.advancedSearch('hide_advertisement_protected')]: 'Fravælg reklamebeskyttede',
  [intl.advancedSearch('hide_holding_company')]: 'Fravælg holdingselskaber',
  [intl.advancedSearch('includes')]: 'Inkluder',
  [intl.advancedSearch('industry_group')]: 'Branchegruppe',
  [intl.advancedSearch('liquidity')]: 'Likviditetsgrad',
  [intl.advancedSearch('load-filters')]: 'Indlæs filtre',
  [intl.advancedSearch('save-filters')]: 'Gemte filtre',
  [intl.advancedSearch('load-filters-title')]: 'Anvend et af dine gemte filtre',
  [intl.advancedSearch('match')]: 'Match',
  [intl.advancedSearch('municipality')]: 'Kommune',
  [intl.advancedSearch('no-filters')]: 'Ingen filtre valgt',
  [intl.advancedSearch('no-saved-filters')]: 'Du har ikke nogle gemte filtre',
  [intl.advancedSearch('over')]: 'Over',
  [intl.advancedSearch('placeholder-auditor')]: 'Skriv CVR eller revisorens navn',
  [intl.advancedSearch('profit_loss')]: 'Årsresultat',
  [intl.advancedSearch('range-from')]: 'Fra',
  [intl.advancedSearch('range-to')]: 'Til',
  [intl.advancedSearch('region')]: 'Region',
  [intl.advancedSearch('reset')]: 'Nulstil',
  [intl.advancedSearch('results-time-elapsed')]:
    '{count, plural, one {# resultat} other {# resultater}} på {time, plural, other {#}} sekunder',
  [intl.advancedSearch('return_on_assets')]: 'Afkastningsgrad',
  [intl.advancedSearch('save')]: 'Gem',
  [intl.advancedSearch('save-filter-label')]: 'Navngiv dit filter',
  [intl.advancedSearch('save-filter-title')]: 'Gem dit filter til fremtidig brug',
  [intl.advancedSearch('score')]: 'Risika score',
  [intl.advancedSearch('search-filter')]: 'Søg efter dit filter',
  [intl.advancedSearch('select-all')]: 'Vælg alle',
  [intl.advancedSearch('share_capital')]: 'Selskabskapital',
  [intl.advancedSearch('show_inactive')]: 'Vis inaktive',
  [intl.advancedSearch('snackbar-delete-filters-failed')]:
    'Der opstod et problem med at slette dit filter',
  [intl.advancedSearch('snackbar-delete-filters-success')]: 'Dit filter er slettet',
  [intl.advancedSearch('snackbar-saved-filters-failed')]:
    'Der opstod et problem med at gemme dit filter',
  [intl.advancedSearch('snackbar-saved-filters-success')]: 'Dit filter er gemt',
  [intl.advancedSearch('snackbar-update-filters-failed')]:
    'Der opstod et problem med at opdatere dit filter',
  [intl.advancedSearch('snackbar-update-filters-success')]: 'Dit filter er opdateret',
  [intl.advancedSearch('duration')]: 'Varighed',
  [intl.advancedSearch('period')]: 'Periode',
  [intl.advancedSearch('solidity')]: 'Soliditetsgrad',
  [intl.advancedSearch('specific_industry')]: 'Specifik branchegruppe',
  [intl.advancedSearch('status_valid_from')]: 'Virkssomhedsstatus gyldigt fra',
  [intl.advancedSearch('total_debt')]: 'Samlet gæld',
  [intl.advancedSearch('under')]: 'Under',
  [intl.advancedSearch('zipcode')]: 'Postnummer',
  [intl.advancedSearch('set-to-today')]: 'Indstil til i dag',
  [intl.annualReportInformation('approvalDate')]: 'Dato for godkendelse af årsrapporten',
  [intl.annualReportInformation('averageNumberOfEmployees')]:
    'Gennemsnitligt antal ansatte',
  [intl.annualReportInformation('classOfReportingEntity')]: 'Regnskabsklasse',
  [intl.annualReportInformation('company_name')]: 'Revisor',
  [intl.annualReportInformation('description')]: 'Revisorbetegnelse',
  [intl.annualReportInformation('get-auditor-information')]: 'Få revisorinformation',
  [intl.annualReportInformation('ifrs')]: 'IFRS',
  [intl.annualReportInformation('name')]: 'Revisors navn',
  [intl.annualReportInformation('no_information')]: 'Ingen information',
  [intl.annualReportInformation('show_less')]: 'Vis mindre',
  [intl.annualReportInformation('show_more')]: 'Vis mere',
  [intl.annualReportInformation('title')]: 'Regnskabsinformationer',
  [intl.annualReportInformation('type_of_assistance')]: 'Erklæringstype',
  [intl.auditorInfo('auditors_report')]: 'Revisorpåtegning',
  [intl.auditorInfo('auditors_report_on_extended_review')]:
    'Erklæring om udvidet gennemgang',
  [intl.auditorInfo('auditors_reports_(other_non-assurance_reports)')]:
    'Andre erklæringer uden sikkerhed',
  [intl.auditorInfo('company_name')]: 'Revisionsfirma',
  [intl.auditorInfo('name_of_auditor')]: 'Revisor',
  [intl.auditorInfo('no_auditor_assistance')]: 'Ingen revisor',
  [intl.auditorInfo('the_independent_auditors_reports_(review)')]:
    'Den uafhængige revisors erklæringer (review)',
  [intl.auditorInfo('type_of_assistance')]: 'Erklæringstype',
  [intl.auth('account-already-verified')]: 'Din konto er allerede verificeret',
  [intl.auth('account-deactivated')]: 'Din konto er blevet spærret',
  [intl.auth('account-deactivated-subtitle')]:
    'Du burde have modtaget en mail med instruktioner for, hvordan du kan genaktiverer den.',
  [intl.auth('account-deactivated-title')]: 'Din konto er spærret',
  [intl.auth('account-verified-failed')]:
    'Vi havde lidt udfordringer med at verificere din konto',
  [intl.auth('account-verified-failed-subtitle')]:
    'Hvis du skriver til os i chatten, så hjælper vi dig med at få adgang med det samme.',
  [intl.auth('account-verified-failed-title')]: 'Problemer med verificering',
  [intl.auth('account-verified-success')]:
    'Din konto er verificieret, og du kan nu logge ind',
  [intl.auth('already-have-an-account')]: 'Har du allerede en konto?',
  [intl.auth('change-password-failed')]:
    'Ser ud til der opstod et problem med at ændre dit password',
  [intl.auth('change-password-success')]: 'Du kan nu logge ind med dit nye password!',
  [intl.auth('change-password-title')]: 'Opret en ny password',
  [intl.auth('company-already-exists')]: 'Virksomheden er allerede oprettet',
  [intl.auth('company-label')]: 'Virksomhed',
  [intl.auth('company-placeholder')]: 'Søg efter din virksomhed',
  [intl.auth('confirm-new-password-label')]: 'Bekræft nye password',
  [intl.auth('confirm-new-password-placeholder')]: 'Bekræft dit nye password',
  [intl.auth('contact-us')]: 'Kontakt os:',
  [intl.auth('do-not-think-email-received')]: 'Kan du ikke se mailen i din indbakke?',
  [intl.auth('email')]: 'Email',
  [intl.auth('email-already-exists')]:
    'Denne email er allerede registreret. Kontakt os på contact@risika.dk',
  [intl.auth('email-label')]: 'E-mail',
  [intl.auth('email-placeholder')]: 'mig@virksomhed.dk',
  [intl.auth('email-sent-to')]: 'Vi sendte mailen til',
  [intl.auth('email-should-have-been-resend')]: 'Du burde have modtaget en ny mail',
  [intl.auth('finalize-account')]: 'Færdiggør konto',
  [intl.auth('first-name-label')]: 'Fornavn',
  [intl.auth('first-name-placeholder')]: 'Dit navn',
  [intl.auth('forgot-password')]: 'Glemt dit password?',
  [intl.auth('forgot-password-email-subtitle')]:
    'Fik du ikke e-mailen? Tjek din spam-mappe',
  [intl.auth('forgot-password-email-title')]:
    'Vi har sendt dig en e-mail for at nulstille din adgangskode',
  [intl.auth('forgot-password-failed')]: 'Beklager, det ser ud til der opstod et problem',
  [intl.auth('forgot-password-subtitle')]:
    'Indtast din e-mail, så sender vi dig instruktioner til at ændre din adgangskode',
  [intl.auth('forgot-password-title')]: 'Nulstil dit password',
  [intl.auth('get-me-in')]: 'Få mig ind',
  [intl.auth('get-started-free')]: 'Kom gratis i gang',
  [intl.auth('go-back')]: 'Gå tilbage',
  [intl.auth('go-back-to-log-in')]: 'Gå tilbage til log ind',
  [intl.auth('invalid-local-id')]: 'Venlist brug et gyldigt CVR-nummer',
  [intl.auth('last-name-label')]: 'Efternavn',
  [intl.auth('last-name-placeholder')]: 'Dit efternavn',
  [intl.auth('log-in')]: 'Log ind',
  [intl.auth('login-header')]: 'Log ind på din konto',
  [intl.auth('login-to-your-account')]: 'Log ind på din konto →',
  [intl.auth('min-password-length')]: 'Mindst 8 tegn',
  [intl.auth('new-email-sent')]: 'Vi har sendt dig en ny mail',
  [intl.auth('new-password-label')]: 'Nyt password',
  [intl.auth('new-password-placeholder')]: 'Dit nye password',
  [intl.auth('no-account-yet')]: 'Har du ikke en konto endnu?',
  [intl.auth('password-label')]: 'Password',
  [intl.auth('password-placeholder')]: 'Dit password',
  [intl.auth('personal-information')]: 'Personlig information',
  [intl.auth('phone')]: 'Telefon',
  [intl.auth('phone-number-label')]: 'Telefonnummer',
  [intl.auth('please-use-work-mail')]: 'Venlist brug din arbejdsmail',
  [intl.auth('register-account')]: 'Opret konto',
  [intl.auth('register-account-subtitle')]: 'Alle felter er påkrævet',
  [intl.auth('register-header')]: 'Opret din konto',
  [intl.auth('resend-email')]: 'Send ny mail',
  [intl.auth('send-email')]: 'Send e-mail',
  [intl.auth('session-expired')]: 'Din session er udløbet, log ind igen.',
  [intl.auth('showcase-subtitle')]:
    'Risika skaber sikkerhed i samhandlen mellem skandinaviske virksomheder via retvisende og præcise kreditvurderinger. ',
  [intl.auth('showcase-title')]: 'Den nemme vej til en sund forretning',
  [intl.auth('sso-login')]: 'Log ind med SSO',
  [intl.auth('terms-of-service')]: 'betingelserne',
  [intl.auth('understand-and-accept')]: 'Jeg forstår og accepterer',
  [intl.auth('user-registered-subtitle')]:
    'Vi har sendt dig en mail for at verificere din konto. Så snart du har gjort det, kan du komme i gang med Risika.',
  [intl.auth('user-registered-title')]: 'Verificer din konto',
  [intl.auth('user-with-email-exists')]: 'Der findes allerede en bruger med den e-mail',
  [intl.auth('work-email-label')]: 'Arbejdsmail',
  [intl.auth('wrong-email-or-password')]:
    'Den kombination af e-mail og adgangskode, du har indtastet, stemmer ikke overens med nogen konto. Prøv igen, eller nulstil din adgangskode',
  [intl.auth('your-company')]: 'Din virksomhed',
  [intl.auth('new-user')]: 'Ny bruger?',
  [intl.auth('create-an-account')]: 'Opret en konto',
  [intl.auth('back-to-sign-in')]: 'Tilbage til Log ind',
  [intl.auth('send-instructions')]: 'Send instruktioner',
  [intl.auth('send-instructions-again')]: 'Send instruktioner igen',
  [intl.auth('save-password')]: 'Gem adgangskode',
  [intl.auth('invalid-email')]: 'Indtast venligst en gyldig e-mailadresse',
  [intl.auth('invalid-password')]: 'Indtast venligst en gyldig adgangskode',
  [intl.auth('passwords-do-not-match')]: 'Adgangskoderne stemmer ikke overens',

  [intl.companyHierarchy('BENEFICIAL_OWNER_AND_LEGAL_OWNER')]: 'Legal og reel ejer',
  [intl.companyHierarchy('LEGAL_OWNER')]: 'Legal ejer',
  [intl.companyHierarchy('BENEFICIAL_OWNER')]: 'Reel ejer',
  [intl.companyHierarchy('monitor')]: 'Overvåge',
  [intl.companyHierarchy('equity')]: 'Egenkapital',
  [intl.companyHierarchy('local-id')]: 'Virksomheds ID',
  [intl.companyHierarchy('risika-score')]: 'Risika score',
  [intl.companyHierarchy('CEO')]: 'CEO',
  [intl.companyHierarchy('role')]: 'Rolle',
  [intl.companyHierarchy('profit-loss')]: 'Årets resultat',
  [intl.companyHierarchy('accessories')]: 'Valgmuligheder',
  [intl.companyHierarchy('collapse-all')]: 'Skjul',
  [intl.companyHierarchy('coming-soon')]: 'Kommer snart',
  [intl.companyHierarchy('company_information')]: 'Virksomhedsoplysninger',
  [intl.companyHierarchy('d3-child-name')]: 'Datterselskab til',
  [intl.companyHierarchy('detailed-view')]: 'Detaljeret oversigt',
  [intl.companyHierarchy('equity:')]: 'Egenkapital:',
  [intl.companyHierarchy('expand-all')]: 'Udvid alle {number} virksomheder',
  [intl.companyHierarchy('expand-all-d3')]: 'Udvid',
  [intl.companyHierarchy('print')]: 'Udskriv',
  [intl.companyHierarchy('expand-all')]: 'Udvid alle',
  [intl.companyHierarchy('collapse-all')]: 'Skjul alle',
  [intl.companyHierarchy('header')]: 'Koncernhierarki',
  [intl.companyHierarchy('list-view')]: 'LISTEVISNING',
  [intl.companyHierarchy('tree-view')]: 'TRÆSTRUKTUR',
  [intl.companyHierarchy('owners')]: '{number} ejere {toggled}',
  [intl.companyHierarchy('subsidiaries')]:
    '{number} {number, select, 1 {datterselskab} other {datterselskaber}} {toggled}',
  [intl.companyHierarchy('display-props')]: 'Vis egenskaber',
  [intl.companyHierarchy('listed-in')]: 'Angivet i: ',
  [intl.companyHierarchy('local-id:')]: 'Virksomheds ID:',
  [intl.companyHierarchy('monitor-all')]: 'Overvåg alle',
  [intl.companyHierarchy('name')]: 'Navn',
  [intl.companyHierarchy('no-credit-score')]: 'Selskabet har ingen kreditscore',
  [intl.companyHierarchy('no-legal-owners')]: 'Ingen legale ejere',
  [intl.companyHierarchy('no-real-owners')]: 'Ingen reelle ejere',
  [intl.companyHierarchy('ownership')]: 'Ejerandele',
  [intl.companyHierarchy('profit_loss')]: 'Årets resultat',
  [intl.companyHierarchy('profit_loss:')]: 'Årets resultat:',
  [intl.companyHierarchy('relations')]: 'Virksomhedsroller',
  [intl.companyHierarchy('save-view-description')]:
    'Her kan du gemme hierarkivisningen til den aktuelle session. Når du logger ud, bliver alle dine gemte visninger væk.',
  [intl.companyHierarchy('save-view-title')]: 'Gem hierarkivisning',
  [intl.companyHierarchy('select-list')]: 'Vælg en liste',
  [intl.companyHierarchy('sort_by')]: 'Sorter efter',
  [intl.companyHighlights('click-for-more')]: 'klik for mere',
  [intl.companyHighlights('title')]: 'Opmærksomhedspunkter',
  [intl.companyHighlights('title-with-currency')]: 'Regnskabstal ({currency})',
  [intl.companyHighlights('profit-loss')]: 'Årets resultat',
  [intl.companyHighlights('company-info')]: 'Oplysninger om virksomheden',
  [intl.fraudIndicators('title')]: 'Svigindikatorer',
  [intl.fraudIndicators('address')]: 'Adresse',
  [intl.fraudIndicators('financial')]: 'Finansiel',
  [intl.fraudIndicators('company')]: 'Virksomhed',
  [intl.fraudIndicators('enabled')]: 'Aktiveret',
  [intl.fraudIndicators('disabled')]: 'Deaktiveret',
  [intl.fraudIndicators('duplicate-statements-title')]: 'Dublerede erklæringer',
  [intl.fraudIndicators('duplicate-statements-description')]:
    'Sporer, om der blev fundet duplikerede regnskaber for en virksomhed',
  [intl.fraudIndicators('same-address-title')]: 'Flere virksomheder fra samme adresse',
  [intl.fraudIndicators('same-address-description')]:
    'Markerer tilfælde, hvor adskillige virksomheder er registreret på samme adresse, hvilket kan indikere shell-virksomheder eller svigagtig aktivitet',
  [intl.fraudIndicators('address-frequency-title')]: 'Hyppighed af adresseændringer',
  [intl.fraudIndicators('address-frequency-description')]:
    'Sporer antallet af gange, en virksomhed ændrer adresse, da hyppige flytninger kan være et rødt flag for svindel',
  [intl.fraudIndicators('autior-bankruptcies-title')]: 'Omvendt revisorkonkurskontrol',
  [intl.fraudIndicators('autior-bankruptcies-description')]:
    'Kontrollerer, om revisorer tilknyttet en virksomhed har en historie med konkurser, hvilket kan påvirke pålideligheden af ​​deres revisioner',
  [intl.fraudIndicators('profit-employees-title')]:
    'Fortjeneste vs. ansatte (abnormaliteter)',
  [intl.fraudIndicators('profit-employees-description')]:
    'Analyserer forholdet mellem virksomhedens overskud og medarbejderantal og identificerer uoverensstemmelser, der kan indikere svigagtig rapportering',
  [intl.fraudIndicators('financial-statements-title')]: 'Regnskabsafvigelser År over',
  [intl.fraudIndicators('financial-statements-description')]:
    'Registrerer uregelmæssigheder i regnskaber over på hinanden følgende år, hvilket fremhæver potentiel økonomisk manipulation eller bedrageri',
  [intl.fraudIndicators('duplicate-statements-multiple-companies-title')]:
    'Duplikerede regnskaber fra andre virksomheder',
  [intl.fraudIndicators('duplicate-statements-multiple-companies-description')]:
    'Virksomhedens indleveret regnskab ligner andres virksomhedernes regnskaber',
  [intl.fraudIndicators('ownership-changes-title')]: 'Ejerskifte',
  [intl.fraudIndicators('ownership-changes-description')]:
    'Virksomheden har haft hyppigt ejerskifte',
  [intl.fraudIndicators('purchase-of-new-company-title')]: 'Nyligt opkøb af virksomhed',
  [intl.fraudIndicators('purchase-of-new-company-description')]:
    'Virksomheden har for nyligt opkøb en eller flere virksomheder',
  [intl.fraudIndicators('inactive')]: 'Inaktiv',
  [intl.fraudIndicators('active')]: 'Aktiv',
  [intl.fraudIndicators('activate-fraud-indicators-title')]: 'Aktiver svindelindikatorer',
  [intl.fraudIndicators('activate-fraud-indicators-description')]:
    'Du er ved at aktivere Fraud Indicators. Er du sikker på, at du vil fortsætte?',
  [intl.fraudIndicators('deactivate-fraud-indicators-title')]:
    'Deaktiver svindelindikatorer',
  [intl.fraudIndicators('deactivate-fraud-indicators-description')]:
    'Du er ved at deaktivere svindelindikatorer. Er du sikker på, at du vil fortsætte?',
  [intl.fraudIndicators('save-changes')]: 'Gem ændringer',
  [intl.fraudIndicators('save-changes-success')]: 'Bedrageripolitikken blev gemt',
  [intl.fraudIndicators('save-changes-error')]: 'Fejl under lagring af svindelpolitikken',
  [intl.fraudIndicators('save_modal_title')]: 'Gem ændringer til Fraud Indicators',
  [intl.fraudIndicators('save_modal_text')]:
    'Det ser ud til, at du har foretaget nogle ændringer i dine svindelindikatorer. Er du sikker på, at du gerne vil forlade uden at gemme?',
  [intl.fraudIndicators('risk-indicators-sentence')]: 'Risikoindikatorer',
  [intl.fraudIndicators('distance_to_ceo')]: 'Afstand til administrerende direktør',
  [intl.fraudIndicators('duplicate_statements')]: 'Dublerede erklæringer',
  [intl.fraudIndicators('frequency_of_address_changes')]: 'Hyppige adresseændringer',
  [intl.fraudIndicators('prepaid-phone-number-title')]: 'Taletidskort numre',
  [intl.fraudIndicators('prepaid-phone-number-description')]:
    'Virksomheden bruger et forudbetalt telefonnummer, hvilket kan indikere en potentiel svindelrisiko',
  [intl.fraudIndicators('distance-to-ceo-title')]: 'Afstand til CEO',
  [intl.fraudIndicators(
    'distance-to-ceo-description'
  )]: `Kontrollér, om virksomheden har usædvanlig lang afstand mellem CEO’s bopæl og virksomhedens adresse`,
  [intl.fraudIndicators('phone-number-registry-title')]: 'Registreret telefon',
  [intl.fraudIndicators('phone-number-registry-description')]:
    'Virksomheden bruger ikke et registreret telefonnummer, hvilket kan indikere en potentiel svindelrisiko',
  [intl.fraudIndicators('company-name-changes-title')]: 'Virksomhedsnavn endringer',
  [intl.fraudIndicators('company-name-changes-description')]:
    'Dette kan afvige fra ændringer i registreret firmanavn. At være i stand til at validere faktiske virksomhedsnavneændringer hjælper os med at identificere mønstre, som kan indikere risiko',
  [intl.companyInfo('ad-protection')]: 'Reklamebeskyttelse',
  [intl.companyInfo('ad-protection-allowed')]:
    'Virksomheden har accepteret at blive kontaktet vedrørende reklame',
  [intl.companyInfo('ad-protection-denied')]:
    'Virksomheden har nægtet at blive kontaktet vedrørende reklame',
  [intl.companyInfo('add-internal-id-label')]: 'Internt ID',
  [intl.companyInfo('add-internal-id-title')]: 'Tilføj internt ID',
  [intl.companyInfo('address')]: 'Adresse',
  [intl.companyInfo('address-changes-count')]: 'Adresseændringer',
  [intl.companyInfo('auditors')]: 'Revisor',
  [intl.companyInfo('bank')]: 'Bank',
  [intl.companyInfo('company-id')]: 'Virksomheds ID',
  [intl.companyInfo('company-info-re-arrange')]:
    'Skræddersy de generelle virksomhedsoplysninger til dine behov ved at trække felterne nedenfor!',
  [intl.companyInfo('company-name')]: 'Virksomhedsnavn',
  [intl.companyInfo('company-name-changes-count')]: 'Virksomhedsnavn endringer',
  [intl.companyInfo('company-name-changes-count-explanation')]:
    'Dette kan afvige fra ændringer i registreret firmanavn. At være i stand til at validere faktiske virksomhedsnavneændringer hjælper os med at identificere mønstre, som kan indikere risiko',
  [intl.companyInfo('company-name-required')]: 'Virksomhedsnavn *',
  [intl.companyInfo('company-type')]: 'Virksomhedstype',
  [intl.companyInfo('date-of-establishment')]: 'Stiftelsesdato',
  [intl.companyInfo('date-of-incorporation')]: 'Stiftet før 1900',
  [intl.companyInfo('ean')]: 'EAN Nummer',
  [intl.companyInfo('edit-internal-id-label')]: 'Internt ID',
  [intl.companyInfo('edit-internal-id-title')]: 'Rediger internt ID',
  [intl.companyInfo('email')]: 'E-mail',
  [intl.companyInfo('employees')]: 'Ansatte',
  [intl.companyInfo('financial_year')]: 'Regnskabsår',
  [intl.companyInfo('founders_auditors')]: 'Stiftere',
  [intl.companyInfo('fte')]: 'Årsværk',
  [intl.companyInfo('industry')]: 'Branche',
  [intl.companyInfo('internal-id')]: 'Internt ID',
  [intl.companyInfo('invalid')]: 'Ugyldigt',
  [intl.companyInfo('land_value')]: 'Grundværdi',
  [intl.companyInfo('listed')]: 'Børsnoteret',
  [intl.companyInfo('number-of-employees')]: 'Antal ansatte',
  [intl.companyInfo('phone')]: 'Telefon',
  [intl.companyInfo('powers-to-bind')]: 'Tegningsregel',
  [intl.companyInfo('production-units')]: 'Produktionsenheder',
  [intl.companyInfo('property_id')]: 'Bygningsnummer',
  [intl.companyInfo('property_type')]: 'Ejendomstype',
  [intl.companyInfo('property_value')]: 'Ejendomsværdi',
  [intl.companyInfo('purpose')]: 'Formål',
  [intl.companyInfo('registered-for-vat')]: 'Momsregistreret',
  [intl.companyInfo('secondary-names')]: 'Binavne',
  [intl.companyInfo('share-capital')]: 'Selskabskapital',
  [intl.companyInfo('share_capital')]: 'Selskabskapital',
  [intl.companyInfo('source')]: 'Kilde',
  [intl.companyInfo('tinglysning')]: 'Tinglysning',
  [intl.companyInfo('title')]: 'Generel virksomhedsinformation',
  [intl.companyInfo('valid-from')]: 'Gyldig fra',
  [intl.companyInfo('valid-to')]: 'Gyldig til',
  [intl.companyInfo('valuation_date')]: 'Vurderingsdato',
  [intl.companyInfo('webpage')]: 'Hjemmeside',
  [intl.companyInfo('number-of-employees-data-display')]:
    '{date, select, undefined {N/A} other {{date}}}: {employeesInterval} {employeesNumber, select, undefined {{fte, select, undefined {} other {(}}} other {({employeesNumber} medarbejdere}}{fte, select, undefined {{employeesNumber, select, undefined {} other {)}}} other {{employeesNumber, select, undefined {} other {, }}{fte} Årsværk)}}',
  [intl.companyInfo('address-changes-count-explanation')]:
    'Dette kan afvige fra registrerede adresseændringer. At kunne validere faktiske adresseændringer hjælper os med at identificere mønstre, som kan indikere svigagtig aktivitet',
  [intl.companyInfo('fraud-indicators-title')]: 'Risikoindikatorer',
  [intl.companyRoles('advanced-view')]: 'Avanceret',
  [intl.companyRoles('analysis-of-bankruptcies')]: 'Konkursanalyse',
  [intl.companyRoles('before-1987')]: 'Før 1987',
  [intl.companyRoles('board-of-directors')]: 'Bestyrelse',
  [intl.companyRoles('get-overview')]: 'Få oversigt',
  [intl.companyRoles('hide-historical-data')]: 'Skjul historisk data',
  [intl.companyRoles('historical-management')]: 'Historical management',
  [intl.companyRoles('history-hide')]: 'Skjul historik',
  [intl.companyRoles('history-show')]: 'Vis historik',
  [intl.companyRoles('legal-owners')]: 'Legale ejere',
  [intl.companyRoles('minimize-table')]: 'Simpel oversigt',
  [intl.companyRoles('month')]: '1 måned',
  [intl.companyRoles('months')]: '{months} måneder',
  [intl.companyRoles('no-board-of-directors')]: 'Ingen bestyrelse fundet',
  [intl.companyRoles('no-information')]: 'Oplysningerne er ikke tilgængelige',
  [intl.companyRoles('no-management')]: 'Ingen ledelse fundet',
  [intl.companyRoles('no-owners')]: 'Ingen ejere',
  [intl.companyRoles('other-positions')]: ' se mere...',
  [intl.companyRoles('real-owners')]: 'Reelle ejere',
  [intl.companyRoles('see-hierarchy')]: 'Se koncernhierarki',
  [intl.companyRoles('see-historical-data')]: 'Se historisk data',
  [intl.companyRoles('shares')]: 'andel',
  [intl.companyRoles('show-all')]: 'Vis alle',
  [intl.companyRoles('show-full-table')]: 'Fuld oversigt',
  [intl.companyRoles('show-less')]: 'Vis færre',
  [intl.companyRoles('simple-view')]: 'Simpel',
  [intl.companyRoles('subsidiaries')]: 'Datterselskaber',
  [intl.companyRoles('title')]: 'Ledelse',
  [intl.companyRoles('title-owners')]: 'Ejere',
  [intl.companyRoles('total')]: 'Total: ',
  [intl.companyRoles('total-owners')]: 'I alt ejere',
  [intl.companyRoles('year')]: '1 år ',
  [intl.companyRoles('years')]: '{years} år ',
  [intl.companyRoles('title-map')]: 'Kort',
  [intl.confirmAction('agree')]: 'Bekræft',
  [intl.confirmAction('cancel')]: 'Annuller',
  [intl.confirmAction('confirm')]: 'Bekræft',
  [intl.confirmAction('delete')]: 'Slet',
  [intl.confirmAction('save')]: 'Gem',
  [intl.country('de')]: 'Tyskland',
  [intl.country('dk')]: 'Danmark',
  [intl.country('fi')]: 'Finland',
  [intl.country('no')]: 'Norge',
  [intl.country('se')]: 'Sverige',
  [intl.country('uk')]: 'UK',
  [intl.creditCheck('auditor')]: 'Revisoroplysninger',
  [intl.creditCheck('boundary-line-1')]:
    'Der opstod et problem under behandlingen af ​​din anmodning. Prøv igen senere!',
  [intl.creditCheck('boundary-line-2')]:
    'Hvis det ikke virker, så prøv at logge ud og ind igen',
  [intl.creditCheck('boundary-line-3')]:
    'Vi er blevet underrettet om problemet og er i fuld gang med at løse det',
  [intl.creditCheck('beneficial_owner')]: 'Reelle ejere',
  [intl.creditCheck('cant-score-comapny')]: 'Vi kunne ikke score dette firma!',
  [intl.creditCheck('check-use-fail')]: 'Ingen oplysninger for udvalgt firma',
  [intl.creditCheck('check-use-success')]: 'Du brugte ét kredittjek',
  [intl.creditCheck('credit-check-re-arrange')]:
    'Tilpas din visning ved at flytte på felterne nedenfor',
  [intl.creditCheck('from')]: 'Fra',
  [intl.creditCheck('generate-report')]: 'Hent PDF-rapport',
  [intl.creditCheck('highlights')]: 'Bemærkninger',
  [intl.creditCheck('historical-scores')]: 'Se historiske score',
  [intl.creditCheck('monitor-company')]: 'Overvåg virksomhed',
  [intl.creditCheck('name')]: 'Navn',
  [intl.creditCheck('no-company-redirect')]:
    'Ingen virksomhed valgt, sender dig tilbage til "søg virksomhed"',
  [intl.creditCheck('no-data')]:
    'Koncernhierarki er endnu ikke tilgængelig for dette land',
  [intl.creditCheck('no-subsidiaries')]: 'Selskabet har ingen datterselskaber',
  [intl.creditCheck('otherPosition')]: 'Andre roller',
  [intl.creditCheck('ownership')]: 'Ejerandele',
  [intl.creditCheck('pep')]: 'PEP-liste',
  [intl.creditCheck('period')]: 'Årsrapport periode',
  [intl.creditCheck('position')]: 'Rolle',
  [intl.creditCheck('report-button')]: 'Del rapport',
  [intl.creditCheck('select-list')]: 'Vælg en liste',
  [intl.creditCheck('seniority')]: 'Anciennitet',
  [intl.creditCheck('share')]: 'Del',
  [intl.creditCheck('share-internal-header')]: 'Brugere fra din organisation',
  [intl.creditCheck('share-report')]: 'Del PDF-rapport',
  [intl.creditCheck('share-report-description')]:
    'Her kan du sende en kreditrapport til en valgfri e-mailadresse eller til en bruger i din organisation.',
  [intl.creditCheck('share-report-message')]: 'Tilføj en besked sammen med rapporten',
  [intl.creditCheck('share-specific-email-header')]: 'Send til en valgfri e-mail',
  [intl.creditCheck('shares')]: 'Aktier',
  [intl.creditCheck('specialRights')]: 'Særlige bemærkninger',
  [intl.creditCheck('stop-monitor-company')]: 'Stop overvågning',
  [intl.creditCheck('subheader-placeholder')]: 'E-mail på modtager',
  [intl.creditCheck('title')]: 'Kredittjek',
  [intl.creditCheck('to')]: 'Til',
  [intl.creditCheck('use-check-for-auditor-title')]:
    'Er du sikker på, du vil bruge ét kredittjek?',
  [intl.creditCheck('use-credit')]: 'Brug kredittjek',
  [intl.creditCheck('votingRights')]: 'Stemmerettigheder',
  [intl.creditPolicy("AUDITOR'S REPORT ON EXTENDED REVIEW")]:
    'Erklæring om udvidet gennemgang',
  [intl.creditPolicy("AUDITOR'S REPORT")]: 'Revisorpåtegning',
  [intl.creditPolicy("AUDITOR'S REPORTS (OTHER NON-ASSURANCE REPORTS)")]:
    'Andre erklæringer uden sikkerhed',
  [intl.creditPolicy("THE INDEPENDENT AUDITOR'S REPORTS (REVIEW)")]:
    'Den uafhængige revisors erklæringer (review)',
  [intl.creditPolicy('COMPILATION OF FINANCIAL STATEMENTS')]:
    'Sammensætning af regnskaber.',
  [intl.creditPolicy('1')]: '1',
  [intl.creditPolicy('1-and-more')]: '1 og mere',
  [intl.creditPolicy('1-year')]: '1 år',
  [intl.creditPolicy('10-and-more')]: '10',
  [intl.creditPolicy('2')]: '2',
  [intl.creditPolicy('2-and-more')]: '2 og mere',
  [intl.creditPolicy('2-year')]: '2 år',
  [intl.creditPolicy('3')]: '3',
  [intl.creditPolicy('3-and-more')]: '3 og mere',
  [intl.creditPolicy('3-year')]: '3 år',
  [intl.creditPolicy('4')]: '4',
  [intl.creditPolicy('4-and-more')]: '4 og mere',
  [intl.creditPolicy('5')]: '5',
  [intl.creditPolicy('6')]: '6',
  [intl.creditPolicy('7')]: '7',
  [intl.creditPolicy('8')]: '8',
  [intl.creditPolicy('5-and-more')]: '5 og flere',
  [intl.creditPolicy('6-and-more')]: '6 og mere',
  [intl.creditPolicy('7-and-more')]: '7 og mere',
  [intl.creditPolicy('8-and-more')]: '8 og mere',
  [intl.creditPolicy('9-and-more')]: '9 og mere',
  [intl.creditPolicy('less-than-year')]: 'Less than a year old',
  [intl.creditPolicy('1-year-old')]: '1 year old',
  [intl.creditPolicy('2-year-old')]: '2 years old',
  [intl.creditPolicy('3-year-old')]: '3 years old',
  [intl.creditPolicy('4-year-old')]: '4 years old',
  [intl.creditPolicy('5-year-old')]: '5 years old',
  [intl.creditPolicy('NO AUDITOR ASSISTANCE')]: 'Ingen revisor',
  [intl.creditPolicy('POLICY_AGE')]: 'Virksomheden er for ung',
  [intl.creditPolicy('POLICY_AUDITOR_ASSISTANCE')]: 'Revisorerklæringen er ikke tilladt',
  [intl.creditPolicy('POLICY_BANKRUPTCIES')]: 'For mange konkurser',
  [intl.creditPolicy('POLICY_BANKRUPTCIES_LONG')]: 'For mange konkurser',
  [intl.creditPolicy('POLICY_NUMBER_OF_EMPLOYEES')]: 'Antal medarbejdere',
  [intl.creditPolicy('POLICY_COMPANY_TYPE')]: 'Afvist virksomhedstype',
  [intl.creditPolicy('POLICY_DISTANCE_TO_CEO')]: 'Stor afstand til CEO',
  [intl.creditPolicy('POLICY_ENTITY_ID')]: 'Afvist person',
  [intl.creditPolicy('POLICY_INDUSTRY')]: 'Industri afvist',
  [intl.creditPolicy('POLICY_LOCAL_ID')]: 'Afvist selskab',
  [intl.creditPolicy('POLICY_AUDITOR_HAS_ID')]: 'Revisor uden MNE-nummer',
  [intl.creditPolicy('POLICY_NEGATIVE_EQUITY')]: 'Negativ egenkapital',
  [intl.creditPolicy('POLICY_PHONE_NUMBER_REGISTRY')]:
    'Telefonnummer er ikke registreret',
  [intl.creditPolicy('POLICY_PREPAID_PHONE_NUMBER')]: 'Taletidskort numre',
  [intl.creditPolicy('POLICY_RISIKA_SCORE')]: 'Lav kreditscore',
  [intl.creditPolicy('POLICY_VAT')]: 'Moms',
  [intl.creditPolicy('above-1-year')]: 'Over 1 år',
  [intl.creditPolicy('above-2-year')]: 'Over 2 år',
  [intl.creditPolicy('above-3-year')]: 'Over 3 år',
  [intl.creditPolicy('above-4-year')]: 'Over 4 år',
  [intl.creditPolicy('activate-advanced-view')]:
    'Dette er en avanceret funktion. Kontakt os, hvis du vil prøve det!',
  [intl.creditPolicy('age-limitation-information')]:
    'Vælg den laveste virksomhedsalder som du vil tillade i jeres kreditpolitik',
  [intl.creditPolicy('age-limitation-title')]: 'Laveste virksomhedsalder',
  [intl.creditPolicy('allow-phone-number')]: 'Tillad',
  [intl.creditPolicy('are-you-sure')]:
    'Det ser ud til, at du har foretaget nogle ændringer i din kreditpolitik. Er du sikker på, at du vil forlade uden at gemme?',
  [intl.creditPolicy('are-you-sure-dialog')]:
    'Denne handling deaktiverer kreditpolitikken for hele din organisation. Er du sikker på, at du vil fortsætte?',
  [intl.creditPolicy('are-you-sure-dialog-clear-field')]:
    'Denne handling sletter alle poster i dette felt. Er du sikker på, at du vil fortsætte?',
  [intl.creditPolicy('auditor-assistance-information')]:
    'Vælg det laveste niveau af erklæring fra revisor som du vil tillade i jeres kreditpolitik',
  [intl.creditPolicy('auditor-assistance-title')]: 'Ikke godkendte erklæringstyper',
  [intl.creditPolicy('auditor-placeholder')]: 'Vælg mange',
  [intl.creditPolicy('auditors_report')]: 'Revisorpåtegning',
  [intl.creditPolicy('bankruptcy-short-limit-information')]:
    'Hvor mange relaterede konkurser indenfor de sidste 10 år vil jeres kreditpolitik tillade?',
  [intl.creditPolicy('bankruptcy-long-limit-information')]:
    'Hvor mange relaterede konkurser indenfor de sidste 50 år vil jeres kreditpolitik tillade?',
  [intl.creditPolicy('bankruptcy-short-limit-title')]:
    'Konkursforbindelser (sidste 10 år) ',
  [intl.creditPolicy('bankruptcy-long-limit-title')]:
    'Maksimalt antal relateret konkurser (50 år)',
  [intl.creditPolicy('company-does-not-comply-with-policy')]:
    'Virksomheden overholder ikke kreditpolitikken',
  [intl.creditPolicy('company-types-information')]:
    'Frasorter virksomhedstyper i jeres kreditpolitik',
  [intl.creditPolicy('company-types-title')]: 'Frasorteret virksomhedstyper',
  [intl.creditPolicy('copied-success')]: 'Politik kopieret med succes',
  [intl.creditPolicy('copy-policy')]: 'Kopiér politik',
  [intl.creditPolicy('copy-policy-title')]: 'Vælg det land og de felter, du vil kopiere!',
  [intl.creditPolicy('distance-to-ceo-information')]:
    "CEO's afstand i km fra virksomhedsadressen",
  [intl.creditPolicy('distance-to-ceo-title')]: 'Afstand til CEO',
  [intl.creditPolicy('enter-number')]: 'Indtast afstand (km)',
  [intl.creditPolicy('dk')]: 'Danmark',
  [intl.creditPolicy('dont-allow-phone-number')]: 'Tillad ikke',
  [intl.creditPolicy('exclude-companies-information')]:
    'Frasorter specifikke virksomheder i jeres kreditpolitik',
  [intl.creditPolicy('exclude-companies-title')]:
    'Frasorter specifikke virksomheder i jeres kreditpolitik',
  [intl.creditPolicy('exclude-persons-information')]:
    'Frasorter specifikke personer i jeres kreditpolitik',
  [intl.creditPolicy('exclude-persons-title')]: 'Frasorter personer',
  [intl.creditPolicy('exclude-vat-information')]:
    'Skal et firma være momsregistreret, eller skal det bare være under den tilladte grænse for et land?',
  [intl.creditPolicy('exclude-vat-title')]:
    'Ekskluder virksomheder, der ikke er momsregistrerede',
  [intl.creditPolicy('less-than-1')]: 'lindre end 1',
  [intl.creditPolicy('more-than-3')]: '3 og mere',
  [intl.creditPolicy('more-than-4')]: '4 og mere',
  [intl.creditPolicy('more-than-3-years')]: 'Mere end 3 år',
  [intl.creditPolicy('more-than-4-years')]: 'Mere end 4 år',
  [intl.creditPolicy('more-than-5')]: '5 og mere',
  [intl.creditPolicy('name_of_auditor')]: 'Revisor',
  [intl.creditPolicy('negative-equity-information')]:
    'Vælg det antal år uden negativ egenkapital som du vil tillade i jeres kreditpolitik',
  [intl.creditPolicy('negative-equity-title')]: 'Seneste år uden negativ egenkapital',
  [intl.creditPolicy('no')]: 'Norge',
  [intl.creditPolicy('no-conflicts')]: 'Ingen konflikter med kreditpolitikken.',
  [intl.creditPolicy('none')]: 'Ingen',
  [intl.creditPolicy('off')]: 'Tillad',
  [intl.creditPolicy('phone-number-registry-information')]:
    'Er telefonnummeret registreret i telefonbogen',
  [intl.creditPolicy('phone-number-registry-title')]: 'Registreret telefonnummer',
  [intl.creditPolicy('policy-not-available-for-non-admin-users')]:
    'Kreditpolitikken er kun tilgængelig for administratorer i jeres organisation. Hvis du mener at du skulle kunne ændre i kreditpolitikken, kontakt derved jeres administrator eller os på contact@risika.dk.',
  [intl.creditPolicy('policy-off')]: 'Kreditpolitik deaktiveret',
  [intl.creditPolicy('policy-on')]: 'Kreditpolitik aktiveret',
  [intl.creditPolicy('policy-switch-failed')]: 'Kunne ikke skifte kreditpolitik',
  [intl.creditPolicy('policy-switch-off-success')]:
    'Din kreditpolitik for {country} er deaktiveret',
  [intl.creditPolicy('policy-switch-on-success')]:
    'Din kreditpolitik for {country} er aktiveret',
  [intl.creditPolicy('prepaid-phone-number-information')]:
    'Bliv notificeret hvis et firma bliver oprettet med et taletidskort nummer',
  [intl.creditPolicy('prepaid-phone-number-title')]: 'Tillad taletidskort numre',
  [intl.creditPolicy('risika-score-information')]:
    'Vælg den laveste Risika Score som du vil tillade i jeres kreditpolitik',
  [intl.creditPolicy('risika-score-title')]: 'Laveste Risika Score',
  [intl.creditPolicy('save-failed')]:
    'Kunne ikke gemme kreditpolitikken for {country}. Prøv igen senere!',
  [intl.creditPolicy('save-success')]: 'Gemt kreditpolitik for {country}',
  [intl.creditPolicy('se')]: 'Sverige',
  [intl.creditPolicy('search')]: 'Søg og tilføj',
  [intl.creditPolicy('select')]: 'Vælg',
  [intl.creditPolicy('select-many')]: 'Vælg mange',
  [intl.creditPolicy('should-be')]: 'Tillad ikke',
  [intl.creditPolicy('should-be-and-exceeded')]:
    'Kun hvis det er over den lokale momsgrænse',
  [intl.creditPolicy('specific-address-title')]: 'Exclude addresses',
  [intl.creditPolicy('specific-industry-information')]:
    'Frasorter specifikke industrier i jeres kreditpolitik',
  [intl.creditPolicy('specific-industry-title')]: 'Frasorter industrier',
  [intl.creditPolicy('too-many-values')]:
    'Værdier for {country} overskrider grænsen. Tilføjet kun 500 virksomheder!',
  [intl.creditPolicy('type_of_assistance')]: 'Erklæringstype',
  [intl.creditPolicy('uk')]: 'UK',
  [intl.creditPolicy('up-to-1')]: '1 og mindre',
  [intl.creditPolicy('up-to-2')]: '2 og mindre',
  [intl.creditPolicy('up-to-3')]: '3 og mindre',
  [intl.creditPolicy('up-to-4')]: '4 og mindre',
  [intl.creditPolicy('up-to-5')]: '5 og mindre',
  [intl.creditPolicy('auditor-id')]: 'Revisor skal have et identifikationsnummer (MNE)',
  [intl.creditPolicy('auditor-id-information')]:
    'Tillad kun godkendte revisorer med et MNE-nummer. MNE-nummeret er et unikt offentligt identifikationsnummer på samtlige godkendte revisorer.',
  [intl.creditPolicy('restriction-modal-title')]:
    'Opgrader til Pro og få adgang til avancerede funktioner',
  [intl.creditPolicyNew('restriction-modal-title')]:
    'Opgrader til Pro og få adgang til avancerede funktioner',
  [intl.creditPolicyNew('restriction-modal-list-1')]:
    'Tilpas din kreditpolitik med yderligere 12 variabler',
  [intl.creditPolicyNew('restriction-modal-list-2')]:
    'Få adgang til data på tværs af 3 nordiske lande',
  [intl.creditPolicyNew('restriction-modal-list-3')]:
    'Overvåg op til 500 virksomheder pr. måned',
  [intl.creditPolicyNew('restriction-modal-paragraph')]:
    'Tal med en salgsrepræsentant for at diskutere dine behov og finde den bedste løsning til din virksomhed',
  [intl.creditPolicyNew('credit-policy-page-title')]: 'Kreditpolitik',
  [intl.creditPolicyNew('industry-type')]: 'Branche type',
  [intl.creditPolicyNew('back-to-home')]: 'Tilbage til Hjemmet',
  [intl.creditPolicyNew('companies-in-country')]: 'Virksomheder i {country}',
  [intl.creditPolicyNew('credit-policy-builder-history-title')]: 'Kreditpolitikbygger',
  [intl.creditPolicyNew('company-type')]: 'Virksomheds Type',
  [intl.creditPolicyNew('risika-score')]: 'Risika Score',
  [intl.creditPolicyNew('cvr-number')]: 'CVR-nummer',
  [intl.creditPolicyNew('prepaid-phone')]: 'Forudbetalt telefon',
  [intl.creditPolicyNew('distance-to-ceo')]: 'Afstand til CEO',
  [intl.creditPolicyNew('audit')]: 'Revidere',
  [intl.creditPolicyNew('equity')]: 'Egenkapital',
  [intl.creditPolicyNew('accept-credit')]: 'Accepter kredit',
  [intl.creditPolicyNew('manual-check')]: 'Manuel kontrol',
  [intl.creditPolicyNew('reject-credit')]: 'Afvis kredit',
  [intl.creditPolicyNew('condition')]: 'Betingelse',
  [intl.creditPolicyNew('type')]: 'Type',
  [intl.creditPolicyNew('tolerance')]: 'Tolerance',
  [intl.creditPolicyNew('delete-rule-button-label')]: 'Slet',
  [intl.creditPolicyNew('import-a-policy-button')]: 'Importer en politik',
  [intl.creditPolicyNew('create-a-policy-button')]: 'Opret en ny politik',
  [intl.creditPolicyNew('terms')]: 'Vilkår',
  [intl.creditPolicyNew('method')]: 'Metode',
  [intl.creditPolicyNew('credit-definition')]: 'Kreditdefinition',
  [intl.creditPolicyNew('or-else-if-no-data-is-available')]:
    'Or else if no data is available',
  [intl.creditPolicyNew('company_vat')]:
    'Kombineret med andre faktorer som virksomhedens alder, ledelsens anciennitet eller omsætning kan virksomhedsmoms være en god indikator for svindel. Medmindre de er fritaget, bør de fleste virksomheder være momsregistreret. Hvis de ikke er det, kan de udgøre en risiko på grund af deres størrelse, eller det kan være et tegn på, at de skjuler en del af deres aktiviteter.',
  [intl.creditPolicyNew('define_credit_max')]: 'Definer kreditmaksimum',
  [intl.creditPolicyNew('define_payment_terms')]: 'Definer betalingsbetingelser',
  [intl.creditPolicyNew('use_risika_recommendation')]: 'Brug Risika anbefaling',
  [intl.creditPolicyNew('set_your_own')]: 'Definer dit eget',
  [intl.creditPolicyNew('set_your_own_value')]: 'Sæt din egen værdi',
  [intl.creditPolicyNew('percentage_of_risika_score')]: 'Procent af Risika Score',
  [intl.creditPolicyNew('value')]: 'Værdi',
  [intl.creditPolicyNew('not_greater_than')]: 'Ikke større end',
  [intl.creditPolicyNew('not_lower_than')]: 'Ikke lavere end',
  [intl.creditPolicyNew('set-limit')]: 'Indstil grænse (valgfri)',
  [intl.creditPolicyNew('create-first-policy')]: 'Begynd at oprette din første politik',
  [intl.creditPolicyNew('create-first-policy-cta')]:
    'Opret en ny politik fra bunden eller ',
  [intl.creditPolicyNew('import')]: 'importer',
  [intl.creditPolicyNew('existing-one')]: ' en eksisterende',
  [intl.creditPolicyNew('you-have-no-policies')]:
    ' Dit team har i øjeblikket ingen politikker',

  [intl.creditPolicyNew('is')]: 'Er',
  [intl.creditPolicyNew('is-not')]: 'Er ikke',
  [intl.creditPolicyNew('equity_without_dividend')]: 'Eksklusive udbytte',
  [intl.creditPolicyNew('greater-than')]: 'Større end',
  [intl.creditPolicyNew('equal-to')]: 'Lige med',
  [intl.creditPolicyNew('equals')]: 'Lige med',
  [intl.creditPolicyNew('less-than')]: 'Mindre end',
  [intl.creditPolicyNew('less_than')]: 'Mindre end',
  [intl.creditPolicyNew('between')]: 'Mellem',
  [intl.creditPolicyNew('select')]: 'Vælge',
  [intl.creditPolicyNew('prepaid-phone-status')]: 'Prepaid phone status',
  [intl.creditPolicyNew('registered-prepaid-phone')]: 'Registered prepaid phone',
  [intl.creditPolicyNew('no-prepaid-phone-registered')]: 'No prepaid phone registered',
  [intl.creditPolicyNew('no-data-yet')]: 'Still no data...',
  [intl.creditPolicyNew('distance-to-ceo-node-title')]: 'Afstand til CEO',
  [intl.creditPolicyNew('equity-node-title')]: 'Equity',
  [intl.creditPolicyNew('prepaid-phone-node-title')]: 'Prepaid phone',
  [intl.creditPolicyNew('something-wrong-with-node-data')]: 'No or corrupted data',
  [intl.creditPolicyNew('no-companies-in-list')]: 'No companies in list',
  [intl.creditPolicyNew('action-panel-cancel-button')]: 'Cancel',
  [intl.creditPolicyNew('action-panel-confirm-button')]: 'Confirm',
  [intl.creditPolicyNew('manual-check-description-label')]: 'Add some notes (optional)',
  [intl.creditPolicyNew('reject-credit-description-label')]: 'Add some notes (optional)',
  [intl.creditPolicyNew('company-list-description-label')]: 'Select a list',
  [intl.creditPolicyNew('company-list-create-new')]: 'Create a new list',
  [intl.creditPolicyNew('search-multi-select-apply')]: 'Anvend',
  [intl.creditPolicyNew('search-multi-select-clear-all')]: 'Fjern alle',
  [intl.creditPolicyNew('please-select')]: 'Vælg venligst',
  [intl.creditPolicyNew('none')]: 'Ingen',
  [intl.creditPolicyNew('1')]: '1',
  [intl.creditPolicyNew('2')]: '2',
  [intl.creditPolicyNew('3')]: '3',
  [intl.creditPolicyNew('4')]: '4',
  [intl.creditPolicyNew('5')]: '5',
  [intl.creditPolicyNew('6')]: '6',
  [intl.creditPolicyNew('7')]: '7',
  [intl.creditPolicyNew('8')]: '8',
  [intl.creditPolicyNew('9')]: '9',
  [intl.creditPolicyNew('10')]: '10',
  [intl.creditPolicyNew('1-year')]: '1 år',
  [intl.creditPolicyNew('2-years')]: '2 år',
  [intl.creditPolicyNew('3-years')]: '3 år',
  [intl.creditPolicyNew('4-years')]: '4 år',
  [intl.creditPolicyNew('5-years')]: '5 år',
  [intl.creditPolicyNew('6-years')]: '6 år',
  [intl.creditPolicyNew('7-years')]: '7 år',
  [intl.creditPolicyNew('8-years')]: '8 år',
  [intl.creditPolicyNew('9-years')]: '9 år',
  [intl.creditPolicyNew('10-years')]: '10 år',
  [intl.creditPolicyNew('1-and-more')]: '1 og mere',
  [intl.creditPolicyNew('2-and-more')]: '2 og mere',
  [intl.creditPolicyNew('3-and-more')]: '3 og mere',
  [intl.creditPolicyNew('4-and-more')]: '4 og mere',
  [intl.creditPolicyNew('5-and-more')]: '5 og flere',
  [intl.creditPolicyNew('6-and-more')]: '6 og mere',
  [intl.creditPolicyNew('7-and-more')]: '7 og mere',
  [intl.creditPolicyNew('8-and-more')]: '8 og mere',
  [intl.creditPolicyNew('9-and-more')]: '9 og mere',
  [intl.creditPolicyNew('10-and-more')]: '10 og mere',

  [intl.creditPolicyNew('less-than-year')]: 'Mindre end et år gammel',
  [intl.creditPolicyNew('1-year-old')]: '1 år gammel',
  [intl.creditPolicyNew('2-year-old')]: '2 år gammel',
  [intl.creditPolicyNew('3-year-old')]: '3 år gammel',
  [intl.creditPolicyNew('4-year-old')]: '4 år gammel',
  [intl.creditPolicyNew('5-year-old')]: '5 år gammel',

  [intl.creditPolicyNew('not-registered')]:
    'Ekskluder enhver virksomhed, der ikke er momsregistreret',
  [intl.creditPolicyNew('should-be-registered')]:
    'Ekskluder kun virksomheder, der burde være momsregistreret',
  [intl.creditPolicyNew('company-type-desc')]:
    'Angiv de virksomhedstyper, du gerne vil afvise i din politik.',
  [intl.creditPolicyNew('company-years-title')]: 'Virksomhedens alder',
  [intl.creditPolicyNew('company-years-desc')]:
    'Vælg det mindste antal år, en virksomhed skal have eksisteret, for at blive godkendt',
  [intl.creditPolicyNew('industry-type-title')]: 'Branchetype',
  [intl.creditPolicyNew('industry-type-desc')]:
    'List branchetyperne du gerne vil ekskludere fra din politik',
  [intl.creditPolicyNew('number-of-employees-title')]: 'Antal ansatte',
  [intl.creditPolicyNew('number-of-employees-desc')]:
    'Fastlæg den mindste virksomhedsstørrelse i form af antal ansatte, som du vil tillade i din politik',
  [intl.creditPolicyNew('risika-score-title')]: 'Risika score',
  [intl.creditPolicyNew('risika-score-desc')]:
    'Fastlæg den Risika score, der minimum kræves for, at en virksomhed kan godkendes af din politik.',
  [intl.creditPolicyNew('bankruptcy-short-limit-desc')]:
    'Vælg det maksimale antal konkursforbindelser tilladt i de sidste 10 år for at virksomheden kan opfylde dine politikkrav',
  [intl.creditPolicyNew('bankruptcy-long-limit-desc')]:
    'Vælg det maksimale antal forbundne konkurser i de sidste 50 år, tilladt under din politik',
  [intl.creditPolicyNew('auditor-id-title')]: 'Revisor ID-nummer',
  [intl.creditPolicyNew('auditor-id-label')]:
    'Afvis virksomheder, hvor revisoren ikke kan give et ID-nummer',
  [intl.creditPolicyNew('distance-to-ceo-desc')]:
    "Fastlæg afstandstærsklen for CEO's nærhed til virksomhedens hovedkontor for at være berettiget til politidækning",
  [intl.creditPolicyNew('people-id-title')]: 'Specifikke mennesker',
  [intl.creditPolicyNew('people-id-desc')]: 'Afvis specifikke personer fra din politik',
  [intl.creditPolicyNew('company-id-title')]: 'Virksomheds-id',
  [intl.creditPolicyNew('company-id-desc')]:
    'Afvis specifikke virksomheder fra din politik',
  [intl.creditPolicyNew('equity-desc')]:
    'Definér det antal år, en virksomhed, som minimum skal have opretholdt positiv egenkapital, for at opfylde din kreditpolitik.',
  [intl.creditPolicyNew('company-vat-title')]: 'Momsnummer',
  [intl.creditPolicyNew('company-vat-desc')]:
    'Vælg de moms-kriterier, som en virksomhed skal opfylde for at blive godkendt i henhold til din politik',
  [intl.creditPolicyNew('company-type-placeholder')]: 'Søg efter en virksomhedstype',
  [intl.creditPolicyNew('industry-type-placeholder')]: 'Søg efter en branchetype',
  [intl.creditPolicyNew('search-company-type-placeholder')]: 'Søg efter virksomhed',
  [intl.creditPolicyNew('search-people-placeholder')]: 'Søg efter personer',

  [intl.creditPolicy('POLICY_DEBT')]: 'Gæld',
  [intl.creditPolicyNew('debt-desc')]:
    'Specificér det maksimale beløb af udestående gæld, som en virksomhed kan have for at kvalificere sig til din politik',
  [intl.creditPolicy('POLICY_SHORT_TERM_DEBT')]: 'Kortfristet gæld',
  [intl.creditPolicyNew('short-term-debt-desc')]:
    'Specificér det maksimale beløb af kortfristet gæld, som en virksomhed kan have for at kvalificere sig til din politik',
  [intl.creditPolicy('POLICY_PROFIT_LOSS')]: 'Resultat efter skat',
  [intl.creditPolicyNew('result-after-tax-desc')]:
    'Angiv det mindste niveau af efter-skattenedskæringer, der kræves for, at en virksomhed kan opfylde kriterierne for din politik',

  [intl.creditPolicy('POLICY_PAYMENT_REMARKS_COUNT')]: 'Antal betalingsanmærkninger',
  [intl.creditPolicyNew('payment-remarks-desc')]:
    'Angiv det maksimale antal aktive betalingsanmærkninger, en virksomhed kan have for at kvalificere sig til kredit',
  [intl.creditPolicy('POLICY_PAYMENT_REMARKS_TOTAL_AMOUNT')]:
    'Balance på betalingsanmærkninger',
  [intl.creditPolicyNew('amount-due-desc')]:
    'Definer den maksimale balance på aktive betalingsanmærkninger, som en virksomhed kan have for at være berettiget til kredit',
  [intl.creditPolicy('POLICY_PAYMENT_REMARKS_CREDITORS_COUNT')]:
    'Antal kreditorer knyttet til betalingsanmærkninger',
  [intl.creditPolicyNew('number-of-creditors-desc')]:
    'Angiv det maksimale antal kreditorer, en virksomhed kan have aktive betalingsanmærkninger med for at opfylde kravene til kreditværdighed',
  [intl.creditPolicy('POLICY_PAYMENT_REMARKS_MAX_REPAYMENT_TIME')]:
    'Tid til at tilbagebetale aktive betalingsanmærkninger',
  [intl.creditPolicyNew('average-repayment-duration-desc')]:
    'Fastlæg en grænse for, hvor længe betalingsanmærkninger kan forblive aktive, før en virksomhed ikke er berettiget ifølge din politik',
  [intl.creditPolicyNew('audit-level-title')]: 'Revisionsniveau',
  [intl.creditPolicyNew('audit-level-desc')]:
    'Fastlæg det krævede revisionsniveau, som en virksomhed skal opfylde for at blive godkendt til kredit',
  [intl.creditPolicyNew('reject_prepaid_phone')]:
    'Afvis virksomheder med en forudbetalt telefon',
  [intl.creditPolicyNew('reject_registered_phone')]:
    'Afvis virksomheder, der ikke har offentligt registreret telefonnummer',
  [intl.creditPolicyNew('tips_company_vat')]:
    'Kombineret med andre faktorer såsom virksomhedens alder, ledelsens anciennitet eller omsætning, kan momsregistrering være en god indikator for svindel. Medmindre undtaget, bør de fleste virksomheder være momsregistrerede. Hvis de ikke er det, kan de udgøre en risiko på grund af deres størrelse, eller det kan være en indikation af, at de skjuler dele af deres aktivitet.',
  [intl.creditPolicyNew('save-changes')]: 'Gem ændringer',
  [intl.creditPolicyNew('inactive')]: 'Inaktiv',
  [intl.creditPolicyNew('active')]: 'Aktiv',
  [intl.creditPolicyNew('policy-published')]: 'Udgivet',
  [intl.creditPolicyNew('policy-draft')]: 'Udkast',
  [intl.creditPolicyNew('company_information')]: 'Firmainformation',
  [intl.creditPolicyNew('risk_indicators')]: 'Risikoindikatorer',
  [intl.creditPolicyNew('fraud_indicators')]: 'Svindelindikatorer',
  [intl.creditPolicyNew('people_and_company_search')]:
    'Søgning efter personer og virksomheder',
  [intl.creditPolicyNew('financial_indicators')]: 'Finansielle indikatorer',
  // Translated until here

  [intl.creditPolicyNew('delete-rule-are-you-sure')]:
    'Are you sure you want to delete this rule ?',

  [intl.creditPolicyNew('delete-rule-explanation')]:
    'You are deleting a rule that forms part of' +
    ' a series of actions. By confirming, you will delete this rule and remove any subsequent' +
    ' actions it is attached to.',
  [intl.creditPolicyNew('delete-rule-do-not-show-again')]:
    "Don't show this message again",

  [intl.creditPolicyNew('company_type_node_content')]: 'Is {companyList}',

  [intl.creditPolicyNew('equity_node_content')]:
    'Equity {type, select, equity {including dividend}' +
    ' equity_without_dividend {excluding dividend} other {}} is {tolerance, select,' +
    ' greater_than {greater than} equals {equal to} less_than {less than} between {between}' +
    ' other {NONE}} {max, plural, =0 {{min} {currency}} other {{min} {currency} and {max}' +
    ' {currency}}}',

  [intl.creditPolicyNew('audit-node-text')]: 'The company has been audited',
  [intl.creditPolicyNew('already-active-dialog')]:
    'The policy "{activeName}" is already active in' +
    '{country, select,' +
    'DK {Denmark}' +
    'SE {Sweden}' +
    'NO {Norway}' +
    'other {this country}}' +
    '. It will need to be deactivated for the policy "{currentName}" to be published.',
  [intl.creditPolicyNew('status-node-text')]:
    'Is {active, select, ACTIVE {Active} other' + ' {Inactive}}',
  [intl.creditPolicyNew('industry_type_node_content')]: 'Is {industryList}',

  [intl.creditPolicyNew('prepaid-phone-node-text')]: 'Prepaid phone registered',

  // registered-prepaid-phone, no-prepaid-phone-registered

  [intl.creditPolicyNew('distance-to-ceo-node-text')]:
    'Is {tolerance, select, greater_than {greater than}' +
    ' equals' +
    ' {equal' +
    ' to}' +
    ' less_than' +
    ' {less' +
    ' than}' +
    ' between {between}' +
    ' other' +
    ' {...}}' +
    ' {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} and {max}}}',

  [intl.creditPolicyNew('number-of-employees-node-text')]:
    '{tolerance, select, greater_than {Greater than} equals {Equal to} less_than {Less than}' +
    ' between {Between}' +
    ' other' +
    ' {...}}' +
    ' {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} and {max}}}',

  [intl.creditPolicyNew('risika-score-node-text')]:
    '{tolerance, select, greater_than {Greater than} equals {Equal to} less_than {Less than}' +
    ' between {Between}' +
    ' other' +
    ' {...}' +
    ' {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} and {max}}}',

  [intl.creditPolicyNew('reject-credit-node-text')]:
    '{description, select, false {No notes available} other {{description}}}',
  [intl.creditPolicyNew('manual-check-node-text')]:
    '{description, select, false {No notes available} other {{description}}}',

  [intl.creditPolicyNew('approve-credit-max-node-text-credit-max')]: 'Kredit max:',
  [intl.creditPolicyNew('approve-credit-max-node-text-not-lower-than')]:
    'Ikke lavere end:',
  [intl.creditPolicyNew('delete-rule-are-you-sure')]: 'Er du sikker på',
  [intl.creditPolicyNew('delete-rule-explanation')]: 'Du sletter en regel',
  [intl.creditPolicyNew('delete-rule-do-not-show-again')]: 'Vis ikke denne besked igen',
  [intl.creditPolicyNew('company_type_node_content')]: 'Er {companyList}',
  [intl.creditPolicyNew('audit-node-text')]: 'Virksomheden er blevet revideret',
  [intl.creditPolicyNew('prepaid-phone-node-text')]:
    'Forudbetalt taletidskort registreret',
  [intl.creditPolicyNew('approve-credit-max-node-text-not-lower-than')]:
    'Ikke lavere end',
  [intl.creditPolicyNew('approve-credit-max-node-text-not-greater-than')]:
    'Ikke større end:',
  [intl.creditPolicyNew('approve-credit-max-node-text')]: "Brug Risika's anbefaling",
  [intl.creditPolicyNew('number-of-employees')]: 'Antal ansatte',
  [intl.creditPolicyNew('risika-score-node-title')]: 'Risika Score',
  [intl.creditPolicyNew('drag-drop-policy')]: 'Træk en fil her (JSON)',
  [intl.creditPolicyNew('cancel')]: 'Annuller',
  [intl.creditPolicyNew('get-started')]: 'Kom i gang',
  [intl.creditPolicyNew('export')]: 'Eksport',
  [intl.creditPolicyNew('duplicate')]: 'Dupliker',
  [intl.creditPolicyNew('edit')]: 'Rediger',
  [intl.creditPolicyNew('edit-policy-settings')]: 'Rediger politikindstillinger',
  [intl.creditPolicyNew('delete')]: 'Slet',
  [intl.creditPolicyNew('print')]: 'Udskriv',
  [intl.creditPolicyNew('share')]: 'Del',
  [intl.creditPolicyNew('rename')]: 'Omdøb',
  [intl.creditPolicyNew('export-success')]:
    'Kreditpolitikken {policyName} er blevet eksporteret',
  [intl.creditPolicyNew('export-error')]: 'Noget gik galt :(',
  [intl.creditPolicyNew('duplicate-success')]: 'Kreditpolitik blev duplikeret med succes',
  [intl.creditPolicyNew('duplicate-error')]: 'Noget gik galt :(',
  [intl.creditPolicyNew('delete-success')]: 'Kreditpolitik blev slettet med succes',
  [intl.creditPolicyNew('delete-error')]: 'Noget gik galt :(',
  [intl.creditPolicyNew('search-action')]: 'Søg efter en handling',
  [intl.creditPolicyNew('deactivate-and-publish')]: 'Deaktiver og offentliggør',
  [intl.creditPolicyNew('save-as-draft')]: 'Gem som kladde',
  [intl.creditPolicyNew('test')]: 'Test',
  [intl.creditPolicyNew('test-policy-title')]: 'Test',
  [intl.creditPolicyNew('test-policy-side-panel-label')]: 'Your lists',

  [intl.creditPolicyNew('select-a-list-to-test-policy-on')]:
    'Select a list to test this policy on',
  [intl.creditPolicyNew('your-policy-will-remain-unpublished')]:
    'Your policy will remain' +
    ' unpublished' +
    ' and' +
    ' you' +
    ' will' +
    ' still' +
    ' be' +
    ' able' +
    ' to make changes\n' +
    '            to it.',
  [intl.creditPolicyNew('test-and-download-button')]: 'Test and download results',
  [intl.creditPolicyNew('company_information')]: 'Virksomhedsoplysninger',
  [intl.creditPolicyNew('status')]: 'Virksomhedsstatus',
  [intl.creditPolicyNew('people_and_company_search')]: 'Persons- og virksomhedssøgning',
  [intl.creditPolicyNew('financial_indicators')]: 'Finansielle indikatorer',
  [intl.creditPolicyNew('payment_history')]: 'Betalingshistorik',
  [intl.creditPolicyNew('advanced')]: 'Avanceret',
  [intl.creditPolicyNew('outcome')]: 'Resultat',
  [intl.creditPolicyNew('company_type')]: 'Virksomhedstype',
  [intl.creditPolicyNew('new-monitoring-list-name')]: 'Navn',
  [intl.creditPolicyNew('new-monitoring-list-add')]: 'Tilføj virksomheder til din liste',
  [intl.creditPolicyNew('new-monitoring-list-label')]: 'Liste navn',
  [intl.creditPolicyNew('industry_type')]: 'Branche',
  [intl.creditPolicyNew('number_of_employees')]: 'Antal ansatte',
  [intl.creditPolicyNew('years_of_existence')]: 'År i eksistens/Virksomhedens alder',
  [intl.creditPolicyNew('ownership_structure')]: 'Ejerstruktur',
  [intl.creditPolicyNew('1')]: '1',
  [intl.creditPolicyNew('2')]: '2',
  [intl.creditPolicyNew('3')]: '3',
  [intl.creditPolicyNew('4')]: '4',
  [intl.creditPolicyNew('5')]: '5',
  [intl.creditPolicyNew('6')]: '6',
  [intl.creditPolicyNew('7')]: '7',
  [intl.creditPolicyNew('8')]: '8',
  [intl.creditPolicyNew('9')]: '9',
  [intl.creditPolicyNew('10')]: '10',
  [intl.creditPolicyNew('drag-drop-policy')]: 'Drag a file here (JSON)',
  [intl.creditPolicyNew('cancel')]: 'Cancel',
  [intl.creditPolicyNew('get-started')]: 'Get started',

  [intl.creditPolicyNew('export-success')]: 'Credit policy was successfully exported',
  [intl.creditPolicyNew('export-error')]: 'Something went wrong :(',
  [intl.creditPolicyNew('duplicate-success')]:
    'Credit policy was successfully duplicated',
  [intl.creditPolicyNew('duplicate-error')]: 'Something went wrong :(',
  [intl.creditPolicyNew('delete-success')]: 'Credit policy was successfully deleted',
  [intl.creditPolicyNew('delete-error')]: 'Something went wrong :(',
  [intl.creditPolicyNew('search-action')]: 'Search for an action',
  [intl.creditPolicyNew('deactivate-and-publish')]: 'Deactivate and publish',
  [intl.creditPolicyNew('save-as-draft')]: 'Gem som et udkast',

  // Action panel Categories
  [intl.creditPolicyNew('status')]: 'Virksomhedsstatus',
  [intl.creditPolicyNew('payment_history')]: 'Betalingshistorik',
  [intl.creditPolicyNew('advanced')]: 'Avanceret',
  [intl.creditPolicyNew('outcome')]: 'Resultat',
  // Action panel rules
  [intl.creditPolicyNew('company_type')]: 'Virksomhedstype',
  [intl.creditPolicyNew(COMPANY_LIST)]: 'Virksomhedsliste',
  [intl.creditPolicyNew(COMPANY_LIST + '-node-text')]: 'Overvågningsliste {listId}',
  [intl.creditPolicyNew(NEW_MONITORING_LIST)]: 'Opret en liste',
  [intl.creditPolicyNew('new-monitoring-list-name')]: 'Navn',
  [intl.creditPolicyNew('new-monitoring-list-add')]: 'Tilføj virksomheder til din liste',
  [intl.creditPolicyNew('new-monitoring-list-placeholder')]: 'Giv din liste et navn',
  [intl.creditPolicyNew('industry_type')]: 'Branche type',
  [intl.creditPolicyNew('number_of_employees')]: 'Aantal ansatte',
  [intl.creditPolicyNew('years_of_existence')]:
    'Antal år virksomheden har eksisteret/virksomhedens alder',
  [intl.creditPolicyNew('ownership_structure')]: 'Ejerskabsstruktur',
  [intl.creditPolicyNew('policy-card-last-updated')]:
    'af {ownerId} sidst opdateret den: {date}',
  [intl.creditPolicyNew('risika_score')]: 'Risika score',
  [intl.creditPolicyNew('only-one-active-policy-title')]:
    'Du kan kun have én aktiv politik pr. land',
  [intl.creditPolicyNew('connected_bankruptcies')]: 'Tilknyttede konkursbegæringer',
  [intl.creditPolicyNew('distance_to_ceo')]: 'Afstand til direktør',
  [intl.creditPolicyNew('prepaid_phone')]: 'Forudbetalt taletidskort',
  [intl.creditPolicyNew('people_search')]: 'Personsøgning',
  [intl.creditPolicyNew('company_search')]: 'Virksomhedssøgning',
  [intl.creditPolicyNew('revenue')]: 'Indtægter',
  [intl.creditPolicyNew('debt')]: 'Gæld',
  [intl.creditPolicyNew('relative_debt_change')]: 'Relativ gældsændring',
  [intl.creditPolicyNew('connected_bankruptcies')]: 'Tilknyttede konkurser',

  [intl.creditPolicyNew('distance_to_ceo')]: 'Afstand til CEO',
  [intl.creditPolicyNew('prepaid_phone')]: 'Forudbetalt telefon',

  [intl.creditPolicyNew('people_search')]: 'People search',
  [intl.creditPolicyNew('company_search')]: 'Company search',

  [intl.creditPolicyNew('revenue')]: 'Revenue',
  [intl.creditPolicyNew('debt')]: 'Debt',
  [intl.creditPolicyNew('relative_debt_change')]: 'Relative debt change',
  [intl.creditPolicyNew('ebitda')]: 'EBITDA',
  [intl.creditPolicyNew('ebit')]: 'EBIT',
  [intl.creditPolicyNew('short_term_tax_payable')]: 'Kortfristet skattepligt',
  [intl.creditPolicyNew('payment_remarks')]: 'Betalingsbemærkninger',
  [intl.creditPolicyNew('invoice_data')]: 'Fakturadata',
  [intl.creditPolicyNew('debt_to_equity_ratio')]: 'Gælds-til-egenkapitalforhold',
  [intl.creditPolicyNew('solidity_ratio')]: 'Soliditetsgrad',
  [intl.creditPolicyNew('return_on_assets')]: 'Aktivafkast (ROA)',
  [intl.creditPolicyNew('return_on_equity')]: 'Egenkapitalafkast',
  [intl.creditPolicyNew('asset_turnover')]: 'Aktivomsætning',
  [intl.creditPolicyNew('approve_credit')]: 'Godkend kredit',
  [intl.creditPolicyNew('manual_check')]: 'Manuel kontrol',
  [intl.creditPolicyNew('reject_credit')]: 'Afvis kredit',

  [intl.creditPolicyNew('publish')]: 'Gem og offentliggør',
  [intl.creditPolicyNew('edit-an-active-policy-edit-a-copy')]: 'Edit a copy',
  [intl.creditPolicyNew('edit-an-active-policy-edit-this-version')]: 'Edit this version',

  [intl.creditPolicyNew('error-loading-policies')]:
    'Sorry we can’t load your policies at the' + ' moment, please try again later.',
  [intl.creditPolicyNew('error-loading-policy')]:
    'Sorry we can’t load your policy at the moment, please try again later.',
  [intl.creditPolicyNew('error-loading-ecp-builder-actions')]:
    "Sorry we can't load this panel at the moment, please try again later.",

  [intl.creditPolicyNew('edit-an-active-policy-body')]:
    'You are trying to edit an active policy, which could impact the performance of your policy. {lineBreak} {lineBreak} For this reason, we will temporarily deactivate your policy whilst you’re making changes. If you would like your policy to remain active, edit a copy instead.',

  [intl.creditPolicyNew('save-incomplete-policy-body')]:
    "We've noticed some branches in your policy appear to be empty. In order for Risika to apply your credit policy accurately, it's important to ensure that each branch is set with an outcome. This will help to make sure that your policy addresses every possible scenario.",

  [intl.creditPolicyNew('save-incomplete-policy-title')]:
    'Make sure your policy is complete before publishing it',

  [intl.creditPolicyNew('pro_tips_title')]: 'Gode råd',
  [intl.creditPolicyNew('tips_equity')]:
    'virksomheden har nok aktiver til at dække sine forpligtelser. Hvis negativ',
  [intl.creditPolicyNew('dropdown_multi_select')]: '{companyList}',
  [intl.creditPolicyNew('tips_company_type')]:
    'Brug virksomhedstypen til at anvende dine kreditpolitikregler på specifikke typer af virksomheder på forskellige måder. For eksempel kan virksomhedstyper',
  [intl.creditPolicyNew('tips_prepaid_phone')]:
    'Forudbetalte taletidskort kan være en indikator for',
  [intl.creditPolicyNew('dk_tips_distance_to_ceo')]:
    'I Danmark, hvor den administrerende direktør bor mere end 25 km væk fra virksomhedens hovedkontor, har en gennemsnitlig konkursrate på 2,83%.',

  [intl.creditPolicyNew('tips_number_of_employees')]:
    'Brug antallet af medarbejdere kan give en indikation af en virksomheds størrelse og økonomiske sundhed - derfor dens potentielle modstandsdygtighed over for økonomiske kriser.',
  [intl.creditPolicyNew('tips_audit')]: 'Brug revision til at kontrollere',
  [intl.creditPolicyNew('tips_industry_type')]:
    'Brug branche type til at anvende dine kreditpolitikregler på virksomheder i forskellige brancher. For eksempel kan virksomhedstyper i fremstilling kræve forskellige kreditbetingelser end virksomheder inden for finansielle tjenester.',
  [intl.creditPolicyNew('tips_status')]: 'Inaktive virksomheder er virksomheder',

  [intl.creditPolicyNew('tips_status')]:
    'Inactive companies are companies that have been ceased, dissolved, are under bankruptcy or' +
    ' under enforced closure... It is recommended to exclude such companies from your policy to ensure you don’t grant them credit',
  [intl.creditPolicyNew('tips_' + COMPANY_LIST)]:
    'Company list is a list of companies that are connected to the' +
    ' company in question',
  [intl.creditPolicyNew('tips_payment_remarks')]:
    'Vores data dækker de seneste fire år og sporer udelukkende aktive betalingsanmærkninger. Dette betyder, at betalingsanmærkninger tæller som sådan, indtil det fulde skyldige beløb er blevet betalt. Vi indsamler betalingsanmærkninger fra Intrum, Visma og Kredinor. Du kan finde mere information om dette i vores privatlivspolitik.',

  [intl.creditPolicyNew('tips_company_list')]:
    'Brug virksomhedslisten til at oprette regler baseret på lister over virksomheder',
  [intl.creditPolicyNew('tips_approve_credit')]:
    'based on the rules that you have setup until this point in your policy. Use either Risikas recommendation',
  [intl.creditPolicyNew('tips_manual_check')]:
    'Manuel kontrol vil blive anvendt på kunder som et resultat af de regler',
  [intl.creditPolicyNew('tips_reject_credit')]: 'Afvist kredit er en kredit',
  [intl.creditPolicyNew('something-went-wrong-credit-policy')]: 'Noget gik galt',
  [intl.creditPolicyNew('back-to-credit-policy')]: 'Tilbage til kreditpolitik',
  [intl.creditPolicyNew('invalid-country-message')]: 'Du skal angive et gyldigt land',
  [intl.creditPolicyNew('invalid-country-message')]:
    'Vi har ikke kunnet gemme din politik. Prøv igen.',
  [intl.creditPolicyNew('we-where-not-able-to-save-your-policy')]: 'Gem og aktiver',
  [intl.creditPolicyNew('save-and-activate')]: 'Begynd at oprette din første regel',
  [intl.creditPolicyNew('create-you-first-rule-button')]:
    'Begynd at oprette din første regel',
  [intl.creditPolicyNew('description-text')]: 'Beskrivelse (valgfri)',
  [intl.creditPolicyNew('description-only')]:
    'Afvis altid inaktive virksomheder og konkurser',
  [intl.creditPolicyNew('always-reject-inactive-companies')]: 'Kom i gang',
  [intl.creditPolicyNew('get-started-text')]: 'Rediger politikindstillinger',
  [intl.creditPolicyNew('edit-policy-settings')]: 'Rediger politikindstillinger',
  [intl.creditPolicyNew('active-companies-only')]: 'Kun aktive virksomheder',
  [intl.creditPolicyNew('equity_node_content')]: 'select',
  [intl.creditPolicyNew('already-active-dialog')]:
    'The policy "{activeName}" is already active in ' +
    '{country, select,' +
    'DK {Denmark}' +
    'SE {Sweden}' +
    'NO {Norway}' +
    'other {this country}}' +
    '. In order to publish "{currentName}", the existing policy will need to be deactivated' +
    ' temporarily.',
  [intl.creditPolicyNew('status-node-text')]: 'select',
  [intl.creditPolicyNew('industry_type_node_content')]: '',

  [intl.creditPolicyNew('distance-to-ceo-node-text')]:
    'Er {tolerance, select, greater_than {greater than}' +
    ' er lig med' +
    ' {equal' +
    ' to}' +
    +' less_than' +
    ' {less' +
    ' than}' +
    +' between {between}' +
    ' other' +
    ' {...}}' +
    ' {max, plural, =0 {{min}}} =1 {{min} og {max}}} other {{min} og {max}}}',

  [intl.creditPolicyNew('number-of-employees-node-text')]: '{tolerance',
  [intl.creditPolicyNew('risika-score-node-text')]: '{tolerance',
  [intl.creditPolicyNew('reject-credit-node-text')]:
    '{description, select, false {Ingen noter tilgængelige} other {{description}}}',
  [intl.creditPolicyNew('manual-check-node-text')]: '{description',
  [intl.creditPolicyNew('adjust-credit-max-node-text')]:
    "{creditMax} {percentage}% af Risika's" + ' anbefaling {lineBreak}',
  [intl.creditPolicyNew('adjust-credit-max-node-text-currency')]:
    '  {limit} {amount} {currency}',
  [intl.creditPolicyNew('approve-with-value-node-text')]:
    'Approve with value {value} {currency}',
  [intl.creditPolicyNew('COMPANY_LIST')]: 'Virksomhedsliste',
  [intl.creditPolicyNew('COMPANY_LIST + -node-text')]: 'Overvågningsliste {listId}',
  [intl.creditPolicyNew('NEW_MONITORING_LIST')]: 'Opret en liste',
  [intl.creditPolicyNew('tips_ + COMPANY_LIST')]: '',
  [intl.creditPolicyNew('tips_risika_score')]: '',
  [intl.creditPolicyNew('\n +')]: '',
  [intl.creditPolicyNew('For companies that have a low level of financial data')]:
    'Sole Traders typically lack broad financial data',
  [intl.creditPolicyNew('\n +')]: '',
  [intl.creditPolicyNew('For companies that have insufficient financial data')]:
    'and so no score or assessment is provided. For example',
  [intl.creditPolicyNew('delete-rule')]: 'Slet en regel',
  [intl.creditPolicyNew('delete-this-rule')]: 'Slet denne regel',
  [intl.creditPolicyNew('greater_than')]: 'Større end',
  [intl.creditPolicyNew('prepaid-phone-status')]: 'Taletidskortstatus',
  [intl.creditPolicyNew('registered-prepaid-phone')]: 'Registreret taletidskort',
  [intl.creditPolicyNew('no-prepaid-phone-registered')]: 'Ingen taletidskort registreret',
  [intl.creditPolicyNew('no-data-yet')]: 'Stadig ingen data...',
  [intl.creditPolicyNew('distance-to-ceo-node-title')]: 'Afstand til direktør',
  [intl.creditPolicyNew('equity-node-title')]: 'Egenkapital',
  [intl.creditPolicyNew('prepaid-phone-node-title')]: 'Taletidskort',
  [intl.creditPolicyNew('something-wrong-with-node-data')]:
    'Ingen eller beskadigede data',
  [intl.creditPolicyNew('no-companies-in-list')]: 'Ingen virksomheder i listen',
  [intl.creditPolicyNew('action-panel-cancel-button')]: 'Annuller',
  [intl.creditPolicyNew('action-panel-confirm-button')]: 'Bekræft',
  [intl.creditPolicyNew('manual-check-description-label')]:
    'Tilføj nogle noter (valgfrit)',
  [intl.creditPolicyNew('reject-credit-description-label')]:
    'Tilføj nogle noter (valgfrit)',
  [intl.creditPolicyNew('company-list-description-label')]: 'Vælg en liste',
  [intl.creditPolicyNew('company-list-create-new')]: 'Opret ny liste',
  [intl.creditPolicyNew('search-multi-select-apply')]: 'Anvend',
  [intl.creditPolicyNew('search-multi-select-clear-all')]: 'Ryd alle',
  // ---------------
  [intl.creditPolicyNew('choose-file')]: 'Vælg fil',
  [intl.creditPolicyNew('tips_status')]:
    'Inaktive virksomheder er virksomheder, der er ophørt, opløst, er under konkurs eller under tvangslukning... Det anbefales at udelukke sådanne virksomheder fra din police for at sikre, at du ikke giver dem kredit',
  [intl.creditPolicyNew('select-action')]: 'Vælg handling',
  [intl.creditPolicyNew('search-for-an-action')]: 'Søg efter en handling',
  [intl.creditPolicyNew('are-you-sure-you-want-to-delete-this-policy')]:
    'Er du sikker på, at du ønsker at slette politikken "{name}"?',
  [intl.creditPolicyNew('delete-policy-description')]:
    'De regler, der er fastsat i denne politik, vil blive fjernet permanent, og du vil ikke længere modtage advarsler for de virksomheder, som den gælder for.',
  [intl.creditPolicyNew('delete-policy-action')]: 'Skriv <b>{name}</b> for at bekræfte:',
  [intl.creditPolicyNew('created-on')]: 'Oprettet den',
  [intl.creditPolicyNew('last-updated')]: 'Sidst opdateret',
  [intl.creditPolicyNew(TOLERANCE_INPUT)]:
    '{tolerance}' +
    ' {max, plural, =0 {{min}} =1 {{min} og {max}} other {{min} og {max}}}',
  [intl.creditPolicyNew('industry_code_node_content')]: 'Er {industryList}',

  [intl.creditPolicyNew('connected-bankruptcies-node-content')]:
    'I den sidste ' +
    '{category, plural, =0 {10} other {50}} år er ' +
    '{tolerance}' +
    ' {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} og {max}}}',

  [intl.creditPolicyNew('equity-node-content')]:
    'Egenkapital ' +
    '{category, plural, =0 {herunder} other {undtagen}} udbytte er ' +
    '{tolerance}' +
    ' {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} og {max}}}',

  [intl.creditPolicyNew('invalid-country-message')]:
    'You need to provide a valid country',
  [intl.creditPolicyNew('we-where-not-able-to-save-your-policy')]:
    'We haven’t been able to save your policy.',
  [intl.creditPolicyNew('save-and-activate')]: 'Save and Activate',
  [intl.creditPolicyNew('always-reject-inactive-companies')]: 'Reject Inactive companies',
  [intl.creditPolicyNew('get-started-text')]: 'Get Started',
  [intl.creditPolicyNew('active-companies-only')]: 'Active companies only',
  [intl.creditPolicyNew('save_modal_title')]: 'Gem ændringer i din politik',
  [intl.creditPolicyNew('save_modal_text')]:
    'Det ser ud til, at du har foretaget nogle ændringer i din kreditpolitik. Er du sikker på, at du vil forlade siden uden at gemme?',
  [intl.creditPolicyNew('activation_modal_title')]: 'Aktivér en politik',
  [intl.creditPolicyNew('activation_modal_text')]:
    'Du er ved at aktivere denne politik. Reglerne i politikken vil nu informere om kreditanbefalinger, og denne ændring vil påvirke hele din organisation. Er du sikker på, at du vil fortsætte?',
  [intl.creditPolicyNew('deactivation_modal_title')]: 'Deaktivér en politik',

  [intl.generic('active-companies')]: 'Aktive virksomheder',

  [intl.creditPolicyNew('deactivation_modal_text')]:
    'Du er ved at deaktivere denne politik. Reglerne i din politik vil ikke længere gælde, og denne ændring vil påvirke hele din organisation. Er du sikker på, at du vil fortsætte?',
  [intl.creditPolicyNew('node-between-2-nodes-text-1')]:
    'Du tilføjer en handling i midten af en eksisterende serie af handlinger.',
  [intl.creditPolicyNew('node-between-2-nodes-text-2')]:
    'Vælg den gren, som resten af handlingerne skal fortsætte på',
  [intl.creditPolicyNew('node-between-2-nodes-option-yes')]: '"Ja"-gren',
  [intl.creditPolicyNew('node-between-2-nodes-option-no')]: '"Nej"-gren',
  [intl.creditPolicyNew('upgrade-advanced-fraud-indicators')]:
    'Opgrader for at låse op for avancerede svindelindikatorer',
  [intl.creditPolicyNew('additional-fraud-variables')]:
    'Yderligere 12 svindelindikatorvariabler',
  [intl.creditPolicyNew('limit-fraud-risk')]: 'Begræns svindelrisikoen i din virksomhed',
  [intl.creditPolicyNew('monitor-fraud-indicators')]:
    'Overvåg svindelindikatorer på tværs af dine kunder',
  [intl.creditPolicyNew('talk-to-sales-rep')]:
    'Tal med en salgsperson for at diskutere dine behov og finde den bedste løsning for din virksomhed',
  [intl.creditPolicyNew('request-call-back')]: 'Anmod om opkald',
  [intl.creditPolicyNew('fraud-indicators-promotion')]:
    'Lås op for avancerede svindelindikatorer',
  [intl.creditPolicyNew('unsaved-changes')]: 'Du har ugemte ændringer',
  [intl.creditPolicyNew('discard-changes')]: 'Kassér ændringer',
  [intl.creditPolicyNew('cancel')]: 'Annuller',
  [intl.creditPolicyNew('undo')]: 'Fortryd',

  [intl.customerManagement('add-new-users')]: 'Add New Users',
  [intl.customerManagement('are-you-sure-dialog')]:
    'Are you sure you want to revoke access for this company?',
  [intl.customerManagement('customer-plan')]: 'Customer Plan',
  [intl.customerManagement('customer-plan-basic')]: 'Basic',
  [intl.customerManagement('customer-plan-enterprise')]: 'Enterprise',
  [intl.customerManagement('customer-plan-freemium')]: 'Freemium',
  [intl.customerManagement('customer-plan-pro')]: 'Pro',
  [intl.customerManagement('customer-plan-standard')]: 'Standard',
  [intl.customerManagement('customer-plan-trial')]: 'Trial',
  [intl.customerManagement('customer-type')]: 'Customer Type',
  [intl.customerManagement('customer-type-customer')]: 'Customer',
  [intl.customerManagement('customer-type-lost-customer')]: 'Lost Customer',
  [intl.customerManagement('customer-type-lost-trial')]: 'Lost Trial',
  [intl.customerManagement('customer-type-risika')]: 'Risika (internal)',
  [intl.customerManagement('customer-type-trial')]: 'Trial',
  [intl.customerManagement('customer-updated')]: 'Customer updated',
  [intl.customerManagement('existing-customers')]: 'Existing Customers',
  [intl.customerManagement('existing-users')]: 'Existing Users',
  [intl.customerManagement('expiry-date')]: 'Expiry Date',
  [intl.customerManagement('handle-access')]: 'Revoke access',
  [intl.customerManagement('manage-customer')]: 'Manage Customer',
  [intl.customerManagement('my-customers')]: 'My Customers',
  [intl.customerManagement('new-customer')]: 'New Customer',
  [intl.customerManagement('new-customer-title')]: 'Create New Customer',
  [intl.customerManagement('responsible-person')]: 'Set responsible person',
  [intl.customerManagement('revoke-access')]: 'Revoke Access',
  [intl.customerManagement('title')]: 'Customer Management',
  [intl.customerManagement('users')]: 'Users',
  [intl.developer('confirm-remove-webhook-description')]:
    'OBS: hvis du fjerner webhooken og beslutter dig for at oprette en ny, så vil der blive genereret en ny secret.',
  [intl.developer('confirm-remove-webhook-title')]:
    'Er du sikker på du vil fjerne jeres webhook?',
  [intl.developer('documentation')]: 'dokumentation',
  [intl.developer('title')]: 'Udvikler',
  [intl.developer('webhook-add-info')]:
    'For mere information i forhold til anvendelse af webhooks, venligst tjek vores',
  [intl.developer('webhook-existing-info')]:
    'Din webhook er opsat. Du kan læse mere om, hvordan du anvender dem fra vores',
  [intl.developer('webhook-secret')]: 'Webhook Secret',
  [intl.developer('webhook-title')]: 'Webhook',
  [intl.developer('webhook-url')]: 'Webhook URL',
  [intl.developer('webhook-url-remove-button')]: 'Fjern',
  [intl.developer('webhook-url-save-button')]: 'Tilføj',
  [intl.developer('webhook-url-test-button')]: 'Test',
  [intl.enforcedPasswordChange('confirm-password-label')]: 'Bekræft dit password',
  [intl.enforcedPasswordChange('description')]:
    'Af sikkerhedsmæssige grunde er du nødt til at skifte password.',
  [intl.enforcedPasswordChange('min-characters')]: 'Mindst 8 tegn',
  [intl.enforcedPasswordChange('must-match-password')]:
    'Skal være det samme som password',
  [intl.enforcedPasswordChange('password-change-success')]:
    'Perfekt! Du kan nu fortsætte med at bruge Risika',
  [intl.enforcedPasswordChange('password-label')]: 'Password',
  [intl.enforcedPasswordChange('submit')]: 'Skift password',
  [intl.enforcedPasswordChange('title')]: 'Ændring af password påkrævet',
  [intl.exportButton('description')]:
    '{count, plural, =0 {Intet at eksportere} one {Eksportering på {count} række} other {Eksportering på {count} rækker}}',
  [intl.exportButton('max-export')]:
    '{count, plural, other {Begrænset til de første {count}}}',
  [intl.exportButton('max-out-of')]: '{count, plural, other {ud af {count} rækker}}',
  [intl.exportButton('text')]: 'Eksporter',
  [intl.faq('number_of_employees')]: 'Antal ansatte',
  [intl.faq('Audit')]: 'Revision',
  [intl.faq('Company size')]: 'Virksomhedsstørelse',
  [intl.faq('Connected bankruptcies')]: 'Relaterede konkurser',
  [intl.faq('Credit days')]: 'Kredit dage',
  [intl.faq('Credit maximum')]: 'Kreditmaks',
  [intl.faq('Distress percentage year')]: 'Konkursprocenter 2020',
  [intl.faq('Effect')]: 'Effekt',
  [intl.faq('Erklæringstype')]: 'Erklæringstype',
  [intl.faq('Explanation')]: 'Forklaring',
  [intl.faq('Financial key figures')]: 'Finansielle nøgletal',
  [intl.faq('Fractile')]: 'Fraktil',
  [intl.faq('Fraktil')]: 'Fraktil',
  [intl.faq('How')]: 'Hvordan',
  [intl.faq(
    'I 2019 gik 2% af danske virksomheder under konkurs eller under tvangsopløsning.'
  )]: '',
  [intl.faq('Key figure performance')]: 'Nøgletal',
  [intl.faq('Level of audit')]: 'Erklæringstype',
  [intl.faq('Maximum')]: 'Maksimum',
  [intl.faq('Number of employees')]: 'Antal ansatte',
  [intl.faq('Other Ratios')]: 'Øvrige nøgletal',
  [intl.faq('Ownership')]: 'Ejerskab',
  [intl.faq('Performance')]: 'Performance',
  [intl.faq('Reason')]: 'Årsag',
  [intl.faq('Risika score')]: 'Risika score',
  [intl.faq('Risk')]: 'Risiko',
  [intl.faq('Sole proprietorship model')]: 'Enkeltmandsmodel',
  [intl.faq('UC: 77.93%, Gini: 55.86%')]: 'UC: 77.93%, Gini: 55.86%',
  [intl.faq('Understanding risika score and figures')]: 'Forstå risika score og nøgletal',
  [intl.faq('Unscored companies')]: 'Virksomheder uden score',
  [intl.faq('company_size')]: 'Virksomhedsstørelse',
  [intl.faq('What')]: 'Hvad',
  [intl.faq('Why')]: 'Hvorfor',
  [intl.faq(
    'With decision threshold on 5 the metrics is sensitivity: 81.01% and precision: 4.8%'
  )]:
    'With decision threshold on 5 the metrics is sensitivity: 81.01% and precision: 4.8%',
  [intl.faq('definition')]: 'Definition',
  [intl.faq('variable')]: 'Nøgletal',
  // english only section
  [intl.faq('find-answers')]: 'Find an answer quickly',
  [intl.faq('add-company-monitoring-list')]: 'Add company to monitoring list',
  [intl.faq('set-up-monitoring-emails')]: 'Set up monitoring emails',
  [intl.faq('adjust-credit-recommendation')]: 'Adjust the credit recommendation',
  [intl.faq('view-companies-subsidiaries')]: 'View a companies subsidiaries',
  [intl.faq('chat-cta')]: 'Chat with us',
  [intl.faq('welcome')]: 'Hi {name},',
  [intl.faq('response-time')]: 'We usually respond within 10 min',
  [intl.faq('how-can-we-help')]: 'How can we help?',
  [intl.faq('prompt-desc')]: 'Let us know if you have any questions, were here to help',
  //= ====================
  [intl.financial('probability-of-closure')]: 'Sandsynlighed for lukning',
  [intl.financial('probability-of-distress')]: 'Konkurssandsynlighed',
  [intl.financial('distress')]: 'Konkurssandsynlighed',
  [intl.financialRatio('no-content')]: 'Intet indhold tilgængeligt',
  [intl.financialRatio('performance')]: 'Performance',
  [intl.firstTimeVisit('confirm-password-label')]: 'Bekræft dit password',
  [intl.firstTimeVisit('description')]:
    'Vælg sprog! Du skal blot vælge dit nye password til platformen og så er du i gang.',
  [intl.firstTimeVisit('min-characters')]: 'Mindst 8 tegn',
  [intl.firstTimeVisit('must-match-password')]: 'Skal være det samme som password',
  [intl.firstTimeVisit('password-label')]: 'Password',
  [intl.firstTimeVisit('submit')]: 'Kom i gang',
  [intl.firstTimeVisit('title')]: 'Vælg dit password',
  [intl.firstTimeVisit('preferences')]: 'Præferenser',

  [intl.generic('view-details')]: 'Se detaljer',
  [intl.generic('hide-details')]: 'Skjul detaljer',
  [intl.generic('owners')]: 'ejere',
  [intl.generic('loading')]: 'Indlæser',
  [intl.generic('print')]: 'Print',
  [intl.generic('actions')]: 'Handlinger',
  [intl.generic('active')]: 'Aktiv',
  [intl.generic('add')]: 'Tilføj',
  [intl.generic('clear-selection')]: 'Ryd',
  [intl.generic('advanced-views-not-allowed')]:
    'Avancerede visninger er ikke tilgængelige på din nuværende plan. Klik her for at se mere!',
  [intl.generic('all')]: 'Alle',
  [intl.generic('back')]: 'Tilbage',
  [intl.generic('cancel')]: 'Annuller',
  [intl.generic('checks-left')]: 'kredittjek tilbage',
  [intl.generic('clear')]: 'Fjern',
  [intl.generic('companies')]: 'Virksomheder',
  [intl.creditPolicyNew('companies')]: 'Virksomheder',
  [intl.generic('company')]: 'Virksomhed',
  [intl.generic('confirm-password')]: 'Bekræft password',
  [intl.generic('create')]: 'Opret',
  [intl.generic('cvr-number')]: 'CVR-nummer',
  [intl.generic('denmark')]: 'Danmark',
  [intl.generic('do-not-want-to-wait')]:
    'Vil du ikke vente? Opdater dit abonnement nedenfor!',
  [intl.generic('edit')]: 'Rediger',
  [intl.generic('emails')]: 'E-mails',
  [intl.home('no-searches-message')]:
    'Har du ikke søgt efter noget endnu? Start med at klikke på søgefeltet ovenfor.',
  [intl.generic('employees')]: 'ansatte',
  [intl.generic('error-reach-us-on-chat')]:
    'Der opstod en fejl! Venlist kontakt os på chatten',
  [intl.generic('failed-to-fetch-list')]:
    'Der gik noget galt med at få data til "{name}"!',
  [intl.home('monitor-recent-changes')]: 'Senest vist',
  [intl.home('recently-viewed')]: 'Senest vist',
  [intl.generic('feature-not-available')]:
    'Denne funktion er ikke tilgængelig i dit abonnement.',
  [intl.generic('first-name')]: 'Fornavn',
  [intl.generic('first-page')]: 'Første Side',
  [intl.generic('fte')]: 'årsværk',
  [intl.generic('fraud-indicators')]: 'Risikoindikatorer',
  [intl.generic('fraud-indicators-no-risk-detected')]: 'Ingen risiko opdaget',
  [intl.generic('fraud-indicators-risk-detected')]: 'Risiko opdaget',
  [intl.generic('get-auditor-information')]: 'Se revisorinformation',
  [intl.generic('get-credit-information')]: 'Se kreditoplysninger',
  [intl.generic('get-highlight-information')]: 'Se opmærksomhedspunkter',
  [intl.generic('import')]: 'Importér',
  [intl.generic('inactive')]: 'Inaktiv',
  [intl.home('recent-searches-content')]:
    'Her kan du få hurtig adgang til de virksomheder og personer, du har søgt efter for nylig.',
  [intl.generic('language')]: 'Sprog',
  [intl.generic('last-name')]: 'Efternavn',
  [intl.generic('last-page')]: 'Sidste Side',

  [intl.home('searches-this-month')]: 'Dine søgninger denne måned',

  [intl.generic('learn-more')]: 'Lær mere',
  [intl.generic('missing-address')]: 'Adresse mangler',
  [intl.generic('monitor')]: 'Overvåg',
  [intl.generic('monitor-stop')]: 'Stop overvågning',
  [intl.generic('more-checks-on-monday')]: 'Du får {credit_rating_cap} på mandag',
  [intl.generic('name')]: 'Navn',
  [intl.generic('never')]: 'Ikke tilgængeligt',
  [intl.generic('next-page')]: 'Næste Side',
  [intl.generic('no')]: 'Nej',
  [intl.generic('no-credit-information')]:
    'Ingen tilgængelige kreditoplysninger for dette firma!',
  [intl.generic('no-data')]: 'Intet data',
  [intl.generic('norway')]: 'Norge',
  [intl.generic('number-of-companies')]: 'Antal virksomheder',
  [intl.generic('active-companies')]: 'Aktive virksomheder',
  [intl.generic('optional')]: 'valgfrit',
  [intl.generic('password')]: 'Password',
  [intl.generic('phone')]: 'Telefon',
  [intl.generic('previous-page')]: 'Forrige Side',
  [intl.generic('remove')]: 'Fjern',
  [intl.generic('restore-default')]: 'Gendan standard',
  [intl.generic('roles')]: 'Roller',
  [intl.generic('rows')]: 'Rækker',
  [intl.generic('save')]: 'Gem',
  [intl.generic('selected')]: 'Valgt',
  [intl.generic('send')]: 'Send',
  [intl.generic('show-more')]: 'Vis mere',
  [intl.generic('stop')]: 'Stop',
  [intl.generic('sweden')]: 'Sverige',
  [intl.generic('total')]: 'Total',
  [intl.generic('update')]: 'Opdater',
  [intl.generic('yes')]: 'Ja',
  [intl.generic('you-have')]: 'Du har',
  [intl.generic('open-all')]: 'Åben alle',
  [intl.generic('close-all')]: 'Luk alle',
  [intl.generic('more-details')]: 'Flere detaljer',
  [intl.generic('less-details')]: 'Færre detaljer',
  [intl.generic('days')]: 'dage',
  [intl.generic('unknown')]: 'Ukendt',
  [intl.generic('close')]: 'Luk',
  [intl.generic('company-id')]: 'Virksomheds ID',
  [intl.generic('company-name')]: 'Virksomheds Navn',
  [intl.generic('company-type')]: 'Virksomheds Type',
  [intl.generic('date-of-incorporation')]: 'Stiftelsesdato',
  [intl.generic('email')]: 'Email',
  [intl.generic('status')]: 'Status',
  [intl.generic('risika-score')]: 'Risika score',
  [intl.generic('credit-max')]: 'Kreditmaksimum',
  [intl.generic('risika-recommendation')]: 'Risika-anbefaling',
  [intl.generic('policy-outcome')]: 'Politisk resultat',
  [intl.generic('policy-credit-limit')]: 'Politisk kreditgrænse',
  [intl.generic('street')]: 'Gade',
  [intl.generic('street-number')]: 'Gadenummer',
  [intl.generic('coname')]: 'Coname',
  [intl.generic('zip-code')]: 'Postnummer',
  [intl.generic('city')]: 'By',
  [intl.generic('municipality')]: 'Kommune',
  [intl.generic('postal-district')]: 'Postdistrikt',
  [intl.generic('country')]: 'Land',
  [intl.generic('number-of-employees')]: 'Antal ansatte',
  [intl.generic('industry-code')]: 'Branchekoder',
  [intl.generic('industry-description')]: 'Branchebeskrivelse',
  [intl.generic('internal-id')]: 'Internt id',
  [intl.generic('currency')]: 'Valuta',
  [intl.generic('equity')]: 'Egenkapital',
  [intl.generic('profit-loss')]: 'Årets resultat',
  [intl.generic('solidity')]: 'Soliditetsgrad',
  [intl.generic('liquidity')]: 'Likviditetsgrad',
  [intl.generic('local-id')]: 'Virksomheds ID',
  [intl.generic('credit-score')]: 'Kreditscore',
  [intl.generic('English')]: 'Engelsk',
  [intl.generic('Danish')]: 'Dansk',
  [intl.generic('Norwegian')]: 'Norsk',
  [intl.generic('swedish')]: 'Svensk',
  [intl.generic('DENMARK')]: 'DANMARK',
  [intl.generic('SWEDEN')]: 'SVERIGE',
  [intl.generic('NORWAY')]: 'NORGE',
  [intl.country('fi')]: 'Finland',
  [intl.creditPolicy('fi')]: 'Finland',
  [intl.generic('finland')]: 'Finland',
  [intl.generic('FINLAND')]: 'FINLAND',
  [intl.riskMonitoringOverview('fi_FI')]: 'Finland',
  [intl.generic('save_changes')]: 'Gem ændringer',
  [intl.generic('discard')]: 'Forkast',
  [intl.generic('successful_changes')]: 'Dine ændringer er blevet gemt med succes',
  [intl.generic('continue')]: 'Fortsæt',
  [intl.generic('cancel')]: 'Annullér',
  [intl.generic('view-less')]: 'Vis færre',
  [intl.generic('view-all')]: 'Vis alle',
  [intl.generic('x-items-selected')]:
    '{count, plural, =0 {} one {{count} emne valgt} other {{count} emner valgt}}',

  [intl.grading('average')]: 'Middel',
  [intl.grading('not-available')]: 'n/a',
  [intl.grading('strong')]: 'Stærk',
  [intl.grading('very-strong')]: 'Meget stærk',
  [intl.grading('very-weak')]: 'Meget svag',
  [intl.grading('weak')]: 'Svag',
  [intl.history('advancedfilters')]: 'Filtrering',
  [intl.history('companyhierarchy')]: 'Koncernhierarki',
  [intl.history('creditpolicy')]: 'Kreditpolitik',
  [intl.history('home')]: 'Forside',
  [intl.history('monitoring')]: 'Overvågning',
  [intl.history('company-comparison')]: 'Sammenlign',
  [intl.history('advance-credit-policy')]: 'Advance Credit Policy',
  [intl.history('observational-lists')]: 'Risk Insights',
  [intl.home('faq-score-calculation-description')]:
    'Vi udregner Risikascoren ved at anvende en scorecard-model, som inddeler alle virksomheder i risikogrupperne 1 - 10 baseret på en branchejusterede finansielle nøgletal, virksomhedens alder, erklæringstype, relaterede konkurser, tvangsopløsninger og industrien virksomheden befinder sig i. Af regnskabstal og andre variable, der indgår i modellen, er årets resultat, EBITDA, EBIT, finansielle udgifter, omsætningsaktiver, balance, gæld, egenkapital, valuta, virksomhedsform, stiftelsesdato, erklæringstype og industri.',
  [intl.home('faq-score-calculation-title')]: 'Hvordan er Risika scoren beregnet?',
  [intl.home('faq-title')]: 'Ofte stillede spørgsmål (FAQ)',
  [intl.home('integrations-description')]:
    'Få indsigt i, hvordan du kan automatisere kreditprocessen i din virksomhed ved at bruge en integration til dine eksisterende CRM og ERP systemer.',
  [intl.home('integrations-title')]:
    'Integrér Risika direkte ind i dine eksisterende værktøjer',
  [intl.home('monitor-list-label')]: 'Dine overvågningslister',
  [intl.home('monitor-overview-title')]: 'Dine overvågede virksomheder',
  [intl.home('monitor-recent-changes')]: 'Seneste ændringer',
  [intl.home('no-changes')]: 'Ingen ændringer',
  [intl.home('sales-and-marketing-description')]:
    'Opsæt din egen interne og branchespecifikke kreditpolitik direkte på platformen, hvorefter alt brug af Risika automatisk tilrettes efter denne politik. På den måde maksimeres sikkerheden for at indgå de korrekte aftaler med dine B2B kunder.',
  [intl.home('sales-and-marketing-title')]:
    'Forbedrer din risikostyring ved en automatisering af kreditpolitikken',
  [intl.home('newsletter-description')]:
    'Den nye scorecardmodel er nu live på Risika platformen. Dette betyder at mange virksomheder i Danmark og Norge har fået en ny og mere præcis Risika score. Du kan her læse mere omkring den nye model. Derudover så er du også velkommen til at kontakte os, hvis du har nogle spørgsmål.',
  [intl.home('newsletter-description-ecp')]:
    'Ønsker du at automatisere dine kreditbeslutninger? Se vores on-demand webinar, hvor Jamie Reynolds & Kristoffer Gerdes introducerer vores nyeste produkt Credit Automator.',
  [intl.home('newsletter-title-ecp')]: 'Credit Automator On-Demand Webinar',
  [intl.home('newsletter-title')]: 'Credit Automator On-Demand Webinar',
  [intl.home('subtitle')]: 'Lær mere om, hvordan du får det meste ud af platformen.',
  [intl.home('title')]: 'Velkommen til Risika',
  [intl.homePage('contact-availability')]: 'Tilgængelig de næste 3 timer',
  [intl.homePage('contact-title')]: 'Har du et spørgsmål?',
  [intl.homePage('go-to-monitoring')]: 'Gå til overvågning',
  [intl.homePage('invite-button')]: 'Invitér',
  [intl.homePage('invite-description')]:
    'Hvis du inviterer en forretningspartner og de prøver systemet inden for en uge, så modtager du 2 entrébilletter til Tivoli.',
  [intl.homePage('invite-description-fields')]:
    'Udfyld felterne nedenfor, så sender vi en e-mail med prøve-login til personen inden for 24 timer.',
  [intl.homePage('invite-form-company')]: 'Søg efter virksomhed eller skriv CVR-nummer',
  [intl.homePage('invite-form-email')]: 'Arbejdsmail',
  [intl.homePage('invite-form-invite-button')]: 'Send invitation',
  [intl.homePage('invite-form-name')]: 'Fulde navn',
  [intl.homePage('invite-form-no-options')]: 'Ingen virksomheder',
  [intl.homePage('invite-sent-success')]:
    'Tak! Vi sender invitationen afsted inden for de næste 24 timer',
  [intl.homePage('invite-suplementary-text')]: 'Kender du nogen, der vil prøve Risika?',
  [intl.homePage('invite-title')]: 'Inviter en forretningspartner og kom i Tivoli',
  [intl.homePage('monitor-changes-subtitle')]: 'Ændringer de seneste 7 dage',
  [intl.homePage('monitor-changes-title')]: 'Mest relevante ændringer',
  [intl.homePage('monitor-overview-empty')]: 'Ingen virksomheder i overvågning',
  [intl.homePage('monitor-overview-import')]:
    'Importer din kundeliste og kom hurtigt i gang',
  [intl.homePage('monitor-portfolio-subtitle')]:
    '{count, plural, =0 {Du kan ikke overvåge flere virksomheder} one {Du kan stadig overvåge en virksomhed} other {Du kan stadig overvåge {count} virksomheder}}',
  [intl.homePage('monitor-portfolio-title')]: 'Dine overvågede virksomheder',
  [intl.homePage('monitor-suggestions-empty')]: 'Ingen foreslag endnu',
  [intl.homePage('monitor-suggestions-empty-description')]:
    'Så snart du har søgt på en virksomhed vil du få foreslag',
  [intl.homePage('monitor-suggestions-subtitle')]: 'Baseret på dine seneste søgninger',
  [intl.homePage('monitor-suggestions-title')]: 'Foreslag til overvågning',
  [intl.homePage('news-letter-april-main')]: 'Risikas april opdatering',
  [intl.homePage('news-letter-april-secondary')]:
    'Læs vores seneste nyhedsbrev med nye forbedringer',
  [intl.homePage('news-letter-february-main')]: 'Risikas februar opdatering',
  [intl.homePage('news-letter-february-secondary')]:
    'Læs vores seneste nyhedsbrev med nye forbedringer',
  [intl.homePage('news-letter-march-main')]: 'Risikas marts opdatering',
  [intl.homePage('news-letter-march-secondary')]:
    'Læs vores seneste nyhedsbrev med nye forbedringer',
  [intl.homePage('recent-searches-subtitle')]:
    'Du kan enkelt tilføje dem til din nuværende liste',
  [intl.homePage('recent-searches-title')]: 'Seneste søgninger',
  [intl.homePage('relevant-changes-empty')]: 'Ingen ændringer de seneste 7 dage.',
  [intl.homePage('relevant-changes-empty-description')]:
    'Så snart der er sket en ændring, vil du se den her',
  [intl.homePage('resources-and-integrations-title')]: 'Lær mere om Risika',
  [intl.homePage('resources-api-solution')]: 'API-løsning',
  [intl.homePage('resources-api-solution-description')]: 'Læs mere om vores API-løsning',
  [intl.homePage('resources-click-copy-example')]: 'Klik for kopiering i Kredittjek',
  [intl.homePage('resources-click-copy-example-description')]:
    'Let kopiering af data på en virksomhed',
  [intl.homePage('resources-dynamics-crm')]: 'Dynamics CRM',
  [intl.homePage('resources-dynamics-crm-description')]:
    'Læs mere om vores Microsoft Dynamics integration',
  [intl.homePage('resources-features-and-integrations')]: 'Funktioner & Integrationer',
  [intl.homePage('resources-features-and-integrations-description')]:
    'Læs om vores funktioner og integrationer',
  [intl.homePage('resources-monitoring-delete-example')]:
    'Slet flere overvågede selskaber samtidigt',
  [intl.homePage('resources-monitoring-delete-example-description')]:
    'Demo af hvordan du sletter flere selskaber samtidigt',
  [intl.homePage('resources-monitoring-example')]: 'Hvordan virker vores overvågning?',
  [intl.homePage('resources-monitoring-example-desription')]:
    'Demo af de forskellige funktioner i overvågning',
  [intl.homePage('resources-monitoring-import-example')]:
    'Importer virksomheder i overvågning',
  [intl.homePage('resources-monitoring-import-example-description')]:
    'Demo af importering af virksomheder i overvågning',
  [intl.homePage('resources-navision')]: 'Navision',
  [intl.homePage('resources-navision-description')]:
    'Læs mere om vores Microsoft Navision integration',
  [intl.homePage('resources-search-example')]: 'Søg efter virksomheder',
  [intl.homePage('resources-search-example-description')]:
    'Demo af hvordan du kan søge efter virksomheder',
  [intl.homePage('welcome')]: 'Velkommen {name}',
  [intl.homePage('all-lists')]: 'Alle lister',
  [intl.homePage('portfolio-recent-changes')]: 'Porteføljens seneste ændringer',
  [intl.homePage('no-data')]: 'Ingen data at vise for denne liste',
  [intl.importButton('description')]:
    'CSV og Excel (XLSX) format understøttet. Kolonnen der indeholder CVR-numre skal hedde "cvr"',
  [intl.importButton('text')]: 'Importer',
  [intl.keyFinancialFigures('export-excel')]: 'Eksport Excel',
  [intl.keyFinancialFigures('original-currency')]: 'Oprindelig valuta',
  [intl.keyFinancialFigures('selected-currency')]: 'Valgt valuta',
  [intl.keyFinancialFigures('export-pdf')]: 'Eksport PDF',
  [intl.keyFinancialFigures('EBIT')]: 'EBIT',
  [intl.keyFinancialFigures('EBITDA')]: 'EBITDA',
  [intl.keyFinancialFigures('annual-report-button')]: 'Årsrapport',
  [intl.keyFinancialFigures('assets')]: 'Aktiver i alt',
  [intl.keyFinancialFigures('balance')]: 'Passiver i alt',
  [intl.keyFinancialFigures('basic-view')]: 'Simpel oversigt',
  [intl.keyFinancialFigures('cash')]: 'Likvide beholdninger',
  [intl.keyFinancialFigures('consolidated-statement')]: 'Koncernregnskab',
  [intl.keyFinancialFigures('contributedCapital')]: 'Selskabskapital',
  [intl.keyFinancialFigures('currentAssets')]: 'Omsætningsaktiver',
  [intl.keyFinancialFigures('currentFinancialAssets')]: 'Værdipapirer',
  [intl.keyFinancialFigures('debt')]: 'Samlet gæld',
  [intl.keyFinancialFigures('depreciation')]: 'Afskrivninger',
  [intl.keyFinancialFigures('detailed-view')]: 'Detaljeret',
  [intl.keyFinancialFigures('dividend')]: 'Udbytte',
  [intl.keyFinancialFigures('ebit')]: 'EBIT',
  [intl.keyFinancialFigures('ebitda')]: 'EBITDA',
  [intl.keyFinancialFigures('equity')]: 'Egenkapital',
  [intl.keyFinancialFigures('full-view')]: 'Fuld oversigt',
  [intl.keyFinancialFigures('grossResult')]: 'Bruttoresultat',
  [intl.keyFinancialFigures('intangibleAssets')]: 'Immaterielle anlægsaktiver',
  [intl.keyFinancialFigures('interestExpenses')]: 'Finansielle udgifter',
  [intl.keyFinancialFigures('interestIncome')]: 'Finansielle indtægter',
  [intl.keyFinancialFigures('inventories')]: 'Varelager',
  [intl.keyFinancialFigures('liabilitiesAndEquity')]: 'Passiver i alt',
  [intl.keyFinancialFigures('longTermDebt')]: 'Langfristet gæld',
  [intl.keyFinancialFigures('noncurrentAssets')]: 'Anlægsaktiver i alt',
  [intl.keyFinancialFigures('noncurrentFinancialAssets')]: 'Finansielle anlægsaktiver',
  [intl.keyFinancialFigures('not-available')]: 'Ikke tilgængelig',
  [intl.keyFinancialFigures('ordinaryProfit')]: 'Ordinært resultat',
  [intl.keyFinancialFigures('otherNetFinancialIncome')]:
    'Netto øvrige finansielle poster',
  [intl.keyFinancialFigures('otherShortTermDebt')]: 'Anden gæld',
  [intl.keyFinancialFigures('otherShortTermReceivables')]: 'Andre tilgodehavender',
  [intl.keyFinancialFigures('parent-statement')]: 'Moderregnskab',
  [intl.keyFinancialFigures('profitLoss')]: 'Årets resultat',
  [intl.keyFinancialFigures('propertyPlantAndEquipment')]: 'Materielle anlægsaktiver',
  [intl.keyFinancialFigures('provisions')]: 'Hensatte forpligtelser',
  [intl.keyFinancialFigures('reserves')]: 'Andre reserver',
  [intl.keyFinancialFigures('retainedEarnings')]: 'Overført resultat',
  [intl.keyFinancialFigures('revenue')]: 'Omsætning',
  [intl.keyFinancialFigures('shareCapital')]: 'Selskabskapital',
  [intl.keyFinancialFigures('shortTermDebt')]: 'Kortfristet gæld',
  [intl.keyFinancialFigures('shortTermDebtToBanks')]: 'Kortfristet gæld til banker',
  [intl.keyFinancialFigures('shortTermDebtToGroupEnterprises')]:
    'Gæld til koncernselskaber',
  [intl.keyFinancialFigures('shortTermReceivablesFromGroupEnterprises')]:
    'Tilgodehavender fra koncernselskaber',
  [intl.keyFinancialFigures('shortTermTaxPayables')]: 'Selskabsskat',
  [intl.keyFinancialFigures('shortTermTradePayables')]: 'Leverandørgæld',
  [intl.keyFinancialFigures('staffExpenses')]: 'Lønomkostninger',
  [intl.keyFinancialFigures('taxExpenses')]: 'Skat af årets resultat',
  [intl.keyFinancialFigures('title')]: 'Regnskabstal',
  [intl.keyFinancialFigures('total-debt')]: 'Samlet gæld',
  [intl.keyFinancialFigures('currency')]: 'Valuta',
  [intl.keyFinancialFigures('profit-loss')]: 'Årets resultat',
  [intl.keyFinancialFigures('solidity')]: 'Soliditetsgrad',
  [intl.keyFinancialFigures('liquidity')]: 'Likviditetsgrad',
  [intl.keyFinancialFigures('capital')]: 'Kapital',
  [intl.keyFinancialFigures('shortTermReceivablesFromSalesAndServices')]:
    'Tilgodehavender fra salg og tjenesteydelser',
  [intl.keyFinancialFigures('start-date')]: 'Rapportens startdato',
  [intl.keyFinancialFigures('end-date')]: 'Rapportens slutdato',
  [intl.loginRegister('forgot-your-password')]: 'Glemt din adgangskode',
  [intl.loginRegister('login-submit-button')]: 'Log på',
  [intl.loginRegister('login-to-account')]: 'Log ind på din konto',
  [intl.loginRegister('register-create-account')]: 'Opret din konto',
  [intl.loginRegister('register-create-button')]: 'Opret',
  [intl.loginRegister('register-popup-failure')]:
    'Oprettelse af konto mislykkedes! Kontakt os venligst, så hjælper vi.',
  [intl.loginRegister('register-popup-password')]: 'Adgangskoderne stemmer ikke overens',
  [intl.loginRegister('register-popup-success')]: 'Konto oprettet! Du kan nu logge ind.',
  [intl.loginRegister('remember-me')]: 'Husk mig',
  [intl.loginRegister('static-content-paragraph-1')]:
    'Vores vision er at levere de bedste økonomiske risikostyringsløsninger til vores kunder globalt.',
  [intl.loginRegister('static-content-paragraph-2')]:
    'For at opnå denne vision udvinder vi værdifulde indsigter fra både ikke-traditionelle og offentlige datakilder ved hjælp af avancerede algoritmer. Slutmålet er tilpassede, automatiserede og intuitive løsninger, der hjælper dig og din virksomhed med at minimere risikoen, spare tid og tage bedre beslutninger.',
  [intl.loginRegister('static-content-welcome')]: 'Velkommen til Risika!',
  [intl.mainRecommendation('auditor_selected')]: 'Revisor tilvalgt',
  [intl.mainRecommendation('company-id')]: 'Virksomheds ID',
  [intl.mainRecommendation('company-id-dk')]: 'Virksomheds ID (CVR)',
  [intl.mainRecommendation('company-id-no')]: 'Virksomheds ID (Orgnr.)',
  [intl.mainRecommendation('company-id-se')]: 'Virksomheds ID (Org.nr.)',
  [intl.mainRecommendation('copy_company_address')]:
    'Firmas adresse kopieret til udklipsholder',
  [intl.mainRecommendation('copy_company_local_id')]:
    'Virksomhedens lokale id kopieret til udklipsholder',
  [intl.mainRecommendation('copy_company_name')]: 'Firmanavn kopieret til udklipsholder',
  [intl.mainRecommendation('currency')]: 'Angivet i ',
  [intl.mainRecommendation('edit-view')]: 'Visning',
  [intl.mainRecommendation('get-credit-information')]: 'Få kreditoplysninger',
  [intl.mainRecommendation('latest-annual-report')]: 'Seneste årsrapport',
  [intl.mainRecommendation('performance')]: 'Historisk udvikling',
  [intl.mainRecommendation('recommendation-credit')]: 'Ingen kredit',
  [intl.mainRecommendation('recommendation-credit-unknown')]: 'Ukendt',
  [intl.mainRecommendation('recommended-credit-description')]: 'Anbefalet kreditmaksimum',
  [intl.mainRecommendation('recommended-days')]:
    '{amount, plural, =0 {Ingen kredit dage} one {Maks {amount} dag} other {Maks {amount} dage}}',
  [intl.mainRecommendation('recommended-days-description')]: 'Anbefalet antal kreditdage',
  [intl.mainRecommendation('risika-estimation')]: 'Risikas estimering',
  [intl.mainRecommendation('risk-level')]: 'Risiko niveau',
  [intl.mainRecommendation('risk-level-high')]: 'Høj risiko',
  [intl.mainRecommendation('risk-level-high-short')]: 'Høj',
  [intl.mainRecommendation('risk-level-low')]: 'Lav risiko',
  [intl.mainRecommendation('risk-level-low-short')]: 'Lav',
  [intl.mainRecommendation('risk-level-medium')]: 'Middel risiko',
  [intl.mainRecommendation('risk-level-medium-short')]: 'Middel',
  [intl.mainRecommendation('risk-level-sole-proprietorship')]: 'Enkeltmandsvirksomhed',
  [intl.mainRecommendation('risk-level-unknown')]: 'Ukendt risiko',
  [intl.mainRecommendation('statstidende-header')]: 'Konkurs dekret',
  [intl.mainRecommendation('statstidende-title')]: 'Statstidende',
  [intl.mainRecommendation('status')]: 'Status: ',
  [intl.mainRecommendation('status-active-from')]: 'Statusændring fra: ',
  [intl.mainRecommendation('you-have')]: 'Du har',
  [intl.mainRecommendation('explanation')]: 'Forklaring',

  [intl.mainRecommendation('estimation')]: 'Risikas estimering',
  [intl.mainRecommendation('norwegian-payment-remarks')]: 'Betalingsanmærkninger',
  [intl.mainRecommendation('credit-override')]: 'Kreditpolitik tilsidesættelse',
  [intl.mainRecommendation('no-override-available')]:
    'Der er ingen tilgængelig tilsidesættelse for denne virksomhed',
  [intl.mainRecommendation('request-override')]: 'Anmod om tilsidesættelse',
  [intl.mainRecommendation('company-highlights')]: 'Opmærksomhedspunkter',
  [intl.mainRecommendation('risika-score-calculation')]: 'Risika score udregning',

  [intl.mainRecommendation('recommendation')]: 'Anbefaling',
  [intl.mainRecommendation('credit-max')]: 'Kreditmaksimum',
  [intl.mainRecommendation('credit-days')]: 'Antal kreditdage',
  [intl.mainRecommendation('credit-policy')]: 'Kreditpolitik',
  [intl.mainRecommendation('date')]: 'Dato: ',
  [intl.mainRecommendation('case-id')]: 'Sagsnummer: ',
  [intl.mainRecommendation('statstidende-link')]: 'Link: ',
  [intl.mainRecommendation('bankruptcy-noticed')]: 'Konkurs dekret',
  [intl.mainRecommendation('status-label')]: 'Status: ',
  [intl.mainRecommendation('valid-from')]: 'Gyldig fra: ',
  [intl.mainRecommendation('undergoing-bankruptcy')]: 'Under konkursbehandling',
  [intl.mainRecommendation('performance-no-data')]:
    'Ydelseshistorikdata for dette firma er ikke tilgængelig',
  [intl.mainRecommendation('fraud-indicators')]: 'Svigindikatorer',
  [intl.mainRecommendation('fraud-indicators-policy-not-set-title')]:
    'Politik for svigindikatorer er ikke fastsat',
  [intl.mainRecommendation('fraud-indicators-policy-not-set-description')]:
    'Opret venligst en politik for svindelindikatorer',
  [intl.mainRecommendation('fraud-indicators-none-title')]: 'Ingen fundet',
  [intl.mainRecommendation('fraud-indicators-none-description')]:
    'Ingen svindelindikatorer fundet på denne virksomhed',
  [intl.paymentRemarks('widget-title')]: 'Betalingsanmærkninger',
  [intl.paymentRemarks('widget-text')]:
    '{count, plural, one {Der er {count} registreret betalingsanmærkning på denne virksomhed.} other {Der er {count} registrerede betalingsanmærkninger på denne virksomhed.}}',
  [intl.paymentRemarks('widget-open-modal')]: 'Se betalings\u00ADanmærkninger',
  [intl.paymentRemarks('creditor')]: 'Kreditorer',
  [intl.paymentRemarks('type')]: 'Anmærkningstype',
  [intl.paymentRemarks('case-id')]: 'Sags ID',
  [intl.paymentRemarks('amount')]: 'Beløb',
  [intl.paymentRemarks('registered')]: 'Registreret',
  [intl.paymentRemarks('status')]: 'Status',
  [intl.paymentRemarks('data-source')]: 'Datakilde',
  [intl.paymentRemarks('total-remarks')]:
    '{count, plural, one {{count} betalingsanmærkning samlet} other {{count} betalingsanmærkninger samlet}}',
  [intl.paymentRemarks('asterisk-1')]:
    '* Ukendte beløb skyldes, at sagens valuta ikke er i NOK.',
  [intl.paymentRemarks('asterisk-2')]:
    '** Ved sammenlægning af to selskaber vil sager fra selskabet, der er opløst efter en fusion, blive oprettet med et nyt CVR-nummer.',
  [intl.paymentRemarks('legal-collector')]:
    'Betalingsanmærkninger indsamles fra Intrum, Visma og Kredinor og er gyldige til brug i kreditrapportering i Norge. Ifølge norsk lov kan betalingsanmærkninger opbevares i fire år, hvorefter de slettes fra vores system.',
  [intl.paymentRemarks('total-unsettled-amount')]: 'Samlet ubetalt beløb',
  [intl.paymentRemarks('total-unsettled-number')]: 'Samlet antal ubetalinger',
  [intl.paymentRemarks('debt-collection-agencies')]: 'Inkassobureauer',
  [intl.paymentRemarks('data-from')]: 'Data fra',
  [intl.paymentRemarks('unique-creditors')]: 'Unikke kreditorer',
  [intl.paymentRemarks('avg-unsettled-time')]: 'Gennemsnitlig gældsperiode',
  [intl.paymentRemarks('active-creditors')]: 'aktive kreditorer',

  [intl.newScorecard('title')]: 'Risika score udregning',
  [intl.newScorecard('title-tooltip')]:
    'Risika score modellen udvælger 25 faktorer ud af i alt 180 tilgængelige der bedst kan forudsige konkurs. Hver faktor bliver tildelt en vægtning, baseret på dens forklaringsevne og de to tal konbineret resulterer i den samlede score.',
  [intl.newScorecard('high_positive_impact')]: 'Stor positiv påvirkning',
  [intl.newScorecard('moderate_positive_impact')]: 'Moderat positiv påvirkning',
  [intl.newScorecard('minor_impact')]: 'Mindre påvirkning',
  [intl.newScorecard('moderate_negative_impact')]: 'Moderat negativ påvirkning',
  [intl.newScorecard('high_negative_impact')]: 'Stor negativ påvirkning',
  [intl.newScorecard('factor-counter')]:
    '{count, plural, =0 {{count} faktorer} one {{count} faktor} other {{count} faktorer}}',
  [intl.newScorecard('minor-impact-tooltip')]:
    'Disse faktorer har meget begrænset betydning for den overordnede score, og kan således hverken regnes som havende nogen videre positiv el. negativ betydning.',

  [intl.monitorFrequency('daily')]: 'Hver dag',
  [intl.monitorFrequency('daily-select')]: 'Dagligt',
  [intl.monitorFrequency('friday-select')]: 'Fredag',
  [intl.monitorFrequency('how-often-label')]: 'Hvor ofte?',
  [intl.monitorFrequency('monday-select')]: 'Mandag',
  [intl.monitorFrequency('monthly')]: 'Hver måned',
  [intl.monitorFrequency('monthly-select')]: 'Månedligt (første mandag)',
  [intl.monitorFrequency('saturday-select')]: 'Lørdag',
  [intl.monitorFrequency('sunday-select')]: 'Søndag',
  [intl.monitorFrequency('thursday-select')]: 'Torsdag',
  [intl.monitorFrequency('title')]: 'Hvor ofte vil du have besked?',
  [intl.monitorFrequency('tuesday-select')]: 'Tirsdag',
  [intl.monitorFrequency('wednesday-select')]: 'Onsdag',
  [intl.monitorFrequency('weekly-friday')]: 'Hver fredag',
  [intl.monitorFrequency('weekly-monday')]: 'Hver mandag',
  [intl.monitorFrequency('weekly-saturday')]: 'Hver lørdag',
  [intl.monitorFrequency('weekly-select')]: 'Ugentligt',
  [intl.monitorFrequency('weekly-sunday')]: 'Hver søndag',
  [intl.monitorFrequency('weekly-thursday')]: 'Hver torsdag',
  [intl.monitorFrequency('weekly-tuesday')]: 'Hver tirsdag',
  [intl.monitorFrequency('weekly-wednesday')]: 'Hver onsdag',
  [intl.monitorFrequency('which-day-label')]: 'Hvilken dag?',
  [intl.navigation('advanced-filters')]: 'Filtrering',
  [intl.navigation('company-comparison')]: 'Sammenlign',
  [intl.navigation('cfo-view')]: 'CFO view',
  [intl.navigation('chat')]: 'Support',
  [intl.navigation('company-hierarchy')]: 'Koncernhierarki',
  [intl.navigation('credit-check')]: 'Kredittjek',
  [intl.navigation('credit-policy')]: 'Kreditpolitik',
  [intl.navigation('fraud-indicators')]: 'Risikofaktorer',
  [intl.navigation('fraud-module')]: 'Svindel- modul',
  [intl.navigation('customer-management')]: 'Kunder',
  [intl.navigation('dashboard')]: 'Dashboard',
  [intl.navigation('developer')]: 'Udvikler',
  [intl.navigation('faq')]: 'FAQ',
  [intl.navigation('home')]: 'Forside',
  [intl.navigation('login')]: 'Log ind',
  [intl.navigation('logout')]: 'Log ud',
  [intl.navigation('relation-overview')]: 'Personer',
  [intl.creditPolicyNew('people')]: 'Personer',
  [intl.navigation('risk-monitoring')]: 'Overvåg',
  [intl.navigation('search-business')]: 'Søgning',
  [intl.navigation('settings')]: 'Indstillinger',
  [intl.navigation('enterprise-credit-policy')]: 'Credit Automator',
  [intl.navigation('user-management')]: 'Brugerstyring',
  [intl.navigation('revoked-warning')]: 'Du har ikke adgang',
  [intl.navigationTooltip('company-hierarchy')]:
    'Søg efter en virksomhed med datterselskaber',
  [intl.navigationTooltip('credit-check')]: 'Søg på en virksomhed først',
  [intl.navigationTooltip('relation-overview')]: 'Søg efter en person først',
  [intl.news('no-articles')]: 'Ingen artikler på nuværende tidspunkt.',
  [intl.news('show-less')]: 'Færre',
  [intl.news('show-more')]: 'Flere',
  [intl.news('title')]: 'Nyheder',
  [intl.notFound('best-experience')]: 'For den bedste oplevelse',
  [intl.notFound('better-performance')]: 'Bedre ydeevne',
  [intl.notFound('not-found-402')]:
    'Hvis du er på en demo, så er begrænsningerne i dit abonnement nok desværre nået. Du kan kontakte den person, der er ansvarlig for dit abonnement i din virksomhed eller kontakte os direkte',
  [intl.notFound('not-found-404')]:
    'Du er desværre kommet ind på en side der ikke eksisterer. Du kan klikke på knappen for at komme tilbage til Dashboard.',
  [intl.notFound('not-found-500')]:
    'Vi oplever i øjeblikket tekniske problemer. Vi arbejder hårdt på at få dem løst og systemet skulle gerne være online inden for kort tid!',
  [intl.notFound('not-found-503')]:
    'Der gik noget galt med din anmodning. Det er muligt, at vores servere er nede for vedligeholdelse eller overbelastet. Prøv igen om få minutter.',
  [intl.notFound('not-found-button-dashboard')]: 'Gå til "Dashboard"',
  [intl.notFound('not-found-button-risika-dk')]: 'Gå til "Risika.dk"',
  [intl.notFound('not-supported-header')]: 'Ikke understøttet',
  [intl.notFound('not-supported-text')]:
    'For at sikre den bedste oplevelse for vores brugere har vi deaktiveret support for Internet Explorer. Følg venligst linkene nedenfor for at downloade en af ​​vores anbefalede browsere.',
  [intl.notFound('pricing-button')]: 'Gå til "Priser og planer"',
  [intl.notFound('under-meintenance-header')]: 'Under kontrol',
  [intl.notFound('under-meintenance-text')]:
    'Risika dashboard og API er i øjeblikket under kontrol. Vi kommer snart tilbage!',
  [intl.popconfirm('cancel')]: 'Annuller',
  [intl.popconfirm('confirm')]: 'Okay',
  [intl.popconfirm('title')]: 'Er du sikker?',
  [intl.relationOverview('end-date')]: 'Slutdato',
  [intl.relationOverview('function')]: 'Funktion',
  [intl.relationOverview('function-status')]: 'Funktionsstatus',
  [intl.relationOverview('no-entity-redirect')]:
    'Ingen virksomhed eller individ valgt, sender dig tilbage til Søg Virksomhed',
  [intl.relationOverview('start-date')]: 'Startdato',
  [intl.relationOverview('title')]: 'Relationsoverblik',
  [intl.relations('auditor')]: 'Revisor',
  [intl.relations('board_member')]: 'Medlem',
  [intl.relations('branch_manager')]: 'Filialbestyrer',
  [intl.relations('ceo')]: 'Adm. direktør',
  [intl.relations('founder')]: 'Stifter',
  [intl.relations('legal_owner')]: 'Legal ejer',
  [intl.relations('management')]: 'Direktion',
  [intl.relations('president')]: 'Formand',
  [intl.relations('real_owner')]: 'Reel ejer',
  [intl.relations('stakeholder')]: 'Interessant',
  [intl.relations('substitute')]: 'Suppleant',
  [intl.relations('vice_president')]: 'Næstformand',
  [intl.relevantFinancialRatios('asset_turnover')]: 'Aktivernes omsætningshastighed',
  [intl.relevantFinancialRatios('based-on-parent-statement')]:
    'Baseret på moderregnskabstal',
  [intl.relevantFinancialRatios('basic_earning_power')]: 'Indtjeningsevne',
  [intl.relevantFinancialRatios('capacity_ratio')]: 'Kapacitetsgrad',
  [intl.relevantFinancialRatios('cash_conversion_rate')]: 'Pengestrømskonverteringsrate',
  [intl.relevantFinancialRatios('cash_ratio')]: 'Pengeraten',
  [intl.relevantFinancialRatios('current_assets_to_equity')]:
    'Omsætningsaktiver-til-egenkapital rate',
  [intl.relevantFinancialRatios('current_ratio')]: 'Likviditetsgrad',
  [intl.relevantFinancialRatios('debt_ratio')]: 'Gældsgrad',
  [intl.relevantFinancialRatios('debt_to_equity_ratio')]: 'Gearing',
  [intl.relevantFinancialRatios('ebitda_to_debt_ratio')]: 'EBITDA til gældsprocent',
  [intl.relevantFinancialRatios('fixed_assets_to_long_term_liabilities_ratio')]:
    'Kapitalbindingsgrad',
  [intl.relevantFinancialRatios('gross_margin')]: 'Dækningsgrad',
  [intl.relevantFinancialRatios('interest_coverage')]: 'Rentedækningsgrad',
  [intl.relevantFinancialRatios('interest_margin')]: 'Rentemarginal',
  [intl.relevantFinancialRatios('interest_rate_on_debt_with_average')]: 'Gældsrente',
  [intl.relevantFinancialRatios('not-available')]: 'Ikke tilgængeligt',
  [intl.relevantFinancialRatios('one_year_change_in_equity')]: 'Ændring i egenkapital',
  [intl.relevantFinancialRatios('operating_margin')]: 'Overskudsgrad',
  [intl.relevantFinancialRatios('profit_margin')]: 'Resultatgrad',
  [intl.relevantFinancialRatios('return_on_assets')]: 'Afkastningsgrad',
  [intl.relevantFinancialRatios('return_on_equity')]: 'Egenkapitalforrentning',
  [intl.relevantFinancialRatios('solidity_ratio')]: 'Soliditetsgrad',
  [intl.relevantFinancialRatios('title')]: 'Relevante finansielle nøgletal',
  [intl.reportBug('agreement')]:
    'Ved at indgive en rapport tillader du os at bruge din e-mail som reference til dette problem.',
  [intl.reportBug('description')]: 'Beskriv den fejl du oplevede',
  [intl.reportBug('drag-drop')]:
    'Du kan Træk- & Slippe eller Gennemse for at vedhæfte nogle billeder.',
  [intl.riskMonitoringNew('no-monitoring-list-available')]:
    'Ingen overvågningsliste tilgængelig',
  [intl.riskMonitoringNew('create-monitoring-list-text-empty')]:
    'Opret eller tilmeld dig en overvågningsliste for at begynde at modtage opdateringer om virksomheder, der betyder noget for dig.',
  [intl.reportBug('error-in-credit-information')]: 'Fejl i kreditinformation',
  [intl.reportBug('error-in-financial-data')]: 'Fejl i finansiel data',
  [intl.reportBug('error-in-general-information')]:
    'Fejl i general virksomhedsinformation',
  [intl.reportBug('error-in-highlights')]: 'Fejl i opmærksomhedspunkter',
  [intl.reportBug('error-in-relation-data')]: 'Fejl i relationer',
  [intl.reportBug('other')]: 'Andet',
  [intl.reportBug('report-bug-paper')]: 'Rapportér en fejl',
  [intl.reportBug('send-button')]: 'Send fejlrapport',
  [intl.reportBug('type-of-report')]: 'Hvilken slags fejl oplevede du?',
  [intl.restriction('basic-description')]:
    'Dit abonnement dækker ikke denne funktion. Klik på knappen herunder for at få flere oplysninger',
  [intl.restriction('create-new-list-content')]:
    'Denne funktion er ikke tilgængelig i dit abonnement.',
  [intl.restriction('create-new-list-title')]: 'Monitoring lists',
  [intl.restriction('credit-reports-button')]: 'Priser & planer',
  [intl.restriction('credit-reports-description')]:
    'Du har ikke adgang til denne funktion. Du kan opgradere dit abonnement eller tilkøbe kreditrapporter som en separat funktion.',
  [intl.restriction('credit-reports-title')]: 'Kreditrapporter',
  [intl.restriction('export-button')]: 'Priser & planer',
  [intl.restriction('export-description')]:
    'Du har ikke adgang til denne funktion. Du kan opgradere dit abonnement eller tilkøbe muligheden for eksport som en separat funktion.',
  [intl.restriction('export-title')]: 'Eksportering',
  [intl.restriction('key-figures-title')]: 'Regnskabstal',
  [intl.restriction('risk-monitoring-button')]: 'Priser & planer',
  [intl.restriction('risk-monitoring-description')]:
    'Du har ikke adgang til denne funktion. Du kan opgradere dit abonnement eller tilkøbe overvågning som en separat funktion.',
  [intl.restriction('risk-monitoring-title')]: 'Overvågning',
  [intl.observationalLists('title')]: 'Risk Insights',
  [intl.observationalLists('risika-score-title')]: 'Risika Kreditscore',
  [intl.observationalLists('risika-score-by-time-title')]:
    'Udvikling i Risika Kredit Score',
  [intl.observationalLists('risika-score-by-company-title')]:
    'Risika Kredit Score Fordelt På Selskabstype',
  [intl.observationalLists('risika-score-distribution-title')]:
    'Risika Kreditscore Fordeling',
  [intl.observationalLists('risika-score-employees-title')]:
    'Risika Kredit Score Fordelt På Antal Medarbejdere',
  [intl.observationalLists('risika-score-industry-title')]:
    'Risika Kredit Score Fordelt På  Brancher',
  [intl.observationalLists('key-figures-industry-title')]: 'Nøgletal Fordelt På Brancher',
  [intl.observationalLists('development-selected-key-figures-title')]:
    'Udvikling i Udvalgte Nøgletal',
  [intl.observationalLists('geographical-risika-score-title')]:
    'Risika Kredit Score Fordelt Geografisk',
  [intl.observationalLists('risika-score-over-time-title')]:
    'Udvikling i Risika Kredit Score',
  [intl.observationalLists('risika-key-figures-by-company-type-title')]:
    'Nøgletal Fordelt På Selskabstype',
  [intl.observationalLists('tab-risika-credit-score')]: 'Risika Kreditscore',
  [intl.observationalLists('tab-financial-performance')]: 'Økonomisk Præstation',
  [intl.observationalLists('tab-companies')]: 'Virksomheder',
  [intl.observationalLists('annual-result-average-title')]: 'Årsresultat, Gns.',
  [intl.observationalLists('equity-average-title')]: 'Egenkapital, Gns.',
  [intl.observationalLists('employees-average-title')]: 'Antal medarbejdere, Gns.',
  [intl.observationalLists('development-credit-policy-title')]:
    'Udvikling i Overholdelse Af Kreditpolitikken',
  [intl.observationalLists('list-id-error')]: 'Angiv venligst et gyldigt liste-id!',
  [intl.observationalLists('credit-score')]: 'Kreditscore',
  [intl.observationalLists('select-industry')]: 'Vælg branche',
  [intl.observationalLists('highlights')]: 'Opmærksomhedspunkter',
  [intl.observationalLists('credit_policy.violations')]: 'Overtrædelser',
  [intl.observationalLists('risk_assessment.code')]: 'Risikoniveau',
  [intl.observationalLists('credit_automator_result')]: 'Kreditpolitik ændringer',
  [intl.observationalLists('credit_policy.allowed')]: 'Overholdelse',
  [intl.observationalLists('company-per-page')]: 'Virksomhed pr. side',
  [intl.observationalLists('export-button-tooltip')]:
    'Vælg de virksomheder, du vil eksportere.',
  [intl.riskMonitoring('intelligence-suite')]: 'Risk Insights',
  [intl.riskMonitoring('intelligence-suite-tooltip-access')]:
    'Få adgang til porteføljeindsigt og forstå din risikoprofil.',
  [intl.riskMonitoring(
    'intelligence-suite-tooltip-no-access'
  )]: `Din abonnementsplan inkluderer ikke Risk Insights. Men kontakt venligst vores Customer Success-team, hvis du gerne vil vide mere om, hvordan Risk Insights kan hjælpe dig med at træffe informerede strategiske beslutninger.`,
  [intl.riskMonitoring('company')]: 'Virksomhed',
  [intl.riskMonitoring('company-search')]: 'Søg efter en virksomhed',
  [intl.riskMonitoring('company-type')]: 'Virksomhedstype',
  [intl.riskMonitoring('companyName')]: 'Virksomhed',
  [intl.riskMonitoring('companyType')]: 'Virksomhedstype',
  [intl.riskMonitoring('country')]: 'Land',
  [intl.riskMonitoring('credit-max')]: 'Kreditmaks',
  [intl.riskMonitoring('currency')]: 'Valuta',
  [intl.riskMonitoring('date-of-incorporation')]: 'Stiftelsesdato',
  [intl.riskMonitoring('dateOfIncorporation')]: 'Stiftelsesdato',
  [intl.riskMonitoring('edit-company-title')]: 'Rediger virksomhed',
  [intl.riskMonitoring('employee-interval')]: 'Ansatte',
  [intl.riskMonitoring('employeeInterval')]: 'Ansatte',
  [intl.riskMonitoring('equity')]: 'Egenkapital',
  [intl.riskMonitoring('from-to-count')]: '{from}-{to} af {count}',
  [intl.riskMonitoring('import-from-file')]: 'Importér fra fil',
  [intl.riskMonitoring('import-from-file-company-id-field')]:
    "Vælg feltet med virksomheds ID'et",
  [intl.riskMonitoring('import-from-file-include-internal-id')]: 'Inkluder internt ID',
  [intl.riskMonitoring('import-from-file-internal-id-field')]:
    'Vælg feltet med jeres interne ID',
  [intl.riskMonitoring('import-from-manual-entry')]: 'Indtast manuelt',
  [intl.riskMonitoring('import-from-manual-entry-helper-text')]:
    'Adskilt med mellemrum, komma eller semikolon',
  [intl.riskMonitoring('internal-id')]: 'Internt ID',
  [intl.riskMonitoring('internalId')]: 'Internt ID',
  [intl.riskMonitoring('liquidity')]: 'Likviditetsgrad',
  [intl.riskMonitoring('local-id')]: 'Virksomheds ID',
  [intl.riskMonitoring('local-id-search')]: 'Søg efter Virksomheds ID',
  [intl.riskMonitoring('internal-id-search')]: 'Søg efter Internt ID',
  [intl.riskMonitoring('localId')]: 'Virksomheds ID (CVR)',
  [intl.riskMonitoring('no-credit')]: 'Ingen kredit',
  [intl.riskMonitoring('no-data')]: 'Intet data tilgængeligt',
  [intl.riskMonitoring('profit-loss')]: 'Årets resultat',
  [intl.riskMonitoring('profitLoss')]: 'Årets resultat',
  [intl.riskMonitoring('reject-companies')]: 'Føj til listen i Kreditpolitik',
  [intl.riskMonitoring('remaining-monitoring')]: 'Overvågninger tilbage',
  [intl.riskMonitoring('remove')]: 'Fjern',
  [intl.riskMonitoring('risika-score')]: 'Score',
  [intl.riskMonitoring('rows-per-page')]: 'Pr. side',
  [intl.riskMonitoring('score')]: 'Score',
  [intl.riskMonitoring('solidity')]: 'Soliditetsgrad',
  [intl.riskMonitoring('status')]: 'Status',
  [intl.riskMonitoring('summation-change-duration')]: 'Ændringer den seneste uge',
  [intl.riskMonitoring('title')]: 'Overvågning',
  [intl.riskMonitoring('top-negative')]: 'Mest negative',
  [intl.riskMonitoring('you-already-have-preset-with-name')]:
    'Du har allerede en indstilling med dette navn',
  [intl.riskMonitoring('please-select-a-name')]: 'Vælg et navn',
  [intl.riskMonitoring('preset-name')]: 'Eksport-navn',
  [intl.riskMonitoring('select-preset')]: 'Vælg eksport',
  [intl.riskMonitoring('no-presets-saved')]: 'Ingen eksport gemt',
  [intl.riskMonitoring('capital')]: 'Kapital',
  [intl.riskMonitoring('cannot-monitor-that-many-companies')]:
    'Din abonnementsplan tillader ikke, at du overvåger så mange virksomheder.',
  [intl.riskMonitoring('invalid-request')]: 'Ugyldig anmodning, tjek venligst listen',
  [intl.riskMonitoring('fraud-indicators-column')]: 'Risikoindikatorer',
  [intl.riskMonitoring('fraud-indicators-title')]: 'Risikoindikatorer',
  [intl.riskMonitoringNew('error-loading-page')]:
    'Vi oplevede et problem med at indlæse dine overvågningslister. Opdater venligst siden og prøv igen.',
  [intl.riskMonitoringNew('table-shown')]: 'Vist',
  [intl.riskMonitoringNew('table-hidden')]: 'Skjult',
  [intl.riskMonitoringNew('add')]: 'Tilføj',
  [intl.riskMonitoringNew('add-companies-button')]: 'Importer',
  [intl.riskMonitoringNew('add-list-name-snackbar')]: 'Venlist vælg et navn til listen!',
  [intl.riskMonitoringNew('add-name')]: 'Navngiv din liste',
  [intl.riskMonitoringNew('add-new-list')]: 'Ny liste',
  [intl.riskMonitoringNew('add-new-subscription')]: 'Tilføj notifikation',
  [intl.riskMonitoringNew('add-to-monitoring-counter')]:
    'Forsøger import af {count} virksomheder til "{listName}". Sikker på du vil fortsætte?',
  [intl.riskMonitoringNew('add-to-monitoring-list-title')]:
    'Indlæs fil eller indtast CVR-numre manuelt',
  [intl.riskMonitoringNew('added-some-companies')]:
    'Beløbet overstiger din nuværende overvågningsplan. Tilføjet kun nogle virksomheder.',
  [intl.riskMonitoringNew('adding-companies-wait')]:
    'Tilføjelse af virksomheder. Vent venligst...',
  [intl.riskMonitoringNew('are-you-sure-delete')]:
    'Er du sikker på, du vil slette listen "{text}"?',
  [intl.riskMonitoringNew('are-you-sure-delete-entries')]:
    '{count, plural, one {Er du sikker på du vil slette virksomheden?} other {Er du sikker på du vil slette virksomhederne?}}',
  [intl.riskMonitoringNew('back')]: 'Tilbage',
  [intl.riskMonitoringNew('copy-button-text')]: 'Kopi',
  [intl.riskMonitoringNew('copy-to-another-list-title')]:
    'Vælg den liste du ønsker at kopiere til',
  [intl.riskMonitoringNew('create-list-button')]: 'Gem liste',
  [intl.riskMonitoringNew('create-new-list-text-field')]: 'Opret ny liste',
  [intl.riskMonitoringNew('create-subscription')]: 'Opret notifikation',
  [intl.riskMonitoringNew('cvt-textfield-label')]:
    "Indtast venligst CVR-numre (lokale ID'er) her",
  [intl.riskMonitoringNew('delete')]: 'Slet',
  [intl.riskMonitoringNew('delete-companies-title')]:
    '{count, plural, one {Slet markerede virksomheder} other {Slet markerede virksomheder}}',
  [intl.riskMonitoringNew('delete-dialog-title')]: 'Slet liste',
  [intl.riskMonitoringNew('delete-failed')]: 'Kunne ikke slette listen',
  [intl.riskMonitoringNew('delete-items')]: 'Slet',
  [intl.riskMonitoringNew('delete-list')]: 'Slet liste',
  [intl.riskMonitoringNew('delete-success')]: 'Listen er slettet',
  [intl.riskMonitoringNew('deleting-companies-wait')]:
    'Sletning af virksomheder. Vent venligst...',
  [intl.riskMonitoringNew('drag-drop')]:
    'Træk og slip, eller <span class="filepond--label-action">vælg en fil</span> (.csv eller .xlsx)',
  [intl.riskMonitoringNew('edit-button')]: 'Omdøb',
  [intl.riskMonitoringNew('edit-list-button')]: 'Omdøb liste',
  [intl.riskMonitoringNew('edit-list-text-field')]: 'Omdøb liste',
  [intl.riskMonitoringNew('edit-name')]: 'Nyt navn',
  [intl.riskMonitoringNew('existing-subscriptions')]: 'Eksisterende notifikationer',
  [intl.riskMonitoringNew('export-companies-button')]: 'Eksporter',
  [intl.riskMonitoringNew('failed-validation')]:
    '{amount} virksomheder fejlede validering og blev ikke tilføjet!',
  [intl.riskMonitoringNew('field-list-header')]:
    'Vælg venligst den kolonne, der indeholder CVR-nummer',
  [intl.riskMonitoringNew('handle-settings-button')]: 'Bord præferencer',
  [intl.riskMonitoringNew('handle-visibility-button')]: 'Visning',
  [intl.riskMonitoringNew('items-copied')]:
    '{count, plural, one {Virksomheden blev kopieret} other {{count} virksomheder blev kopieret}}',
  [intl.riskMonitoringNew('items-deleted')]:
    '{count, plural, one {Virksomheden blev slettet} other {{count} virksomheder blev slettet}}',
  [intl.riskMonitoringNew('items-failed-to-copy')]:
    'Der opstod en fejl da vi prøvede at kopiere virksomhederne',
  [intl.riskMonitoringNew('items-failed-to-delete')]:
    'Der opstod en fejl da vi prøvede at slette virksomhederne',
  [intl.riskMonitoringNew('items-failed-to-import')]:
    'Virksomheder kunne ikke importeres.',
  [intl.riskMonitoringNew('items-failed-to-move')]:
    'Der opstod en fejl da vi prøvede at flytte virksomhederne',
  [intl.riskMonitoringNew('items-imported')]: 'Virksomhederne blev importeret',
  [intl.riskMonitoringNew('items-moved')]:
    '{count, plural, one {Virksomheden blev flyttet} other {{count} virksomheder blev flyttet}}',
  [intl.riskMonitoringNew('monitor-add-subscription')]: 'Tilføj notifikation',
  [intl.riskMonitoringNew('monitor-settings')]: 'Indstil præferencer',
  [intl.riskMonitoringNew('move-button-text')]: 'Flyt',
  [intl.riskMonitoringNew('move-to-another-list-title')]:
    'Vælg den liste du ønsker at flytte til',
  [intl.riskMonitoringNew('new-list-added-failed')]: 'Kunne ikke tilføje ny liste',
  [intl.riskMonitoringNew('new-list-added-success')]: 'Ny liste tilføjet',
  [intl.riskMonitoringNew('new-list-private')]: 'Privat',
  [intl.riskMonitoringNew('new-list-public')]: 'Offentlig',
  [intl.riskMonitoringNew('new-list-subtitle')]: 'Vælg de brugere, der må se listen',
  [intl.riskMonitoringNew('no-credit')]: 'Ingen kredit',
  [intl.riskMonitoringNew('no-subscriptions-text')]:
    'Det ser ud til, at du ikke modtager ændringer på nogle lister. Tryk på knappen ovenfor for at tilføje en ny!',
  [intl.riskMonitoringNew('remove-internal-id')]: 'Slet internt ID',
  [intl.riskMonitoringNew('remove-internal-id-description')]:
    "{count, plural, one {Du er ved at slette {count} internt ID} other {Du er ved at slette {count} interne ID'er}}",
  [intl.riskMonitoringNew('remove-internal-id-title')]:
    "{count, plural, one {Slet internt ID} other {Slet interne ID'er}}",
  [intl.riskMonitoringNew('save-changes')]: 'Gem ændringer',
  [intl.riskMonitoringNew('select-lists')]: 'Vælg lister',
  [intl.riskMonitoringNew('select-subscription-lists')]:
    'Vælg lister du vil modtage ændringer på',
  [intl.riskMonitoringNew('select-subscription-options')]: 'Vælg hvad du vil høre om',
  [intl.riskMonitoringNew('selected')]:
    '{count, plural, =0 {Ingen valgt} other {{count} valgt}}',
  [intl.riskMonitoringNew('settings-header')]: 'Vælg hvilke kolonner, du gerne vil se',
  [intl.riskMonitoringNew('submit')]: 'Bekræft',
  [intl.riskMonitoringNew('subscribed-to-all')]:
    'Du abonnerer på alle tilgængelige lister',
  [intl.riskMonitoringNew('subscription-add-failed')]:
    'Der opstod et problem med oprettelsen af din notifikation',
  [intl.riskMonitoringNew('subscription-add-success')]: 'Notifikation oprettet',
  [intl.riskMonitoringNew('subscription-delete-failed')]:
    'Der opstod et problem da vi prøvede at slette din notifikation',
  [intl.riskMonitoringNew('subscription-delete-success')]: 'Notifikation slettet',
  [intl.riskMonitoringNew('subscription-updated-success')]:
    'Notifikationen blev opdateret',
  [intl.riskMonitoringNew('table-actions-tooltip')]: 'Vælg først en række',
  [intl.riskMonitoringNew('text-field-hint')]:
    'Brug komma eller mellemrum imellem hvert CVR-nummer',
  [intl.riskMonitoringNew('update')]: 'Opdater',
  [intl.riskMonitoringNew('update-subscriptions')]: 'Dine notifikationer',
  [intl.riskMonitoringNew('update-subscriptions-title')]: 'Dine notifikationer',
  [intl.riskMonitoringNew('visibility-header')]: 'Opdater hvem der må se listen',
  [intl.riskMonitoringNew('your-lists')]: 'Dine lister',
  [intl.riskMonitoringNew('sum-of-all-ids-found')]:
    'Summen af alle ID fundet i filen: {count}.',
  [intl.riskMonitoringNew('faulty-ids-found-message')]:
    '{count, plural, one {{count} defekt virksomheds ID fundet.} other {{count} defekte virksomheds ID fundet.}}',
  [intl.riskMonitoringNew('faulty-ids-button-export-label')]: 'Eksportér defekt(e) ID',
  [intl.riskMonitoringNew('faulty-ids-file-name')]: 'DefekteID',
  [intl.riskMonitoringNew('duplicate-ids-found-message')]:
    '{count, plural, one {{count} duplikere virksomheds ID fundet.} other {{count} duplikere virksomheds ID fundet.}}',
  [intl.riskMonitoringNew('duplicate-ids-button-export-label')]: 'Eksportér duplikere ID',
  [intl.riskMonitoringNew('duplicate-ids-file-name')]: 'DuplikereID',
  [intl.riskMonitoringNew('country-restriction-error')]:
    'Du kan kun overvåge virksomheder fra Danmark, Sverige og Norge.',
  [intl.riskMonitoringNew('language-choice')]: 'Vælg dit foretrukne sprog',
  [intl.riskMonitoringNew('list-name-update-success')]: 'Listenavn opdateret',
  [intl.riskMonitoringNew('list-name-update-fail')]: 'Opdatering mislykkedes',

  [intl.riskMonitoringOverview('all')]: 'Alle',
  [intl.riskMonitoringOverview('da_DK')]: 'Danmark',
  [intl.riskMonitoringOverview('delete-all-button')]: 'Slet alle',
  [intl.riskMonitoringOverview('delete-all-popup-text')]:
    'Denne handling vil slette alle de virksomheder, som du overvåger i øjeblikket. Er du sikker på, at du vil fortsætte?',
  [intl.riskMonitoringOverview('delete-all-popup-title')]:
    'Slet alle overvågede virksomheder',
  [intl.riskMonitoringOverview('high-risk')]: 'Høj risiko',
  [intl.riskMonitoringOverview('low-risk')]: 'Lav risiko',
  [intl.riskMonitoringOverview('medium-risk')]: 'Middel risiko',
  [intl.riskMonitoringOverview('no-score')]: 'Ingen score',
  [intl.riskMonitoringOverview('no_NO')]: 'Norge',
  [intl.riskMonitoringOverview('sv_SE')]: 'Sverige',
  [intl.riskMonitoringOverview('total')]: 'Total',
  [intl.riskMonitoringSubscription('add-description')]:
    'Du kan oprette et abonnement på de parametre, du ønsker at høre om. Vi sender en ugentlig e-mail, hvis der er sket nogle ændringer i de virksomheder, du overvåger.',
  [intl.riskMonitoringSubscription('add-subscription')]: 'Tilføj overvågning',
  [intl.riskMonitoringSubscription('add-subscription-title')]:
    'Tilføj et overvågningsabonnement',
  [intl.riskMonitoringSubscription('address')]: 'Adresse',
  [intl.riskMonitoringSubscription('all-important-changes')]: 'Alle vigtige ændringer',
  [intl.riskMonitoringSubscription('all-regular-changes')]: 'Alle almindelige ændringer',
  [intl.riskMonitoringSubscription('annual_reports')]: 'Årsrapporter',
  [intl.riskMonitoringSubscription('company_type')]: 'Virksomhedstype',
  [intl.riskMonitoringSubscription('email')]: 'E-mail',
  [intl.riskMonitoringSubscription('policy_outcome')]: 'Kreditpolitik ændringer',
  [intl.riskMonitoringSubscription('employees')]: 'Antal ansatte',
  [intl.riskMonitoringSubscription('gazette')]: 'Statstidende',
  [intl.riskMonitoringSubscription('fraud_indicators')]: 'Risikoindikatorer',
  [intl.riskMonitoringSubscription('important-changes')]: 'Vigtige ændringer',
  [intl.riskMonitoringSubscription('industry')]: 'Industri',
  [intl.riskMonitoringSubscription('payment_remarks')]: 'Betalingsanmærkninger (Norge)',
  [intl.riskMonitoringSubscription('manage-description')]:
    'Du har mulighed for at fjerne dit overvågningabonnement eller ændre de parametre, du ønsker at høre om i fremtiden.',
  [intl.riskMonitoringSubscription('manage-subscription')]: 'Præferencer',
  [intl.riskMonitoringSubscription('manage-subscription-title')]:
    'Indstil dit overvågningsabonnement',
  [intl.riskMonitoringSubscription('name')]: 'Virksomhedsnavn',
  [intl.riskMonitoringSubscription('phone_number')]: 'Telefonnummer',
  [intl.riskMonitoringSubscription('receive-updates-description')]:
    'Når der kommer ændringer som du synes er relevante, så sender vi dem til dig.',
  [intl.riskMonitoringSubscription('receive-updates-title')]:
    'Opdateringer til din e-mail',
  [intl.riskMonitoringSubscription('regular-changes')]: 'Almindelige ændringer',
  [intl.riskMonitoringSubscription('relations')]: 'Relationer',
  [intl.riskMonitoringSubscription('remove-subscription')]: 'Fjern notifikation',
  [intl.riskMonitoringSubscription('score')]: 'Risika score',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-added')]:
    'Vi kunne desværre ikke tilføje dit abonnement',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-removed')]:
    'Vi kunne desværre ikke fjerne dit abonnement',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-updated')]:
    'Vi kunne desværre ikke opdatere dit abonnement',
  [intl.riskMonitoringSubscription('snackbar-subscription-added')]:
    'Dit abonnement er tilføjet',
  [intl.riskMonitoringSubscription('snackbar-subscription-removed')]:
    'Dit abonnement er fjernet',
  [intl.riskMonitoringSubscription('snackbar-subscription-updated')]:
    'Dit abonnement er blevet opdateret',
  [intl.riskMonitoringSubscription('status')]: 'Virksomhedsstatus',
  [intl.searchBusiness('api-error')]: 'Anmodningen mislykkedes: {message}',
  [intl.searchBusiness('clear-range-picker')]: 'Nulstil Risika Score',
  [intl.searchBusiness('company')]: 'Virksomhed',
  [intl.searchBusiness('employees')]: 'Ansatte',
  [intl.searchBusiness('local-id')]: 'Virksomheds ID',
  [intl.searchBusiness('max-export')]:
    '{count, plural, other {Eksportering begrænset til de første {count} rækker}}',
  [intl.searchBusiness('no-data')]: 'Intet data tilgængelig',
  [intl.searchBusiness('personal-id')]: 'Personal Id',
  [intl.searchBusiness('results-time-elapsed')]:
    '{count, plural, one {# resultat} other {# resultater}} på {time, plural, other {#}} sekunder',
  [intl.searchBusiness('risika-score')]: 'Risika score',
  [intl.searchBusiness('search-field')]: 'Søg',
  [intl.searchBusiness('search-placeholder')]:
    'Skriv virksomhedsnavn/id (cvr), adresse, mail eller tlf.nr. (min. 3 tegn)',
  [intl.searchBusiness('selector-companies')]:
    '{count, plural, one {Virksomhed ({countDisplay})} other {Virksomheder ({countDisplay})}}',
  [intl.searchBusiness('selector-no-companies')]:
    'Vi kunne ikke finde nogle virksomheder. Søger efter personer i stedet.',
  [intl.searchBusiness('selector-people')]:
    '{count, plural, one {Person (#)} other {Personer (#)}}',
  [intl.creditPolicyNew('people')]: 'Personer',
  [intl.searchBusiness('status')]: 'Status',
  [intl.searchBusiness('title')]: 'Søgning',
  [intl.searchBusiness('type')]: 'Type',
  [intl.settings('address')]: 'Virksomhedsadresse',
  [intl.settings('all-users')]: 'Alle brugere',
  [intl.settings('are-you-sure-delete-user')]:
    'Er du sikker på, at du vil slette denne bruger?',
  [intl.settings('cant-delete-yourself')]: 'Du kan ikke slette dig selv!',
  [intl.settings('cant-have-user-and-admin')]:
    'Du kan enten vælge "Bruger" eller "Administrator"!',
  [intl.settings('code-helper-text')]: 'Kopier teksten og tilføj til din hjemmeside',
  [intl.settings('company-details-title')]: 'Detaljer om din aftale',
  [intl.settings('company-information-title')]: 'Virksomhedsinformation',
  [intl.settings('company-information-updated-success')]:
    'Virksomhedsoplysningerne er opdateret',
  [intl.settings('confirm-new-password')]: 'Bekræft nyt password',
  [intl.settings('confirm-password-requirements')]:
    'Bekræftelsen af password skal være det samme som password',
  [intl.settings('content-copied')]: 'Indhold kopieret',
  [intl.settings('copy-icon')]: 'Kopier indhold',
  [intl.settings('customerType')]: 'Abonnement',
  [intl.settings('dark')]: 'Mørk',
  [intl.settings('domain-label')]: 'Domæne',
  [intl.settings('email-validation')]: 'Ugyldig e-mailadresse',
  [intl.settings('phone-validation')]: 'Ugyldigt telefonnummer',
  [intl.settings('name-validation')]: 'Tilføj venligst brugernavn',
  [intl.observationalLists('press-enter-email')]:
    'Tryk på enter, når du har indtastet din e-mail for at bekræfte.',
  [intl.settings('general-information')]: 'Generel information',
  [intl.settings('generate')]: 'Generer',
  [intl.settings('invalid-phone')]: 'Ugyldigt telefonnummer',
  [intl.settings('invalid-url')]: 'Tilføj venlist en gyldig URL',
  [intl.settings('invalid-url-phone')]: 'Ugyldig URL og telefonnummer',
  [intl.settings('large')]: 'Stor',
  [intl.settings('last-login')]: 'Sidste aktivitet',
  [intl.settings('light')]: 'Lys',
  [intl.settings('link-type')]: 'Link type',
  [intl.settings('logo-title')]: 'Step 1: Fortæl os, hvor du vil bruge vores logo',
  [intl.settings('mail-send')]: 'E-mail sendt',
  [intl.settings('medium')]: 'Medium',
  [intl.settings('monitoring-used')]: 'Antal overvågninger',
  [intl.settings('name')]: 'Virksomhedsnavn',
  [intl.settings('new-password')]: 'Nyt password',
  [intl.settings('password-requirements')]: 'Dit password skal være minimum 8 karaktere',
  [intl.settings('password-title')]: 'Password',
  [intl.settings('password-updated-successfully')]: 'Adgangskode blev opdateret',
  [intl.settings('user-information-updated')]: 'Brugeroplysninger er blevet opdateret',
  [intl.settings('user-password-updated')]: 'Adgangskode er blevet opdateret',

  [intl.settings('add-user-button')]: 'Tilføj bruger',
  [intl.settings('user-create-success')]: 'Brugeren er blevet oprettet',
  [intl.settings('user-create-error')]: 'Kunne ikke oprette bruger',
  [intl.settings('user-update-success')]: 'Bruger opdateret',
  [intl.settings('user-update-error')]: 'Kunne ikke opdatere bruger',
  [intl.settings('user-delete-success')]: 'Bruger slettet',
  [intl.settings('user-delete-error')]: 'Kunne ikke slette bruger',
  [intl.settings('user-table-results-per-page')]: 'Resultater pr. side',

  [intl.settings('phone-label')]: 'Telefonnummer',
  [intl.settings('preview-title')]: 'Step 2: Design dit logo',
  [intl.settings('radio-text')]: 'Hvilken baggrund vil logoet være på?',
  [intl.settings('remainingMonitoring')]: 'Resterende overvågning',
  [intl.settings('remainingUsers')]: 'Resterende brugere',
  [intl.settings('risika-logo')]: 'Få et Risika logo',
  [intl.settings('see-code')]: 'Se koden',
  [intl.settings('select-helper-text')]: 'HTML eller URL?',
  [intl.settings('share-logo')]: 'Del logo',
  [intl.settings('share-logo-message')]: 'Tilføj en besked sammen med logoet',
  [intl.settings('size-text')]: 'Størrelse på logoet',
  [intl.settings('small')]: 'Lille',
  [intl.settings('step-3')]: 'Step 3: Send koden til din udvikler',
  [intl.settings('termsOfService')]: 'Forretningsbetingelser',
  [intl.settings('title')]: 'Indstillinger',
  [intl.settings('update-avatar')]: 'OPDATER AVATAR',
  [intl.settings('update-company')]: 'Opdater virksomhed',
  [intl.settings('update-password')]: 'Opdater password',
  [intl.settings('update-user')]: 'Opdater bruger',
  [intl.settings('user-information-title')]: 'Brugerinformation',
  [intl.settings('user-management')]: 'Brugerstyring',
  [intl.settings('users-used')]: 'Antal brugere',
  [intl.settings('whitelist')]: 'Website',
  [intl.settings('whitelist-helper-text')]:
    'Efter indtastning af dit websted, tryk "enter" for at validere det. Tryk derefter på Generer-knappen.',
  [intl.settings('scoring-model')]: 'Scoremodel',
  [intl.settings('click-to-change-currency')]: 'Klik for at ændre valuta',
  [intl.settings('credit-override')]: 'Kreditoverskridelse',

  [intl.settings('score-model-dk')]: 'Scoremodel (Danmark)',
  [intl.settings('score-model-se')]: 'Scoremodel (Sverige)',
  [intl.settings('score-model-no')]: 'Scoremodel (Norge)',
  [intl.settings('score-model-uk')]: 'Scoremodel (STORBRITANNIEN)',
  [intl.settings('score-model-fi')]: 'Scoremodel (Finland)',

  [intl.shareReport('add-custom-message')]: 'Besked til modtager (valgfrit)',
  [intl.shareReport('select-recipient')]: 'Vælg modtager',
  [intl.shareReport('sender-name')]: 'Afsenders navn (valgfrit)',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor resources from NO'
  )]: 'Din abonnementsplan tillader ikke, at du overvåger ressourcer fra Norge',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor resources from SE'
  )]: 'Din abonnementsplan tillader ikke, at du overvåger ressourcer fra Sverige',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor this many companies'
  )]: 'Din abonnementsplan tillader ikke, at du overvåger disse mange virksomheder',
  [intl.snackbar('account-activated-failed')]: 'Der opstod et problem',
  [intl.snackbar('account-activated-success')]:
    'Perfekt! Du kan nu begynde at bruge Risika',
  [intl.snackbar('add-companies-monitoring')]: 'Virksomhederne bliver nu overvåget',
  [intl.snackbar('add-companies-monitoring-failed')]:
    'Importering fejlede: vær venlig at tjekke at en af kolonnerne hedder "cvr"',
  [intl.snackbar('add-company-monitoring')]: 'Virksomheden bliver nu overvåget',
  [intl.snackbar('added-back')]: 'bliver overvåget igen',
  [intl.snackbar('all-added-back')]: 'virksomheder bliver overvåget igen',
  [intl.snackbar('companies-added-to-list')]: 'Virksomheder tilføjet til {list}',
  [intl.snackbar('companies-added')]: 'Virksomheder tilføjet',
  [intl.snackbar('companies-failed-to-add')]:
    'Virksomhederne kunne ikke tilføjes til {list}',
  [intl.snackbar('company-added-to-list')]: 'Virksomheden tilføjet til {list}',
  [intl.snackbar('company-removed-from-list')]: 'Virksomheden fjernet fra {list}',
  [intl.snackbar('copied')]: 'Kopieret',
  [intl.snackbar('dismiss')]: 'Luk',
  [intl.snackbar('export-failed')]: 'Din eksportering fejlede',
  [intl.snackbar('export-success')]: 'Din eksportering er klar',
  [intl.snackbar('failed-to-add-company')]: 'Kunne ikke oprette virksomheden',
  [intl.snackbar('failed-to-add-emails')]: 'Kunne ikke oprette brugere med e-mails',
  [intl.snackbar('fields-required')]: 'Udfyld venligst de krævede felter',
  [intl.snackbar('invalid-email')]: 'Tilføj venligst en gyldig e-mail',
  [intl.snackbar('logo-shared-succes')]: 'Logoet bliver sendt.',
  [intl.snackbar('maximum-export-exceded-exporting-less')]:
    'Maksimal mængde virksomheder, der skal eksporteres. Eksport af de første {exportLimit} virksomheder',
  [intl.snackbar('no-subsidiaries')]: 'Selskabet har ingen datterselskaber',
  [intl.snackbar('password-not-allowed')]: 'Venlist vælg et andet passwors',
  [intl.snackbar('remove-companies-monitoring')]:
    'Alle virksomheder bliver ikke længere overvåget',
  [intl.snackbar('remove-company-monitoring')]:
    'Virksomheden bliver ikke længere overvåget',
  [intl.snackbar('remove-failed')]: 'Der opstod en fejl da vi fjernede jeres webhook',
  [intl.snackbar('remove-successfull')]: 'Jeres webhook er blevet fjernet',
  [intl.snackbar('report-error-something-wrong')]: 'Der opstod et problem',
  [intl.snackbar('report-error-no-data-available')]: 'Data utilgængelige',
  [intl.errors('observation-list-no-data-to-load')]:
    'Beklager, vi har i øjeblikket ikke de nødvendige data til at vise denne grafik.',
  [intl.snackbar('report-error-timeout')]:
    'På nuværende tidspunkt har vi ikke en rapport for',
  [intl.snackbar('report-failed-to-submit')]: 'Rapporten kunne ikke indsendes',
  [intl.snackbar('report-submited')]: 'Rapport indsendt med succes',
  [intl.snackbar('reset-filters')]: 'Filtrene er blevet nulstillet',
  [intl.snackbar('settings-saved')]: 'Indstillinger gemt',
  [intl.snackbar('shared-succes')]: 'Rapporten bliver sendt.',
  [intl.snackbar('test-failed')]: 'Der opstod en fejl med testen: {error}',
  [intl.snackbar('test-successfull')]:
    'Du vil modtage et eksempel svar inden for 2 minutter',
  [intl.snackbar('undo')]: 'Fortryd',
  [intl.snackbar('users-added-successfully')]: 'Alle brugere oprettet',
  [intl.table('loading')]: 'Loader...',
  [intl.table('next')]: 'Næste',
  [intl.table('no-rows')]: 'Ingen rækker tilgængelige',
  [intl.table('of')]: 'af',
  [intl.table('page')]: 'Side',
  [intl.table('previous')]: 'Forrige',
  [intl.table('rows')]: 'rækker',
  [intl.toolbar('add-email')]: 'Tilføj e-mail',
  [intl.toolbar('add-phone')]: 'Tilføj telefon',
  [intl.toolbar('billing-plan')]: 'Faktureringsplan',
  [intl.toolbar('edit-profile')]: 'Rediger profil',
  [intl.toolbar('greeting')]: 'Hej',
  [intl.toolbar('logout')]: 'Log ud',
  [intl.toolbar('role')]: 'Rolle',
  [intl.toolbar('welcome-to-risika')]: 'Velkommen til Risika Dashboard',
  [intl.tooltip('click-to-copy')]: 'Klik for at kopiere',
  [intl.userManagement('delete-dialog-title')]: 'Slet bruger',
  [intl.userManagement('delete-dialog-content')]:
    'Ved at slette denne bruger accepterer du permanent at tilbagekalde vedkommendes adgang til alle Risika-produkter og API. Er du sikker på, at du gerne vil fortsætte?',
  [intl.userManagement('failed-to-load-users')]: 'Kunne ikke indlæse brugere',
  [intl.userManagement('invalid-email')]: 'Ugyldig e-mail',
  [intl.userManagement('invite-header')]: 'Inveter brugere til Risika',
  [intl.userManagement('invite-users-button')]: 'Inveter brugere',
  [intl.userManagement('invites-send-failed')]: 'Vi kunne ikke sende e-mail til {email}',
  [intl.userManagement('invites-send-success')]:
    'Invitation til {email} blev sendt med success',
  [intl.userManagement('maximum-invites-reached')]:
    'Du har nået dit maksimum af invitation. Klik her for at købe flere',
  [intl.userManagement('more-than-30')]: '30+ dage',
  [intl.userManagement('no-data-yet')]: 'Ingen data',
  [intl.userManagement('role-change-failed')]: 'Kunne ikke ændre rolle',
  [intl.userManagement('role-change-success')]: 'Successivt ændret rolle',
  [intl.userManagement('send-invite-button')]: 'Inviter',
  [intl.userManagement('user-delete-failed')]: 'Brugeren kunne ikke slettes',
  [intl.userManagement('user-delete-success')]: 'Bruger blev slettet',
  [intl.visualScore('high-risk')]: 'Høj risiko',
  [intl.visualScore('low-risk')]: 'Lav risiko',
  [intl.visualScore('medium-risk')]: 'Middel risiko',
  [intl.companyComparison('title')]: 'Sammenlign',
  [intl.companyComparison('add-to-comparison-button')]: 'Tilføj til sammenligning',
  [intl.companyComparison('add-to-comparison-tooltip')]:
    'Føj dette firma til en sammenlignende liste',
  [intl.companyComparison('dialog-cannot-add-title')]: 'Virksomheden kunne ikke tilføje',
  [intl.companyComparison('dialog-cannot-add-content')]:
    'Virksomheden kunne ikke tilføjes til sammenligningslisten.',
  [intl.autoSelectCompanies('search-box-title')]: 'Søg virksomheder',
  [intl.keyFigures('title')]: 'Nøgletal',
  [intl.keyFiguresDevelopment('title')]: 'Financial Ratios Development',
  [intl.keyFiguresDevelopment('liquidity-ratio')]: 'Likviditetsgrad',
  [intl.keyFiguresDevelopment('result')]: 'Resultat',
  [intl.charts('radar-tooltip-score-since')]: 'Siden',

  [intl.charts('radar-tooltip-distress')]: 'Konkurssandsynlighed: {value}%',
  [intl.charts('radar-tooltip-current-ratio')]: 'Nuværende forhold: {value}',
  [intl.charts('radar-tooltip-explanation')]:
    'Dette betragtes som {rating} i sin branche',

  [intl.charts('radar-tooltip-ratio')]:
    '{key}en er på {value}%, hvilket betragtes som en {rating} {key} sammenlignet med andre virksomheder i samme branche.',
  [intl.charts('radar-key-figures-info')]:
    'Diagrammet viser, hvordan virksomhedens nøgletal ser ud sammenlignet med andre virksomheder i samme branche. De fire valgte nøgletal repræsenterer virksomhedens rentabilitet, soliditet, likviditet og konkursrisiko.',
  [intl.alikeCompanies('dialog-not-found-title')]: 'Ingen virksomhed at vise',
  [intl.alikeCompanies('dialog-not-found-content')]:
    'Ingen lignende virksomhed blev fundet.',
  [intl.alikeCompanies('tooltip')]:
    'En lignende virksomhed vil blive fundet ud fra den første virksomhed, der er i sammenligningen. Den lignende virksomhed findes ud fra aktive virksomheder, der er i samme branche og som har nogenlunde samme antal ansatte og kapital.',
  [intl.portfolioInsights('drawer-trigger-button')]: 'Porteføljeindsigt',
  [intl.portfolioInsights('tab-summary-label')]: 'Resumé',
  [intl.portfolioInsights('tab-compliance-label')]: 'Overholdelse',
  [intl.portfolioInsights('tab-scheduler-label')]: 'Planlægger',
  [intl.portfolioInsights('tab-scheduler-destination')]: 'Modtager',
  [intl.portfolioInsights('tab-scheduler-destination-email')]: 'Email',
  [intl.portfolioInsights('tab-scheduler-destination-email-recipients')]:
    'Email-modtagere',
  [intl.portfolioInsights('tab-scheduler-emails-list-placeholder')]:
    'Angiv mindst én modtager',
  [intl.portfolioInsights('tab-scheduler-emails-list-invalid-email-warning')]:
    'Ugyldig email. Sørg for, at alle emailadresser er korrekte.',
  [intl.portfolioInsights('tab-scheduler-frequency-label')]: 'Frekvens',
  [intl.portfolioInsights('tab-scheduler-frequency-send-every')]: 'Send hver',
  [intl.portfolioInsights('tab-scheduler-frequency-week')]: 'Uge',
  [intl.portfolioInsights('tab-scheduler-frequency-month')]: 'Måned',
  [intl.portfolioInsights('tab-scheduler-frequency-year')]: 'År ',
  [intl.portfolioInsights('tab-scheduler-month-first-day')]: 'Første dag i måneden',
  [intl.portfolioInsights('tab-scheduler-month-first-week-day')]:
    'Første hverdag i måneden',
  [intl.portfolioInsights('tab-scheduler-month-last-day')]: 'Sidste dag i måneden',
  [intl.portfolioInsights('tab-scheduler-month-last-week-day')]:
    'Sidste hverdag i måneden',
  [intl.portfolioInsights('tab-scheduler-frequency-send-on')]: 'Dag',
  [intl.portfolioInsights('tab-scheduler-frequency-starting-from')]: 'Startende fra',
  [intl.portfolioInsights('tab-scheduler-frequency-day-mon')]: 'Man',
  [intl.portfolioInsights('tab-scheduler-frequency-day-tue')]: 'Tir',
  [intl.portfolioInsights('tab-scheduler-frequency-day-wed')]: 'Ons',
  [intl.portfolioInsights('tab-scheduler-frequency-day-thu')]: 'Tor',
  [intl.portfolioInsights('tab-scheduler-frequency-day-fri')]: 'Fre',
  [intl.portfolioInsights('tab-scheduler-frequency-day-sat')]: 'Lør',
  [intl.portfolioInsights('tab-scheduler-frequency-day-sun')]: 'Søn',
  [intl.portfolioInsights('tab-scheduler-status')]: 'Status',
  [intl.portfolioInsights('tab-scheduler-status-active')]: 'Aktiv',
  [intl.portfolioInsights('tab-scheduler-status-disabled')]: 'Deaktiveret',
  [intl.portfolioInsights('tab-scheduler-save-changes')]: 'Gem ændringer',
  [intl.portfolioInsights('tab-scheduler-subscription-create-successful')]:
    'Indstilling tilføjet',
  [intl.portfolioInsights('tab-scheduler-subscription-update-successful')]:
    'Indstilling opdateret',
  [intl.portfolioInsights('tab-scheduler-subscription-create-fail')]:
    'Kunne ikke tilføje indstilling',
  [intl.portfolioInsights('tab-scheduler-subscription-update-fail')]:
    'Kunne ikke opdatere indstilling',
  [intl.portfolioInsights('points-of-attention')]: 'Opmærksomhedspunkter',
  [intl.portfolioInsights('portfolio-risk-distribution-subtitle')]:
    '{currentCompaniesCount} af {allCompaniesCount} virksomheder',
  [intl.portfolioInsights('portfolio-score')]: 'PORTEFØLJESCORE',
  [intl.portfolioInsights('portfolio-risk-distribution')]: 'Portefølje Risikofordeling',
  [intl.portfolioInsights('Low risk')]: 'Lav risiko',
  [intl.portfolioInsights('Medium risk')]: 'Middel risiko',
  [intl.portfolioInsights('High risk')]: 'Høj risiko',
  [intl.portfolioInsights('Unknown risk')]: 'Ukendt risiko',
  [intl.portfolioInsights('portfolio-risk-history')]: 'Portefølje Risikohistorik',
  [intl.portfolioInsights('credit-policy-compliance')]:
    'Overensstemmelse Med Kreditpolitik',
  [intl.portfolioInsights('credit-policy-violation')]: 'Krænkelse af Kreditpolitik',
  [intl.portfolioInsights('violations')]: 'Krænkelse',
  [intl.portfolioInsights('compliant-label')]: 'Overensstemmende',
  [intl.portfolioInsights('non-compliant-label')]: 'Ikke-kompatibel',
  [intl.portfolioInsights('compliance-history')]: 'Overensstemmelse historik',
  [intl.portfolioInsights('avg.')]: 'gns.',
  [intl.portfolioInsights('approved')]: 'Kredit godkendt',
  [intl.portfolioInsights('rejected')]: 'Kredit afvist',
  [intl.portfolioInsights('manual-check')]: 'Manuel kontrol',
  [intl.portfolioInsights('no-policy-set')]: 'Ingen politik anvendt',
  [intl.portfolioInsights('allowed-label')]: 'Godkendt',
  [intl.portfolioInsights('manual_check-label')]: 'Manuel kontro',
  [intl.portfolioInsights('rejected-label')]: 'Afvist',
  [intl.portfolioInsights('do_not_evaluate-label')]: 'Ingen politik',

  [intl.errors('hubspot_error')]:
    'Emailverificering fejlede. Brug venligst en firma-email eller kontakt vores kundeservice',
  [intl.errors('companies_local_id_country_unique')]: 'CVR er allerede i brug',
  [intl.errors('company_already_exists')]: 'CVR er allerede i brug',
  [intl.errors('invalid_local_id')]: 'Du har brugt et ugyldigt CVR-nummer',
  [intl.errors('error_creating_new_customer')]:
    'Noget gik galt. Kontakt venligst vores kundeservice på contact@risika.com',
  [intl.errors('observation-list-can-t-be-loaded')]:
    'Vi kan desværre ikke indlæse data for denne grafik i øjeblikket, prøv venligst igen senere.',
  [intl.errors('service-unavailable')]: 'Tjenesten Utilgængelig',
  [intl.errors('service-unavailable-description')]:
    'Vi kunne ikke hente detaljerne på dette tidspunkt. Prøv venligst igen senere.',
  [intl.errors('contract-min-date-error')]:
    'Kontraktens slutdato kan ikke være tidligere end startdatoen.',
}

export default messages
