import { Typography, Box } from '@mui/material'
import { Check as CheckIcon, PriorityHigh as ErrorIcon } from '@mui/icons-material'
import { compliantStyle } from '../../../../../components-new/data-display/Table/Table.styles'

type FraundIndicatorCellProps = {
  row: any
}

const FraundIndicatorCell = ({ row }: FraundIndicatorCellProps) => {
  const isCompliant = !row?.original?.fraudIndicators
  const CompliantIcon = isCompliant ? CheckIcon : ErrorIcon
  const DisplayContent = () => (
    <Typography sx={{ ...compliantStyle(isCompliant ? 'COMPLIANT' : '') }}>
      <CompliantIcon sx={{ fontSize: '2.4rem', padding: '0.4rem' }} />
    </Typography>
  )

  return (
    <Box display="flex" data-cy="body-cell-fraud-indicators">
      <DisplayContent />
    </Box>
  )
}

export default FraundIndicatorCell
