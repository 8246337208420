import { useMemo, Fragment, useEffect } from 'react'
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Box,
} from '@mui/material'
import {
  useTable,
  useRowSelect,
  usePagination,
  useFilters,
  useSortBy,
  useExpanded,
} from 'react-table-next'
import { AsyncLoader } from 'components'
import { useHover } from 'react-use'
import {
  localIdFilterFn,
  fuzzyTextFilterFn,
  groupSelectFilterFn,
} from './Filters/filterTypes'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { classes } from 'components/styles/table'
import { useTheme } from '@mui/material/styles'

const defaultPropGetter = () => ({})
const defaultRowsPerPageOptions = [5, 10, 25, 50, 100]
const defaultRowsPerPage = 50

const Hoverable = ({ render }) => {
  const [hoverable] = useHover((isHovered) => render(isHovered))
  return hoverable
}
const Table = ({
  columns,
  data,
  setSelectedRows,
  renderRowSubComponent,
  getRowProps = defaultPropGetter,
  pageRows,
  loading,
  pageIndex,
  setPage,
  dataCy,
}) => {
  const theme = useTheme()
  const { rowsPerPage, rowsPerPageOptions } = pageRows ?? {}
  const filterTypes = useMemo(
    () => ({
      localId: localIdFilterFn,
      fuzzyText: fuzzyTextFilterFn,
      groupSelect: groupSelectFilterFn,
    }),
    []
  )

  const defaultColumn = useMemo(
    () => ({
      Filter: null,
    }),
    []
  )

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    setPageSize,
    page,
    rows,
    gotoPage,
    allColumns,
    selectedFlatRows,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: rowsPerPage ?? defaultRowsPerPage,
      },
      filterTypes,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  )

  useEffect(() => {
    gotoPage(pageIndex)
  }, [pageIndex, data, page, gotoPage])

  useEffect(() => {
    setSelectedRows(selectedFlatRows)
  }, [setSelectedRows, selectedFlatRows])
  return (
    <AsyncLoader ready={!loading}>
      <MuiTable {...getTableProps()} data-cy={dataCy}>
        <TableHead sx={classes.tableHead}>
          {headerGroups.map((headerGroup) => (
            <TableRow key={headerGroup?.accessor} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i, a) => {
                return (
                  <TableCell
                    key={i}
                    sx={{
                      ...classes.tableHeadCell,
                      width: column.width,
                    }}
                  >
                    <Box sx={classes.header}>
                      <Box>
                        {column.render('Header')}
                        {column.canFilter ? column.render('Filter') : null}
                      </Box>
                      <Box>
                        {i > 1 && i < a.length - 1 && (
                          <TableSortLabel
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            data-cy="table-sort-indicator"
                            active
                            sx={classes.sortArrow}
                            direction={
                              column.id !== 'riskAssessmentCode'
                                ? column.isSortedDesc
                                  ? 'asc'
                                  : 'desc'
                                : column.isSortedDesc
                                  ? 'desc'
                                  : 'asc'
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <Fragment key={row?.id}>
                <Hoverable
                  render={(isHovered) => (
                    <TableRow
                      {...row.getRowProps()}
                      {...getRowProps({ ...row, isHovered })}
                      selected={selectedFlatRows.includes(row)}
                      sx={classes.row(isHovered)}
                    >
                      {row.cells.map((cell) => {
                        row.isHovered = isHovered
                        return (
                          <TableCell
                            key={cell?.row?.index}
                            {...cell.getCellProps()}
                            padding="none"
                            sx={{
                              ...classes.cell,
                              width: cell.column.width,
                            }}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )}
                />
                {/* extended row */}
                {row.isExpanded ? (
                  <TableRow sx={classes.expandedRow}>
                    <TableCell colSpan={allColumns.length}>
                      {renderRowSubComponent({ row })}
                    </TableCell>
                  </TableRow>
                ) : null}
              </Fragment>
            )
          })}
        </TableBody>
      </MuiTable>
      <TablePagination
        sx={classes.paginationRoot}
        slotProps={{
          select: theme.typography.body2,
          actions: {
            previousButton: {
              'aria-label': 'previous page',
            },
            nextButton: {
              'aria-label': 'next page',
            },
          },
        }}
        rowsPerPageOptions={rowsPerPageOptions ?? defaultRowsPerPageOptions}
        count={rows.length}
        rowsPerPage={pageSize}
        labelRowsPerPage={<FormattedMessage id={intl.riskMonitoring('rows-per-page')} />}
        labelDisplayedRows={({ from, to, count }) => (
          <FormattedMessage
            id={intl.riskMonitoring('from-to-count')}
            values={{ from, to, count }}
          />
        )}
        page={pageIndex}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => setPageSize(+e.target.value)}
      />
    </AsyncLoader>
  )
}

export default Table
