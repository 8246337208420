import { useCallback, useMemo } from 'react'
import { Stack, Collapse, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Show } from 'components'
import { FormattedMessage } from 'react-intl'
import { colors } from 'configs'
import { isComponentRendered } from 'configs'
import { useCreditCheck } from '../../../../context'
import { LoadingSkeleton } from 'components-new'
import IndicatorsSkeleton from './Indicators.skeleton'
import { WidgetWrapper } from '../WidgetWrapper/WidgetWrapper'
import { FraudIndicators } from './FraudIndicators'
import { CompanyHighlights } from './CompanyHighlights'

const useStyles = makeStyles((theme) => ({
  frame: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (isDragging) => (isDragging ? colors?.risikaContrast : 'inherit'),
  },
  outerStack: {
    padding: theme.spacing(4),
  },
  main: {
    marginTop: theme.spacing(6),
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}))

function sortHighlights(highlights) {
  const copyHighlights = [...highlights]

  function classificationNumeric(classification) {
    switch (classification) {
      case 'negative':
        return 2
      case 'positive':
        return 1
      case 'neutral':
        return 0
      default:
        return 0
    }
  }

  copyHighlights.sort((a, b) => {
    const classificationOfA = classificationNumeric(a.classification)
    const classificationOfB = classificationNumeric(b.classification)

    const diffClassification = classificationOfB - classificationOfA

    if (diffClassification !== 0) {
      return diffClassification
    }

    return a.weight - b.weight
  })

  return copyHighlights
}

function parseHighlights(highlights) {
  const parsedHighlights = []

  for (const key in highlights) {
    parsedHighlights.push({
      key,
      classification: highlights[key].classification,
      title: highlights[key].title,
      description: highlights[key].description,
      message: highlights[key].message,
      weight: highlights[key].weight,
      data: highlights[key].data,
    })
  }

  return sortHighlights(parsedHighlights)
}

export const Indicators = ({
  indicatorName,
  indicatorTitle,
  widgetNameDND,
  data,
  dataCy,
  error,
  isReArrange = false,
  isDragging = false,
  isLoading,
  isActive,
  setIsActive,
}) => {
  const localClasses = useStyles(isDragging)
  const highlightsToShow = useMemo(() => {
    if (!data) {
      return []
    }
    const allParsedHighlights = parseHighlights(data)
    return allParsedHighlights.map((highlight) =>
      Object.assign({}, highlight, { blurred: false })
    )
  }, [data])

  const { localId } = useCreditCheck()

  const getIndicators = useCallback(() => {
    if (indicatorName === 'fraudIndicators') {
      return (
        <FraudIndicators
          error={error}
          highlightsToShow={highlightsToShow}
          dataCy={dataCy}
        />
      )
    }

    return <CompanyHighlights highlightsToShow={highlightsToShow} dataCy={dataCy} />
  }, [dataCy, error, highlightsToShow, indicatorName])

  return (
    <LoadingSkeleton SkeletonComponent={IndicatorsSkeleton} isLoading={isLoading}>
      <Show when={isComponentRendered(indicatorName, localId?.country)}>
        <WidgetWrapper
          isReArrange={isReArrange}
          widgetName={widgetNameDND}
          isActive={isActive}
          setIsActive={setIsActive}
        >
          <Stack
            direction="column"
            className={localClasses.outerStack}
            data-cy={`${widgetNameDND}-stack`}
          >
            <Typography variant="h4">
              <FormattedMessage id={indicatorTitle} />
            </Typography>
            <Collapse in={!isReArrange}>
              <Stack direction="column" spacing={4} className={localClasses.main}>
                {getIndicators()}
              </Stack>
            </Collapse>
          </Stack>
        </WidgetWrapper>
      </Show>
    </LoadingSkeleton>
  )
}

export default Indicators
